import {BoundingBox} from '../../util/kdtree';
import {DocumentConfig} from "./document-config";
import {assertNever, assertThere} from "../../util/util";
import * as T from '../../backend/types';

export const layoutTextWithTempCanvas = (text: T.Parsed, config: DocumentConfig): Letter[] => {
    const canvas = document.createElement('canvas');
    canvas.width = 200;
    canvas.height = 600;
    const context = assertThere(canvas.getContext('2d'), "temporary canvas context");
    context.font = config.font;
    return layoutText(context, text, config);
}

const layoutText = (c: CanvasRenderingContext2D, element: T.Parsed, config: DocumentConfig): Letter[] => {
    const letters: Letter[] = [];

    let fontSize = +config.font.match(/(\d+)px/)![1];
    let x = 0;
    let y = 0;
    let index = 0;

    const layoutSimple = (text: string): void => {
        for (let i = 0; i < text.length; i++) {
            let letter = text[i];

            let width: number = 0;

            if (letter === "\n") {
                x = 0;
                y += fontSize;
            } else {
                width = c.measureText(letter).width;
            }

            if (x + width > config.width) {
                x = 0;
                y += fontSize;
            }

            letters.push({
                letter,
                font: c.font,
                boundingBox: {
                    LEFT: x,
                    UPPER: y,
                    RIGHT: x + width,
                    LOWER: y + fontSize
                },
                index
            });
            x += width;

            index++;
        }
    }

    const skipLines = (n: number = 1) => {
        for (let i = 0; i < n; i++) {
            y += fontSize;
            x = 0;
        }
    }

    const sub = (element: T.Parsed): void => {
        switch (element.kind) {
            case "Text":
                layoutSimple(element.text);
                break;
            case "Compound":
                element.subs.forEach(sub);
                break;
            case "Headline":
                c.font = config.headlineFont;
                fontSize = +config.font.match(/(\d+)px/)![1];
                skipLines(2);
                x = 0;
                layoutSimple(element.text);
                skipLines(2);
                c.font = config.font;
                fontSize = +config.font.match(/(\d+)px/)![1];
                break;
            case "ParsedList":
                skipLines(1);
                element.subs.forEach(subElement => {
                    layoutSimple(">");
                    index--;
                    sub(subElement);
                    skipLines(1);
                });
                break;
            case "Linebreak":
                skipLines(1);
                break;
            case "Paragraph":
                skipLines(1);
                element.subs.forEach(sub);
                skipLines(1);
                break;
            default:
                assertNever(element);
        }
    }

    sub(element);

    return letters;
}

export interface Letter {
    boundingBox: BoundingBox;
    letter: string;
    font: string;
    index: number;
}

