import React, {ReactElement, useState, useCallback, useMemo, useReducer, useEffect} from "react";

import "./ripple.scss";

import * as T from "../../backend/types";
import {toast} from "../../common-components/toast/Toast";
import {RippleNode} from "./RippleNode";
import * as RT from "./types";
import * as VM from "./vm";
import * as S from "./scroll";
import {BEM} from "../../util/util";
import {SchemaEvent} from "../schema/state";

export const Ripple = (props: Props): ReactElement => {
    const [canvasDimension, setCanvasDimension] = useState({x: 10, y: 10});
    const [rootNode, setRootNode] = useState<RT.RippleNode>(props.schema.outcome.rules || RT.emptyRippleNode());
    const [scoll, dispatchScroll] = useReducer(S.update, S.emptyScroll);
    const schemaInfo = useMemo(() => VM.getSchemaInfo(props.schema), [props.schema]);

    useEffect(() => {
        setRootNode(props.schema.outcome.rules || RT.emptyRippleNode());
    }, [props.schema.outcome.rules]);


    const setter = useCallback((path: RT.PathSegment[], newNode: RT.RippleNode | undefined): void => {
        setRootNode(old => {
            const newRoot = RT.update(old, path, newNode);
            if (newRoot === undefined) {
                props.setter({kind: "SetOutcomeLogicOpen", isOpen: false});
                props.setter({kind: "SetRippleOutcomeNode"});
                return old;
            } else {
                return newRoot;
            }
        });
    }, [setRootNode]);

    const viewModel = useMemo(() => VM.toVM(rootNode), [rootNode]);

    const vb = (width: number, height: number, scroll: S.ScrollState): string => {
        return `${scroll.offset.x} ${scroll.offset.y} ${width * scroll.scale} ${height * scroll.scale}`;
    }

    const withRef = useCallback((r: SVGSVGElement | null) => {
        r?.addEventListener('wheel', e => dispatchScroll({kind: 'Wheel', event: e}), {passive: false});
        if (r) {
            setCanvasDimension({x: r.clientWidth, y: r.clientHeight});
        }
    }, [dispatchScroll, setCanvasDimension]);

    const onOk = () => {
        const newRules = RT.toBackendNode(rootNode);
        if (newRules) {
            props.setter({kind: "SetOutcomeLogicOpen", isOpen: false});
            props.setter({kind: "SetRippleOutcomeNode", node: newRules});
        } else {
            toast("Es müssen alle Knoten korrekte Bedinungen haben, bevor gespeichert werden kann", true);
        }
    }

    return <div
        {...BEM(["ripple", "container", {open: props.isOpen}])}
    >
        <svg
            width="100%"
            height="100%"
            viewBox={vb(canvasDimension.x, canvasDimension.y, scoll)}
            onMouseUp={e => dispatchScroll({kind: 'Up', event: e.nativeEvent})}
            onMouseDown={e => dispatchScroll({kind: 'Down', event: e.nativeEvent})}
            onMouseMove={e => dispatchScroll({kind: 'Move', event: e.nativeEvent})}
            onMouseLeave={() => dispatchScroll({kind: 'Leave'})}
            ref={withRef}
            {...BEM(["ripple", "svg-root"])}
        >
            <defs>
                <marker id="arrowhead" markerWidth="10" markerHeight="7"
                    refX="10" refY="3.5" orient="auto">
                    <polygon points="0 0, 10 3.5, 0 7" />
                </marker>

                <marker id="arrowhead-new" markerWidth="10" markerHeight="7"
                    refX="10" refY="3.5" orient="auto">
                    <polygon fill="lightgray" stroke="lightgray" points="0 0, 10 3.5, 0 7" />
                </marker>

                <clipPath id="condition-clip">
                    <rect width={VM.nodeWidth} height={VM.nodeHeight} x="0" y="0" />
                </clipPath>
            </defs>
            {schemaInfo ? viewModel.map(vm =>
                <RippleNode
                    schemaInfo={schemaInfo}
                    node={vm}
                    updater={setter}
                />)
                : <text y={VM.textHeight} fill="red">Bitte erstellen Sie zunächst die Ausgangsknoten</text>
            }
            {/*<circle cx={scoll.circle.x} cy={scoll.circle.y} r="50" fill="rgba(255, 0, 0, 0.5)" />*/}
        </svg>
        <div></div>

        <button {...BEM(["ripple", "ok-button"])} onClick={onOk}>Ok</button>
        <button {...BEM(["ripple", "cancel-button"])} onClick={() => props.setter({kind: "SetOutcomeLogicOpen", isOpen: false})}>Abbrechen</button>

    </div >

        ;
}

export type Props = {
    schema: T.Schema;
    setter: (te: SchemaEvent) => void;
    isOpen: boolean;
}

