import React, {ReactElement} from "react";
import "./gold-mapping.scss";
import * as T from "../../backend/types";
import "./Schema.scss";
import {BEM} from "../../util/util";
import {askOneIn} from "../../common-components/toast/Toast";
import {selectSchemaNode} from "../../common-components/matcher/Matcher";
import {SchemaEvent} from "./state";
import {Delete} from "../../common-components/icon/Icon";
import {Descriptions} from "./descriptions";


export const GoldStandardMapping = (props: Props): ReactElement => {
  const onNew = async () => {
    const [, node] = await selectSchemaNode();
    const annotationStateTexts: {[annotationState in T.AnnotationState]: string} = {
      No: "Nein",
      Yes: "Ja",
      Neutral: "Neutral",
      PartialYes: "Partielles Ja",
      Unsubstantiated: "Unsubstantiated",
    };

    const goldstandarState = await askOneIn("Welchen Status hat die Goldstandard-Annotation?", annotationStateTexts);
    const expectedState = await askOneIn("Welcher Status wird erwartet?", annotationStateTexts);

    props.setter({kind: "SetGoldStandardMappings", mappings: [...props.mappings, {goldStandardNode: node, expectedOutcome: expectedState, goldStandardState: goldstandarState}]});

  };

  const onDelete = (toDelete: number): void => {
    props.setter({kind: "SetGoldStandardMappings", mappings: props.mappings.filter((_, i) => i !== toDelete)});
  };

  return <div
  >
    <table
      {...BEM(['goldstandard-mapping', 'table'])}
    >
      <thead>
        <tr>
          <th>Goldstandard Knoten</th>
          <th>Goldstandard Status</th>
          <th>Erwarteter Status</th>
        </tr>
      </thead>
      <tbody>
        {
          props.mappings.map((m, i) => <tr>
            <td>
              {props.descriptions.names[m.goldStandardNode] || "--"}
            </td>
            <td>
              {m.goldStandardState}
            </td>
            <td>
              {m.expectedOutcome}
            </td>
            <td>
              <Delete onClick={() => onDelete(i)} title="Löschen" />
            </td>
          </tr>)

        }
      </tbody>
    </table>
    <button onClick={onNew} {...BEM(['goldstandard-mapping', 'add-new'])}>Neues Mapping hinzufügen</button>
  </div>;
}

export type Props = {
  mappings: T.GoldStandardMapping[];
  descriptions: Descriptions;
  setter: (se: SchemaEvent) => void;
};
