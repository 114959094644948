import React, {ReactElement, useEffect, useMemo, useState} from "react";
import * as api from "../../backend/Api";

import './anlysis.scss';
import * as T from "../../backend/types";
import {Bannered} from "../../common-components/bannered/Bannered";
import {Loader} from "../../common-components/loader/Loader";
import {assertNever, classNames, BEM} from "../../util/util";
import {toast} from "../../common-components/toast/Toast";
import {setFixedError} from "../../common-components/fixed-error/FixedError";
import {getRedirectLink} from "../../hooks/useUrlData";
import {UrlData} from "../document/Document";
import {useSchemas} from "../../hooks/useSchema";
import {flattenNodes} from "../schema/tree";
import {Matcher} from "../../common-components/matcher/Matcher";

export const AutoAnnotate = (): ReactElement => {
    const [search, setSearch] = useState<T.PreMatcher | null>(null);

    const schemas = (useSchemas() || []).sort((a, b) => (a.name > b.name) ? 1 : -1);
    const [schema, setSchema] = useState<string | undefined>(undefined);
    const [inclusionNodes, setInclusionNodes] = useState<T.SchemaNode[]>([]);
    const [inclusion, setInclusion] = useState<boolean>(true);
    const [skipScoring, setSkipScoring] = useState<boolean>(false);
    const possibleNodes = useMemo(() => {
        if (schema) {
            const s = schemas.find(s => s.id === schema);
            if (s) {
                return flattenNodes(s.root);
            }
        }
        return [];
    }, [schema, schemas])

    useEffect(() => {setInclusionNodes([])}, [schema])

    const startAnalysis = async () => {
        if (schema) {
            const res = await api.startAnalysis({
                kind: "AutoAnnotateRequest",
                search: search || {'kind': 'And', subs: []},
                schema,
                inclusion,
                nodeNames: inclusionNodes.map(inNode => inNode.name),
                skipScoring
            });

            if (res.kind === "Ok") toast("Search Started");
            else if (res.kind === "UnknownError") toast(res.hint, true)
            else setFixedError(res);
        }
    };

    const toggleNode = (id: string) => {
        if (!!inclusionNodes.find(n => n.id === id)) {
            setInclusionNodes(inclusionNodes.filter(n => n.id !== id));
        } else {
            setInclusionNodes(inclusionNodes.concat(possibleNodes.filter(n => n.id === id)))
        }
    }

    return (
        <div >
            <Bannered
                name="Analyse Konfiguration"
                topRight={[<div onClick={startAnalysis}>run</div>]}
                additionalBem={[["default-layout", "column-element"]]}
            >
                <div {...BEM(["route-analysis", "sub-header", {first: true}])}>Dokumentfilter</div>
                <pre>

                    <Matcher
                        matcher={search}
                        setter={filter => setSearch(filter)}
                        context={({schemas: schemas})}
                        isRoot={true}
                    />
                </pre>

                <div className="route-analysis__sub-header">Schema</div>

                <select
                    {...BEM("select", ["document-schemas", "select-primary"])}
                    value={schema}
                    onChange={(ev) => setSchema(ev.target.value)}
                >
                    {
                        schemas.map(schema => <option key={schema.id} value={schema.id}>{schema.name}</option>)
                    }
                </select>

                <div className="route-analysis__sub-header">Scoring nicht auswerten</div>
                <input
                    type="checkbox"
                    checked={skipScoring}
                    onClick={() => setSkipScoring(old => !old)}
                />

                <div className="route-analysis__sub-header">Knotenfilter</div>

                <div {...BEM(["route-analysis", "in-ex-picker"])}>
                    <input type="radio" checked={inclusion} onClick={() => setInclusion(true)} />
                    <label>Ausschlieslich die gewählten</label>
                    <input type="radio" checked={!inclusion} onClick={() => setInclusion(false)} />
                    <label>Ausschlieslich nicht die gewählten</label>
                </div>

                <div {...BEM(["route-analysis", "in-ex-node-list"])}>
                    {
                        possibleNodes.map(s =>
                            <div {...BEM(["route-analysis", "node-row"])} key={s.id}>
                                <input
                                    type="checkbox"
                                    checked={!!inclusionNodes.find(n => n.id === s.id)}
                                    onClick={() => toggleNode(s.id)}
                                />
                                <div>{s.name}</div>
                            </div>
                        )
                    }
                </div>
            </Bannered>
            <AnalysisResults />
        </div>
    );
};

const AnalysisResults = (): ReactElement => {
    const [result, setResult] = useState<Results | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);

    const getResults = async () => {
        setLoading(true);
        const res = await api.getAnalysis({length: 10, offset: page * 10});
        setLoading(false);
        switch (res.kind) {
            case "AlwaysError":
                setFixedError(res);
                break;
            case "NotFound":
                setResult(undefined);
                break;
            case "GotData":
                if (res.data.kind == "AutoAnnotateState") {
                    const mean = res.data.results.length === 0 ? 0 : res.data.results.reduce((a, b) => a + b.tokenScore, 0) / res.data.results.length;
                    const start = new Date(res.data.common.started).getTime();
                    const current = new Date(res.data.common.lastUpdate).getTime();
                    const secondsRunning = (current - start) / 1000;
                    const perSecond = (res.data.common.numberOfDocumentsAlreadyScored / secondsRunning).toFixed(2);

                    setResult(
                        {
                            rows: res.data.results,
                            progress: `${res.data.common.numberOfDocumentsAlreadyScored}/${res.data.common.numberOfDocumentsToBeScored} (${perSecond} per second)`,
                            mean: mean
                        });
                } else {
                    toast("Falsche Antwort auf AutoAnnotation", true);
                }
                break;
            default:
                assertNever(res);
        }
    };

    return (
        <Bannered
            name="Ergebnis"
            topRight={[<div onClick={() => getResults()}>🗘</div>]}
            additionalBem={[["default-layout", "column-element"]]}
        >
            <div className={"component-loading-overlay"}>
                <div
                    className={classNames({"loading-overlay": true, loading: loading})}
                >
                    {loading ? <Loader input={"loading"} /> : null}
                </div>
                <div className={"loading-overlay-content"}>
                    {!result ?
                        <div>No analysis running</div>
                        : (
                            <table>
                                <tbody>
                                    <tr {...BEM(["route-analysis", "progress-row"])}>
                                        <td>
                                            <span onClick={() => {setPage(Math.max(0, page - 1)); getResults();}}>&lt;</span>
                                            Seite {page}
                                            <span onClick={() => {setPage(page + 1); getResults();}}>&gt;</span>
                                            , Indiziert: {result.progress}
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr {...BEM(["route-analysis", "mean-row"])}>
                                        <td></td>
                                        <td>{result.mean.toFixed(2)}</td>
                                    </tr>
                                    {result.rows.map((result) => (
                                        <ResultRow key={result.id} s={result} />
                                    ))}

                                </tbody>
                            </table>
                        )}
                </div>
            </div>
        </Bannered >
    );
};

type Results = {
    mean: number,
    progress: string,
    rows: T.ScoredDocument[]
}


const ResultRow = (props: {s: T.ScoredDocument}): ReactElement => {
    const link = getRedirectLink<UrlData>("document");

    return (
        <tr {...BEM(["route-analysis", "result-row"])} onClick={() => window.open(link({documentId: props.s.id}))}>
            <td>{props.s.name}</td>
            <td>{props.s.tokenScore.toFixed(2)}</td>
        </tr>
    );
};
