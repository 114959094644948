import {Answer} from '../../common-components/toast/Toast';
import {addX, Point} from './point';
import {RippleNode} from './RippleNode';
import * as TT from './types';
import * as T from '../../backend/types';
import {flattenNodes} from '../schema/tree';

export const nodeHeight = 100;
export const nodeWidth = 200;
export const gap = 70;
export const thenHeight = 30;
export const textHeight = 13;
export const deleteWidth = 15;

export type RippleNode = {
    location: Point,
    path: TT.PathSegment[];
    node?: TT.RippleNode;
    parent?: Point;
}

export const toVM = (root: TT.RippleNode): RippleNode[] => {
    const go = (node: TT.RippleNode, location: Point, path: TT.PathSegment[], parent?: Point): [RippleNode[], number] => {
        const current: RippleNode = {
            location, path, node, parent
        };

        const exceptPath = path.concat(["Except"]);
        const elsePath = path.concat(["Else"]);

        const emptyExcept: RippleNode = {
            location: addX(location, 1),
            path: exceptPath,
            parent: location
        };

        const [exceptBranch, highestUsedY] = node.exceptNode ?
            go(node.exceptNode, addX(location, 1), path.concat(["Except"]), location) :
            [[emptyExcept], location.y];


        const elseLocation = {x: location.x, y: highestUsedY + 1};
        const emptyElse: RippleNode = {
            location: elseLocation,
            path: elsePath,
            parent: location
        };

        const [elseBranch, highestUsedY2] = node.elseNode ?
            go(node.elseNode, elseLocation, path.concat(["Else"]), location) :
            [[emptyElse], highestUsedY + 1];

        return [[current].concat(exceptBranch).concat(elseBranch), highestUsedY2];
    }

    return go(root, {x: 0, y: 0}, [])[0];
}

export type SchemaInfo = {
    possiblesAnswers: Answer<string>[];
    nodes: {[id: string]: T.SchemaNode};
}

export const getSchemaInfo = (schema: T.Schema): SchemaInfo | undefined => {
    if (schema.outcome.nodes) {
        const flatNodes = flattenNodes(schema.outcome.nodes);
        const possiblesAnswers: Answer<string>[] = flatNodes.map(sn => ({payload: sn.id, message: sn.name}));
        const nodes = flatNodes.reduce((obj, node) => ({...obj, [node.id]: node}), {});

        return {
            possiblesAnswers,
            nodes
        };
    } else {
        return undefined;
    }
};
