import React, {ReactElement, useState} from 'react';

import './health-check.scss'
import {Bannered} from "../../common-components/bannered/Bannered";
import {Loader} from "../../common-components/loader/Loader";
import {useApi} from "../../hooks/useApi";
import * as api from "../../backend/Api";
import {switchOnKind} from "../../util/util";
import {setFixedError, setFixedErrorMessage} from "../../common-components/fixed-error/FixedError";
import {toast} from "../../common-components/toast/Toast";

export const ConfigOptions = () => {
    const [options, runner] = useApi(api.listConfigOptions);

    const state: "error" | "success" | undefined =
        options === "loading" || options === "not-yet-started" ? undefined :
            options.kind === "AlwaysError" ? "error" :
                undefined;

    return <Bannered
        canCollapse={true}
        name="Config Options"
        topRight={[<div onClick={runner}>get</div>]}
        state={state}>
        {
            options === "not-yet-started" ? null :
                options === "loading" || options.kind === "AlwaysError" ?
                    <Loader input={options} /> :
                    <div className="result">
                        <ConfigList options={options.data} />

                    </div>
        }
    </Bannered>
}

const ConfigList = (props: {options: {[key: string]: string}}): ReactElement => {
    const sortedKeys = Object.keys(props.options);
    sortedKeys.sort();


    return <table>
        <tbody>
            {
                sortedKeys.map(key => {
                    const value = props.options[key];

                    return <ConfigRow key={key} optionKey={key} value={value} />
                })
            }
        </tbody>
    </table>;
}

const ConfigRow = (props: {optionKey: string, value: string}): ReactElement => {
    const [value, setValue] = useState(props.value);

    const setter = async () => {
        const response = await api.setConfigOption({id: props.optionKey, value});
        switchOnKind(response, {
            AlwaysError: r => setFixedError(r),
            InvalidUserInput: () => setFixedErrorMessage("The user input could not be deserialized for the given key."),
            NotFound: () => setFixedErrorMessage("The option key could not be found. This is probably a programming error!"),
            Ok: () => toast("Option Set")
        });
    }

    return <tr>
        <td>{props.optionKey}</td>
        <td><input value={value} onChange={e => setValue(e.target.value)} /></td>
        <td><button onClick={setter}>Set</button></td>
    </tr>;
}
