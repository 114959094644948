import './StoredSearchMeta.scss';
import React, {ReactElement} from 'react';
import {BEM} from '../../util/util';
import * as S from './state';

export const StoredSearchMeta = (props: Props): ReactElement => {
    const selected = props.state.selected === undefined ? undefined : props.state.searches[props.state.selected];

    if (!selected) {
        return <div>
            Bitte wählen Sie zunächst eine gespeicherte Suche.
        </div>;
    }


    return <div {...BEM('stored-search-meta')}>
        <input    {...BEM('default-input')} placeholder={"Name"} value={selected.name} onChange={ev => {const newValue = ev.target.value; props.setState(S.updateSelected(ss => ({...ss, name: newValue})))}} />
        <textarea {...BEM('default-input', ['stored-search-meta', 'description'])} placeholder={"Beschreibung"} value={selected.description} onChange={ev => {const newValue = ev.target.value; props.setState(S.updateSelected(ss => ({...ss, description: newValue})))}} />
        <textarea {...BEM('default-input', ['stored-search-meta', 'test-text'])} placeholder={"Test Text"} value={selected.testText} onChange={ev => {const newValue = ev.target.value; props.setState(S.updateSelected(ss => ({...ss, testText: newValue})))}} />
    </div>;
}

interface Props {
    state: S.State;
    setState: (s: (S.State | ((s: S.State) => S.State))) => void;
}
