import React, {ReactElement, useState} from 'react';

import './lareda-crawler-overview.scss'
import {Bannered} from "../../common-components/bannered/Bannered";
import {Loader} from "../../common-components/loader/Loader";
import {useApiAuto} from "../../hooks/useApi";
import * as api from "../../backend/Api";
import * as T from "../../backend/types";

export const LaredaCrawlerOverview = (props: {}) => {
    const [request, setRequest] = useState<null | T.GetLaredaCrawlerOverviewRequest>(null);

    const setLastFailedSeen = (lastFailedSeen: undefined | string): void => {
        setRequest({...request, lastFailedSeen});
    }

    const setLastSuccessSeen = (lastSuccessSeen: undefined | string): void => {
        setRequest({...request, lastSuccessSeen});
    }

    const res = useApiAuto(api.getLaredaCrawlerOverview, request);

    const runner = (): void => {
        if (request === null) {
            setRequest({});
        } else {
            setRequest({...request});
        }
    }

    const state: "error" | "success" | undefined =
        res === "loading" || res === "not-yet-started" ? undefined :
            res.kind === "AlwaysError" ? "error" :
                undefined;

    return <div className="component-lareda-crawler-overview">
        <Bannered
            canCollapse={true}
            name="Crawler Overview"
            topRight={[<div onClick={runner}>run️</div>]}
            state={state}>
            {
                res === "not-yet-started" ? null :
                    res === "loading" || res.kind === "AlwaysError" ?
                        <Loader input={res} /> :
                        <div className="result">
                            <div className="label">Service Name</div>
                            <div className="data">{res.data.serviceName}</div>
                            <div className="label">Max Documents</div>
                            <div className="data">{res.data.maxDocumentsToDownload}</div>
                            <div className="label">Downloaded</div>
                            <div className="data">{res.data.numDownloaded}</div>
                            <div className="label">Failed</div>
                            <div className="data">{res.data.numFailed}</div>
                            <div className="label">Queued</div>
                            <div className="data">{res.data.numQueued}</div>
                            <div className="label">Suceeded</div>
                            <div className="data">{renderDocuments(setLastSuccessSeen, res.data.downloaded)}</div>
                            <div className="label">Failed</div>
                            <div className="data">{renderDocuments(setLastFailedSeen, res.data.failed)}</div>
                        </div>
            }
        </Bannered>
    </div>
}


const renderDocuments = (setter: (lastSeen: string | undefined) => void, documents: T.QueuedForDownload[]): ReactElement =>
    <div>
        <table>
            <tbody>
                {
                    documents.map((meta, i) => <tr key={i}>
                        <td>{meta.service}/{meta.serviceLocalId}</td>
                        <td>{meta.date}</td>
                        <td>{meta.name}</td>
                    </tr>)
                }
            </tbody>
        </table>
        <button onClick={() => setter(documents.length > 0 ? documents[documents.length - 1].serviceLocalId : undefined)}>Next</button>
    </div>



