import React, {ReactElement, useState} from 'react';
import {assertNever, BEM} from '../../util/util';
import {SchemaList} from '../schema/SchemaList';
import "./settle.scss";
import * as T from "../../backend/types";
import {FillingOut} from './FillingOut';

export const Settle = (): ReactElement => {
    const [state, setState] = useState<State>({kind: "SchemasLoaded"});

    return <div {...BEM("default-layout")}>
        <div {...BEM(["default-layout", "left"])}></div>
        <div {...BEM(["default-layout", "middle"])}>
            {renderState(state, setState)}
        </div>
        <div {...BEM(["default-layout", "right"])}></div>
    </div>;
}

const renderState = (state: State, setState: (s: State) => void): ReactElement => {
    switch (state.kind) {
        case 'SchemasLoaded': return renderSchemaSelection(setState);
        case 'FillingOutState': return <FillingOut schema={state.schema} onDone={() => setState({kind: "LoadingResults"})} />;
        case 'LoadingResults': return <div>LoadingResults</div>;
        case 'ShowingResults': return <div>ShowingResults</div>;
        default: return assertNever(state);
    }
}

const renderSchemaSelection = (setState: (s: State) => void): ReactElement => {
    return <div {...BEM("settle-schema-selection")}>
        <div>Bitte wählen Sie einen Sachverhalt zum klären aus</div>
        <SchemaList onSchemaLoaded={s => setState({kind: "FillingOutState", schema: s})} />
    </div>
}

type State = SchemasLoaded | FillingOutState | LoadingResults | ShowingResults;

type SchemasLoaded = {
    kind: "SchemasLoaded"
}


type FillingOutState = {
    kind: "FillingOutState"
    schema: T.Schema;
}

type LoadingResults = {
    kind: "LoadingResults"
}

type ShowingResults = {
    kind: "ShowingResults"
}
