import React from 'react';
import './App.scss';
import { Document } from "./routes/document/Document";
import { Link, Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Login } from "./routes/login/Login";
import { Search } from "./routes/search/Search";
import { Analysis } from "./routes/analysis/Analysis";
import { Admin } from "./routes/admin/Admin";
import { FixedError } from "./common-components/fixed-error/FixedError";
import { Toast } from "./common-components/toast/Toast";
import { Schema } from "./routes/schema/Schema";
import { StoredSearch } from "./routes/stored-search/StoredSearch";
import { Bill } from './routes/bill/Bill';
import { Settle } from './routes/settle/Settle';
import { Synonyms } from './routes/synonyms/Synonyms';
import { Upload } from './routes/upload/Upload';

const history = createBrowserHistory();

export const App = () => {

  return <Router history={history}>
    <div className="janator-root">
      <div className="janator-header">
        <nav>
          <div>
            <Link to="/">Login</Link>
            <Link to="/search">Suche</Link>
            <Link to="/stored-search">Gespeicherte Suchen</Link>
            <Link to="/admin">Instandhaltung</Link>
            <Link to="/schema">Schema</Link>
            <Link to="/bill">Rechnungen</Link>
            <Link to="/settle">Sachverhalt klären</Link>
            <Link to="/analysis">Analyse</Link>
            <Link to="/synonyms">Synonyme</Link>
            <Link to="/upload">Upload</Link>
          </div>
        </nav>
      </div>

      <Switch>
        <Route path="/search">
          <Search />
        </Route>
        <Route path="/document">
          <Document />
        </Route>
        <Route path="/admin">
          <Admin />
        </Route>
        <Route path="/stored-search">
          <StoredSearch />
        </Route>
        <Route path="/schema">
          <Schema />
        </Route>
        <Route path="/bill">
          <Bill />
        </Route>
        <Route path="/settle">
          <Settle />
        </Route>
        <Route path="/analysis">
          <Analysis />
        </Route>
        <Route path="/synonyms">
          <Synonyms />
        </Route>
        <Route path="/upload">
          <Upload />
        </Route>
        <Route path="/">
          <Login />
        </Route>
      </Switch>
    </div>
    <FixedError />
    <Toast />
  </Router>;
}

