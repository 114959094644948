import React, { ReactElement } from "react";

import "./admin.scss";
import { HealthCheck } from "./HealthCheck";
import { UpdateDefaultSearches } from "./UpdateDefaultSearches";
import { LaredaSearch } from "./LaredaSearch";
import { LaredaCrawlerOverview } from "./LaredaCrawlerOverview";
import { ConfigOptions } from "./ConfigOptions";
import { Systems } from "./Systems";
import { LuceneQueryTest } from "./LuceneQueryTest";
import { UpdateCourtMetadata } from "./UpdateCourtMetadata";
import { NormalizeText } from "./NormalizeText";
import { PruneNonBought } from "./PruneNonBought";

export const Admin = (): ReactElement => {
  return (
    <div className="route-admin">
      <div className="middle">
        <NormalizeText />
        <HealthCheck />
        <UpdateDefaultSearches />
        <LaredaSearch />
        <LaredaCrawlerOverview />
        <ConfigOptions />
        <Systems />
        <LuceneQueryTest />
        <UpdateCourtMetadata />
        <PruneNonBought />
      </div>
    </div>
  );
};
