import React, {ReactElement, FormEvent, useState} from "react";
import { checkLogin } from "../../backend/Api";
import { setFixedError } from "../../common-components/fixed-error/FixedError";

import './login.scss'
import {switchOnKind} from "../../util/util";
import {toast} from "../../common-components/toast/Toast";

export const Login = (): ReactElement => {
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");

    const doLogin = async (ev: FormEvent): Promise<void> => {
        ev.preventDefault();
        const response = await checkLogin(name, password);
        return switchOnKind(response, {
            'AlwaysError': (r) => setFixedError(r),
            'Ok': () => toast("Login Successful"),
        });
    }

    return <div className="route-login">
        <span>Please log into Janator</span>
        <form onSubmit={doLogin}>
            <div className="input-line">
                <label>
                    User Name
                </label>
                <input autoFocus={true} onChange={ev => setName(ev.target.value)} name="name" value={name}/>
            </div>
            <div className="input-line">
                <label>Password</label>
                <input onChange={ev => setPassword(ev.target.value)} name="name" value={password}/>
            </div>
            <button>Login</button>
        </form>
    </div>;
}