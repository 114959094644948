import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import {assertNever, BEM} from '../../util/util';
import {UrlData} from "../document/Document";
import "./settle.scss";
import * as T from "../../backend/types";
import * as api from "../../backend/Api";
import {setFixedError} from '../../common-components/fixed-error/FixedError';
import {toast} from '../../common-components/toast/Toast';
import {getRedirectLink} from '../../hooks/useUrlData';

export const FillingOut = (props: Props): ReactElement => {
  const nodes = useMemo(() => {
    const nodes = getAllNodes(props.schema.root)
    nodes.shift();
    return nodes.filter(n => n.askEndUser);
  }, [props.schema]);

  const [values, setValues] = useState<{[nodeId: string]: T.AnnotationState}>({});
  const [winningChance, setWinningChance] = useState<undefined | number>(undefined);
  const [results, setResults] = useState<T.DocumentAdjudicationResult[] | null>(null);

  const showEnd = Object.keys(values).length === nodes.length;

  useEffect(() => {
    let canceled = false;

    setTimeout(() => {
      if (!canceled) {
        document.getElementById("end")?.scrollIntoView({behavior: 'smooth'});
      }
    }, 0)
    return () => {canceled = true};

  }, [showEnd])

  const adjudicate = () => {
    api.adjudicate({
      schemaId: props.schema.id,
      userInput: values
    }).then(response => {
      switch (response.kind) {
        case 'AlwaysError': setFixedError(response); break;
        case 'NotFound': toast("Could not find"); break;
        case 'UnknownError': toast("Error: " + response.hint, true); break;
        case 'GotData':
          setWinningChance(response.data.winningChance);
          setResults(response.data.documents);
          break;
        default: assertNever(response);
      }
    });
  }


  return <div {...BEM("settle-filling-out")}>
    <div {...BEM(["settle-filling-out", "track", {"shown": results === null}])}>
      {nodes.map(n => {
        const buttonState = (state: T.AnnotationState): string => {
          if (values[n.id] === state) {
            return (state || "Neutral") + "-clicked";
          } else {
            return (state || "Neutral");
          }
        }

        const clickOn = (annotationState: T.AnnotationState) => {
          setValues({
            ...values, [n.id]: annotationState
          })
        }

        return <div key={n.id} {...BEM(["settle-filling-out", "node"])}>
          <div {...BEM(["settle-filling-out", "question-text"])}>{n.name}</div>
          <div {...BEM(["settle-filling-out", "buttons"])}>
            <div
              {...BEM(["settle-filling-out", "button", {"clickable": true, "yes": true, "left": true, [buttonState("Yes")]: true}])}
              onClick={() => clickOn("Yes")}
            >Ja</div>
            <div
              onClick={() => clickOn("Neutral")}
              {...BEM(["settle-filling-out", "button", {"clickable": true, "irrelevant": true, "middle": true, [buttonState("Neutral")]: true}])}
            >Irrelevant</div>
            <div
              onClick={() => clickOn("No")}
              {...BEM(["settle-filling-out", "button", {"clickable": true, "no": true, "right": true, [buttonState("No")]: true}])}
            >Nein</div>
          </div>
        </div>;
      })
      }
      <div id="end" {...BEM(["settle-filling-out", "completed", {shown: showEnd && results == null}])}>
        <div {...BEM(["settle-filling-out", "completed-text"])}>
          Fallaufnahme abeschlossen!
                </div>
        <div onClick={adjudicate} {...BEM(["settle-filling-out", "completed-button"])}>
          Prognose starten
                </div>
      </div>

    </div>
    <div {...BEM(["settle-filling-out", "results", {shown: results !== null}])}>
      <div className="result">
        <table>
          <tbody>
            {(results || []).map((result) => (
              <ResultRow s={result} />
            ))}
          </tbody>
        </table>
        <div>{winningChance}</div>
      </div>
    </div>
  </div>;
}

const getAllNodes = (n: T.SchemaNode): T.SchemaNode[] => {
  let ret: T.SchemaNode[] = [n];

  for (let sub of n.subs) {
    ret = ret.concat(getAllNodes(sub))
  }

  return ret;
}

type Props = {
  schema: T.Schema;
  onDone: (selected: [string, T.AnnotationState][]) => void;
}


const ResultRow = (props: {s: T.DocumentAdjudicationResult}): ReactElement => {
  const link = getRedirectLink<UrlData>("document");

  return (
    <tr onClick={() => window.open(link({documentId: props.s.documentId}))}>
      <td>{props.s.name}</td>
      <td>{props.s.score}</td>
      <td>{JSON.stringify(props.s.outcome)}</td>
    </tr>
  );
};
