import React, {ReactElement, useMemo, useReducer, useState} from "react";

import * as T from "../../backend/types";
import * as api from "../../backend/Api";
import {Bannered} from "../../common-components/bannered/Bannered";
import {toast} from "../../common-components/toast/Toast";
import {assertNever, BEM, showBytes} from "../../util/util";
import {Descriptions} from "../schema/descriptions";
import {flattenNodes} from "../schema/tree";
import {setFixedError} from "../../common-components/fixed-error/FixedError";
import {getRedirectLink} from "../../hooks/useUrlData";
import {UrlData} from "../document/Document";

export const Relevance = (props: Props): ReactElement => {
  const [state, dispatch] = useReducer(handleEvent, {newRequest: {}});
  const [result, setResult] = useState<undefined | T.AnalyzeRelevanceState>(undefined);

  const groups = useMemo(() => {
    if (state.newRequest.groupSchemaId) {
      return props.schemas.flatMap(s => {
        if (s.id == state.newRequest.groupSchemaId) {
          const ret = flattenNodes(s.root);
          ret.shift();
          return ret;
        } else {
          return [];
        }
      });
    } else {
      return [];
    }
  }, [props.schemas, state.newRequest.groupSchemaId]);

  const startAnalysis = async () => {
    if (state.newRequest.groupNodeId && state.newRequest.groupSchemaId && state.newRequest.relevanceSchema) {
      const res = await api.startAnalysis({
        kind: "AnalyzeRelevanceRequest",
        testSchema: state.newRequest.groupSchemaId,
        testGroup: state.newRequest.groupNodeId,
        schema: state.newRequest.relevanceSchema

      });

      if (res.kind === "Ok") toast("Search Started");
      else if (res.kind === "UnknownError") toast(res.hint, true)
      else setFixedError(res);
    }
    else {
      toast("Bitte erst alles auswählen", true);
    }
  };

  const getResults = async () => {
    const res = await api.getAnalysis({length: 10, offset: 0});

    switch (res.kind) {
      case "AlwaysError":
        setFixedError(res);
        break;
      case "NotFound":
        toast("Keine Analyse gestarted", true);
        break;
      case "GotData":
        if (res.data.kind == "AnalyzeRelevanceState") {
          setResult(res.data);
        } else {
          toast("Falsche Antwort auf AutoAnnotation", true);
        }
        break;
      default:
        assertNever(res);
    }
  };

  const getResultsExcel = async () => {
    const res = await api.getAnalysisExcel();

    switch (res.kind) {
      case "AlwaysError":
        setFixedError(res);
        break;
      case "NotFound":
        toast("Keine Analyse gestarted", true);
        break;
      case "GotData":
        showBytes(res.data, "results.xlsx");
        break;
      default:
        assertNever(res);
    }
  };

  return <div>
    <Bannered
      name="Konfiguration"
      topRight={[<div onClick={startAnalysis}>run</div>]}
      additionalBem={[["default-layout", "column-element"]]}
    >
      <div {...BEM(["route-analysis", "sub-header", {first: true}])}>Neuer Test</div>

      <div {...BEM(["route-analysis", "selection-table"])}>
        <div{...BEM(["route-analysis", "label", {first: true}])}>Testgruppen-Schema:</div>
        <select className="select" onChange={e => dispatch({kind: "SelectGroupSchema", id: e.target.value})}>
          <option></option>
          {
            props.schemas.map(s =>
              <option key={s.id} value={s.id}>{s.name}</option>
            )

          }
        </select>
        <div{...BEM(["route-analysis", "label", {first: true}])}>Testgruppen-Knoten:</div>
        <select className="select" onChange={e => dispatch({kind: "SelectGroupNode", id: e.target.value})}>
          <option></option>
          {
            groups.map(g =>
              <option key={g.id} value={g.id}>{g.name}</option>
            )

          }

        </select>
        <div{...BEM(["route-analysis", "label", {first: true}])}>Relevanz-Schema:</div>
        <select className="select" onChange={e => dispatch({kind: "SelectRelevanceSchema", id: e.target.value})}>
          <option> </option>
          {
            props.schemas.map(s =>
              <option key={s.id} value={s.id}>{s.name}</option>
            )

          }
        </select>
      </div>

      <div {...BEM(["route-analysis", "sub-header"])}>Eingereihte Tests</div>

      <div {...BEM(["route-analysis", "sub-header"])}> </div>

    </Bannered>

    <Bannered
      name="Auswertung"
      topRight={[<div onClick={getResults}>run</div>]}
      additionalBem={[["default-layout", "column-element"]]}
    >
      {!result ? "Noch nichts" :
        <div>
          <div onClick={getResultsExcel}>Als Excel Downloaden</div>
          <div{...BEM(["route-analysis", "sub-header", {first: true}])}>Fortschritt:</div>
          <div>{result.common.numberOfDocumentsAlreadyScored}/{result.common.numberOfDocumentsToBeScored}</div>

          <div>
            <div{...BEM(["route-analysis", "sub-header"])}>Ergebnisse:</div>
            <div {...BEM(["route-analysis", "selection-table"])}>
              <div>Richtig-Positiv:</div>
              <div>{result.result.truePositive}</div>
              <div>Falsch-Positiv:</div>
              <div>{result.result.falsePositive}</div>
              <div>Richtig-Negativ:</div>
              <div>{result.result.trueNegativ}</div>
              <div>Falsch-Negativ:</div>
              <div>{result.result.falseNegative}</div>
              <div>Precision:</div>
              <div>{precision(result.result)}</div>
              <div>Recall:</div>
              <div>{recall(result.result)}</div>
            </div>

            <div {...BEM(["route-analysis", "label"])}>Richtig-Positiv</div>
            <div {...BEM(["route-analysis", "max-height-result-container"])}>
              {result.result.truePostiveResults.map(meta => <ResultRow s={meta} />)}
            </div>

            <div {...BEM(["route-analysis", "label"])}>Falsch-Positiv</div>
            <div {...BEM(["route-analysis", "max-height-result-container"])}>
              {result.result.falsePostiveResults.map(meta => <ResultRow s={meta} />)}
            </div>

            <div {...BEM(["route-analysis", "label"])}>Richtig-Negativ</div>
            <div {...BEM(["route-analysis", "max-height-result-container"])}>
              {result.result.trueNegativeResults.map(meta => <ResultRow s={meta} />)}
            </div>

            <div {...BEM(["route-analysis", "label"])}>Falsch-Negativ</div>
            <div {...BEM(["route-analysis", "max-height-result-container"])}>
              {result.result.falseNegativeResults.map(meta => <ResultRow s={meta} />)}
            </div>

          </div>
        </div>
      }
    </Bannered>

  </div>;
}

const ResultRow = (props: {s: T.DocumentMeta}): ReactElement => {
  const link = getRedirectLink<UrlData>("document");

  return (
    <div {...BEM(["route-analysis", "result-row"])} onClick={() => window.open(link({documentId: props.s.id}))}>
      <td>{props.s.name}</td>
    </div>
  );
};

const precision = (group: T.RelevanceGroupResult): number => {
  const both = group.truePositive + group.falsePositive;
  if (both === 0) return 0;
  else return group.truePositive / both;
}

const recall = (group: T.RelevanceGroupResult): number => {
  const both = group.truePositive + group.falseNegative;
  if (both === 0) return 0;
  else return group.truePositive / both;
}

type State = {
  newRequest: SingleNewRequest;
}

type SingleNewRequest = {
  groupSchemaId?: string;
  groupNodeId?: string;
  relevanceSchema?: string;
}

type StateEvent = {
  kind: "SelectGroupSchema";
  id: string;
} | {
  kind: "SelectGroupNode";
  id: string;
} | {
  kind: "SelectRelevanceSchema";
  id: string;
}

const handleEvent = (state: State, event: StateEvent): State => {
  switch (event.kind) {
    case 'SelectRelevanceSchema':
      return {...state, newRequest: {...state.newRequest, relevanceSchema: event.id}};
    case 'SelectGroupNode':
      return {...state, newRequest: {...state.newRequest, groupNodeId: event.id}};
    case 'SelectGroupSchema':
      return {...state, newRequest: {groupSchemaId: event.id}};
    default: return assertNever(event);
  }
}

type Props = {
  schemas: T.Schema[];
  descriptions: Descriptions;
}
