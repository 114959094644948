import React, {ReactElement, useState} from "react";
import * as api from '../../backend/Api';
import {BEM, assertNever} from "../../util/util";
import './OverlapAnalysis.scss';
import {State, StateEvent} from "./state";
import * as T from "../../backend/types";

export const OverlapAnalysis = (props: Props): ReactElement => {
    const {state, dispatch} = props;
    const [result, setResult] = useState<T.OverlapAnalysis | null>(null)

    const execute = () => {
        if (state.schemaSelection && state.schemaSelection.compareWith) {
            const primary = state.document.expandedSchemas[state.schemaSelection.id]
            const secondary = state.document.expandedSchemas[state.schemaSelection.compareWith]

            if (primary && secondary) {
                api.analyseOverlap({primary, secondary}).then(response => {
                    switch (response.kind) {
                        case "AlwaysError": console.log(response); break;
                        case "GotData":
                            dispatch({
                                kind: "SetStateEvent", state: {
                                    ...state,
                                    document: {
                                        ...state.document,
                                        expandedSchemas: {...state.document.expandedSchemas, [response.data.derived.schema.id]: response.data.derived}
                                    }
                                }
                            });
                            setResult(response.data);
                            break;
                        default: assertNever(response);
                    }
                    console.log(response)
                });
            }
        }
    }

    const scores: [string, number][] = [];
    if (result) {
        const keys = Object.keys(result.scores);
        keys.sort();

        keys.forEach(k => scores.push([k, result.scores[k]]));
    }

    return <div {...BEM('document-overlap-analysis')}>
        <button {...BEM(["document-overlap-analysis", "button"], "button")} onClick={execute}>Ausführen</button>
        {
            scores.map((s, i) =>
                <div {...BEM(["document-overlap-analysis", "score-line"])} key={i}>
                    <div>{s[0]}</div>
                    <div>{s[1]}</div>
                </div>
            )
        }
    </div>;
}

export interface Props {
    state: State,
    dispatch: (se: StateEvent) => void;
}

