import { Lens, Optional } from 'monocle-ts';
import { some, none } from 'fp-ts/lib/Option';


export function showBytes(base64: string, filename: string): void {
  let binaryString = window.atob(base64);
  let binaryLen = binaryString.length;
  let bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  let blob = new Blob([bytes]);
  let link = document.createElement('a');
  let href = window.URL.createObjectURL(blob);
  link.href = href;
  link.download = filename;
  document.body.appendChild(link);
  link.setAttribute("type", "hidden");
  link.click();
}

export const toBase64 = (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result?.toString().split(',')[1]);
  reader.onerror = reject;
});

export const toText = (file: File) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsText(file);
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = reject;
});


export const assertNever = (n: never): never => {
  throw new Error("reached never: " + JSON.stringify(n));
};

export const assertThere = <T>(t: T | null | undefined, name: string): T => {
  if (t === null || t === undefined) {
    throw new Error(
      `Did not expect ${name} to be ${t === null ? "null" : "undefined"}`
    );
  }

  return t;
};

export const hashCode = (str: string): number => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

export const hashStringToRgb = (str: string): string => {
  const i = hashCode(str);
  const c = (i & 0x00ffffff).toString(16).toUpperCase();

  return "00000".substring(0, 6 - c.length) + c;
};

export const classNames = (classMap: {
  [className: string]: boolean;
}): string => {
  const classes = Object.keys(classMap);
  classes.sort();
  return classes.filter((c) => classMap[c]).join(" ");
};

export type SimpleObject = { [key: string]: any };

type SwitchOnKindMap<I extends SimpleObject, O> = {
  [key in I["kind"]]: (i: I extends { kind: key } ? I : never) => O;
};

export const switchOnKind = <I extends SimpleObject, O>(
  i: I,
  m: SwitchOnKindMap<I, O>
): O => (m as any)[i.kind](i);

export const BEM = (...elements: SingleBem[]): { className: string } => {
  const classString = elements
    .flatMap((element) => {
      if (typeof element === "string") {
        return [element];
      } else if (typeof element[1] === "boolean") {
        if (element[1]) {
          return [element[0]];
        } else {
          return [];
        }
      } else {
        const [b, e, m, active] = element;

        if (active === false) {
          return [];
        } else {
          const firstPart = `${b}${e ? "__" + e : ""}`;
          const modifiers =
            m === undefined
              ? [""]
              : [""].concat(
                typeof m === "string"
                  ? ["--" + m]
                  : Object.keys(m)
                    .filter((k) => m[k])
                    .map((k) => "--" + k)
              );

          return modifiers.map((m) => firstPart + m);
        }
      }
    })
    .join(" ");

  return {
    className: classString,
  };
};

export type SingleBem =
  | string
  | [string, boolean]
  | [string, string?, BemModifier?, boolean?];

// If the modifier is a single string:
// BEM("l", "block", "centered") => "l-block l-block--centered"
// If the modifier is an object:
// BEM("l", "block", "element", {"centered: false, "is-active": true, "is-focused": true}) =>
// "l-block-element l-block-element--is-active l-block-element--is-focused"
export type BemModifier = string | { [modifier: string]: boolean };

export const arrayLast = <A>(): Optional<A[], A> =>
  new Optional(arr => arr.length > 0 ? some(arr[arr.length - 1]) : none, elem => arr => arr.concat([elem]));

export const guaranteedProperty = <A>(key: string): Lens<{ [key: string]: A }, A> =>
  new Lens(s => s[key], a => s => ({ ...s, [key]: a }));
