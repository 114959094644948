import React, {ReactElement} from "react";

import * as T from "../../backend/types";
import {AddMenu} from "../../routes/search/Search";
import {makeAddMenu} from "../matcher/Matcher";

export function ScorerList<Single, Context>(props: Props<Single, Context>): ReactElement {
    const setScorer = (i: number) => (s: null | Single): void => {
        const newScorers = (props.scorers || []).slice();
        if (s !== null) newScorers.splice(i, 1, s);
        else newScorers.splice(i, 1);
        props.setter(newScorers);
    };

    const addScorer = (s: T.PreMatcher): void => {
        const newScorers = (props.scorers || []).slice();
        newScorers.splice(newScorers.length, 1, props.createNew(s));
        props.setter(newScorers);
    };

    const addMenu = makeAddMenu(matchers => matchers.forEach(addScorer));

    const RenderSub = props.RenderSub;

    return <div className="search-top">
        <div className={"search-scorers"}>
            {(props.scorers || []).map((scorer, i) =>
                <RenderSub scorer={scorer} setter={setScorer(i)} context={props.context} />)}

        </div>
        <AddMenu menu={addMenu} />
    </div>
}

export type Props<Single, Context> = {
    scorers: Single[] | undefined | null;
    context: Context;
    setter: (newScorers: Single[]) => void;

    RenderSub: (props: {scorer: Single, setter: (s: Single | null) => void, context: Context}) => ReactElement;
    createNew: (m: T.PreMatcher) => Single;
}
