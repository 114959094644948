import {PreMatcher, Schema, SchemaNode, Scorer, Search, SchemaScorer} from "../../backend/types";
import {v4} from "uuid";

export const newNode = (): SchemaNode => ({
  id: v4(),
  or: false,
  negated: false,
  name: "",
  subs: [],
  search: [],
  yesText: "",
  noText: "",
  endUserExplanation: "",
  askEndUser: true,
});

export const findNode = (root: SchemaNode, id: string): SchemaNode | null => {
  if (root.id === id) {
    return root;
  } else {
    for (let i = 0; i < root.subs.length; i++) {
      const result = findNode(root.subs[i], id);
      if (result !== null) {
        return result;
      }
    }

    return null;
  }
};

export const flattenNodes = (root: SchemaNode): SchemaNode[] => {
  let result: SchemaNode[] = [root];
  for (let sub of root.subs) {
    result = result.concat(flattenNodes(sub));
  }
  return result;
};

export const getPathOfNode = (
  root: SchemaNode,
  id: string
): number[] | null => {
  if (root.id === id) {
    return [];
  } else {
    for (let i = 0; i < root.subs.length; i++) {
      const result = getPathOfNode(root.subs[i], id);
      if (result !== null) {
        return [i].concat(result);
      }
    }

    return null;
  }
};

export const modify = (
  root: SchemaNode,
  path: number[],
  f: (n: SchemaNode) => SchemaNode
): SchemaNode | null => {
  if (path.length === 0) {
    return f(root);
  } else {
    const [head, ...tail] = path;

    if (root.subs[head] === undefined) {
      return root;
    } else {
      const newNode = modify(root.subs[head], tail, f);
      if (newNode === null) {
        return null;
      }
      const subCopy = [...root.subs];
      subCopy.splice(head, 1, newNode);
      return {...root, subs: subCopy};
    }
  }
};

export const addSibling = (
  root: SchemaNode,
  target: string
): SchemaNode | null => {
  const path = getPathOfNode(root, target);
  if (path === null || path.length === 0) {
    return null;
  } else {
    const withoutLast = path.slice(0, path.length - 1);
    return modify(root, withoutLast, (n) => ({
      ...n,
      subs: n.subs.concat([newNode()]),
    }));
  }
};

export const moveTo = (
  root: SchemaNode,
  target: string,
  dragee: string,
  asChild: boolean
): SchemaNode | null => {
  const drageeP = getPathOfNode(root, dragee);
  const drageeT = findNode(root, dragee);

  if (drageeP === null || drageeT === null) {
    return null;
  }

  if (drageeP.length === 0) {
    return null; // root can not be moved;
  }

  const drageeIndex = drageeP[drageeP.length - 1];
  const draggeParentP = drageeP.slice(0, drageeP.length - 1);

  const afterDelete = modify(root, draggeParentP, (n) => ({
    ...n,
    subs: n.subs.filter((_, i) => i !== drageeIndex),
  }));

  if (afterDelete === null) {
    return null; // this can't actually happen
  }

  const targetP = getPathOfNode(afterDelete, target);

  if (targetP === null) {
    return null; // We moved into ourselfs, so we are gone.
  }

  if (asChild) {
    return modify(afterDelete, targetP, (n) => ({
      ...n,
      subs: [drageeT].concat(n.subs),
    }));
  } else {
    if (targetP.length === 0) {
      return null; // can't move as child of root
    } else {
      const targetIndex = targetP[targetP.length - 1];
      const targetParentP = targetP.slice(0, targetP.length - 1);

      return modify(afterDelete, targetParentP, (targetParent) => {
        const newSubs = [...targetParent.subs];
        newSubs.splice(targetIndex + 1, 0, drageeT);
        return {...targetParent, subs: newSubs};
      });
    }
  }
};

export const createCompoundSearch = (schema: Schema): Search<PreMatcher> => {
  const go = (root: SchemaNode): Scorer<PreMatcher>[] => {
    const mapScorer = (schemaScorer: SchemaScorer): Scorer<PreMatcher> => {
      return {
        kind: "AssocicatedScorer",
        score: schemaScorer.score,
        fact: {
          node: root.id,
          state: schemaScorer.state,
        },
        matcher: schemaScorer.matcher
      };
    }

    const forThisNode: Scorer<PreMatcher>[] = root.search.length > 0 ? [{kind: "DropContainedScorer", subs: root.search.map(mapScorer)}] : [];

    return forThisNode.concat(
      root.subs.flatMap(go)
    );
  }

  return {
    filter: schema.filter,
    scorers: go(schema.root)
  };
};

export const deepClone = (root: SchemaNode): SchemaNode => {
  const subs = root.subs.map(deepClone);

  return {...root, id: v4(), subs: subs};
};

export const deleteNode = (
  root: SchemaNode,
  target: string
): SchemaNode | null => {
  const path = getPathOfNode(root, target);

  if (path === null) {
    return root;
  } else if (path.length === 0) {
    return null;
  }

  const index = path[path.length - 1];
  const parent = path.slice(0, path.length - 1);

  return modify(root, parent, (n) => ({
    ...n,
    subs: n.subs.filter((_, i) => i !== index),
  }));
};
