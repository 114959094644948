import React, {
    ReactElement,
    useMemo,
    useReducer,
    useRef
} from "react";
import {UnassociatedAnnotation} from './model/annotation';
import {OverlapAnalysis} from './OverlapAnalysis';
import {TextCanvas} from "./TextCanvas";
import {layoutTextWithTempCanvas, Letter} from "./letter";
import "./document.scss";
import {defaultDocumentConfig} from "./document-config";
import {Bannered} from "../../common-components/bannered/Bannered";
import {AnnotationCanvas} from "./AnnotationCanvas";
import * as State from './state';
import * as api from '../../backend/Api';
import * as T from '../../backend/types';
import {KdTree} from "../../util/kdtree";
import {NewAnnotation} from "./NewAnnotation";
import {Annotations} from "./Annotations";
import {ContextMenu} from "../../common-components/ContextMenu";
import {BEM} from "../../util/util";
import {useUrlData} from "../../hooks/useUrlData";
import {Tabbed} from "../../common-components/Tabbed";
import {Loader} from "../../common-components/loader/Loader";
import {useApiAuto} from "../../hooks/useApi";
import {AnnotationHoverCanvas} from "./AnnotationHoverCanvas";
import {Schemas} from './Schemas';
import {Header} from './Header';

export function Document(): ReactElement {
    const [documentId] = useUrlData<UrlData>()('documentId');
    const document = useApiAuto(api.getDocument, documentId);

    if (document === "not-yet-started") {
        return <Loader input={<div>It seems that the document is missing from the query string.</div>} />;
    } if (document === 'loading' || document.kind === "AlwaysError") {
        return <Loader input={document} />;
    } else if (document.kind === "GotData") {
        return <LoadedDocument doc={document.data} />;
    } else {
        return <Loader input={<div>The document could not be found. Maybe it got deleted. Please search for another document.</div>} />;
    }
}

export function LoadedDocument(props: {doc: T.ExpandedDocument}): ReactElement {
    const initialState = useMemo(() => buildState(props.doc), [props.doc]);
    const [state, dispatch] = useReducer(State.dispatch, initialState);
    const canvasWrapper = useRef<HTMLDivElement>();

    const annotationClickMenu = (() => {
        const withClose = (event: State.StateEvent): void => {
            dispatch(event);
            dispatch({kind: "CloseAnnotationContextMenuEvent"});
        }
        if (state.selectedAnnotation && state.selectedAnnotation.contextMenuAt && state.schemaSelection && state.document.expandedSchemas[state.schemaSelection.id]) {
            const groups = State.getAnnotationsOfCurrentSchema(state).map(a => a.groupnumber);
            const uniqueGroups = [];
            for (let g of groups) {
                if (g !== undefined && g !== null && uniqueGroups.indexOf(g) === -1) {
                    uniqueGroups.push(g);
                }
            }
            const id = state.selectedAnnotation.annotation;
            return <ContextMenu
                x={state.selectedAnnotation.contextMenuAt.x}
                y={state.selectedAnnotation.contextMenuAt.y}
                onClose={() => dispatch({kind: "CloseAnnotationContextMenuEvent"})}
                menu={({
                    name: "Annotationsmenue",
                    subs: [
                        {
                            name: "Status zuweisen", subs: [
                                {name: "Ja", callback: () => withClose({kind: "SetAnnotationStateEvent", annotationId: id, state: "Yes"})},
                                {name: "Partiell Ja", callback: () => withClose({kind: "SetAnnotationStateEvent", annotationId: id, state: "PartialYes"})},
                                {name: "Nein", callback: () => withClose({kind: "SetAnnotationStateEvent", annotationId: id, state: "No"})},
                                {name: "Unsubstantiiert", callback: () => withClose({kind: "SetAnnotationStateEvent", annotationId: id, state: "Unsubstantiated"})},
                                {name: "Neutral", callback: () => withClose({kind: "SetAnnotationStateEvent", annotationId: id, state: "Neutral"})},
                            ],
                        },
                        {
                            name: "Gruppieren", subs: [
                                {name: "Neue Gruppe erstellen", callback: () => withClose({kind: "CreateAndAddToNewGroupEvent", annotationId: id})},
                                {name: "Gruppe Entfernen", callback: () => withClose({kind: "SetAnnotationGroupEvent", annotationId: id, group: undefined})},
                                ...uniqueGroups.map(g => ({name: "Zur Gruppe " + g + " hinzufügen", callback: () => withClose({kind: "SetAnnotationGroupEvent", annotationId: id, group: g})}))
                            ]
                        },
                        {name: "Notiz bearbeiten", callback: () => withClose({kind: "SetAnnotationCommentVisibilityEvent", visible: true})},
                        {name: "Entfernen", callback: () => withClose({kind: "DeleteSelectedAnnotationEvent"})},
                        {name: "Auf gleiche Selection setzen", callback: () => withClose({kind: "ChangeSelectedAnnotationToTextSelectionEvent"}), },
                    ]
                })} />
        } else {
            return null;
        }
    })();

    return <div {...BEM('document-route-root', 'default-layout')}>
        {annotationClickMenu}
        <div {...BEM(['default-layout', 'header'])}>
            <Header state={state} dispatch={dispatch} />
        </div>
        <div {...BEM(['default-layout', 'left', {'no-scroll': true, '2-children': true}])}>
            <Bannered name={"Neue Annotation"} scroll={true}
                additionalBem={[["default-layout", "column-element"]]}
            >
                <NewAnnotation {...{state, dispatch}} />
            </Bannered>
            <Bannered name="Schema Liste"
                onlyTopPadding={true}
                scroll={true}
                additionalBem={[["default-layout", "column-element"]]}
            >
                <Schemas state={state} dispatch={dispatch} />
            </Bannered>
        </div>
        <div {...BEM(['default-layout', 'right', {'no-scroll': true, '2-children': true}])}>
            <Bannered name="Annotationen"
                scroll={true}
                additionalBem={[["default-layout", "column-element"]]}
            >
                <Annotations onAnnotationFocusRequested={makeScrollToAnnotation(canvasWrapper.current!, state.letters, dispatch)} {...{state, dispatch}} />
            </Bannered>
            <Bannered name="Überlappungsanalyse"
                scroll={true}
                additionalBem={[["default-layout", "column-element"]]}
            >
                <OverlapAnalysis dispatch={dispatch} state={state} />
            </Bannered>
        </div>
        <div {...BEM(['default-layout', 'middle', {'no-scroll': true}])}
        >
            <Bannered name="Dokument"
                scroll={true}
                bodyRef={canvasWrapper}
            >
                <Tabbed tabs={[
                    {
                        name: "Rendered",
                        child:
                            <div
                                onMouseDown={(ev) => dispatch({...mouseEvent(ev), kind: "MouseDownEvent"})}
                                onMouseUp={(ev) => dispatch({...mouseEvent(ev), kind: "MouseUpEvent", openContextMenu: ev.button === 2})}
                                onMouseMove={(ev) => dispatch({...mouseEvent(ev), kind: "MouseMoveEvent"})}
                                className="canvas-wrapper"
                                onContextMenu={(ev) => {ev.preventDefault(); return false;}}
                            >
                                <TextCanvas config={defaultDocumentConfig} letters={state.letters} />
                                <AnnotationCanvas annotations={State.getAnnotationsOfCurrentSchema(state)} highlight={state.selectedAnnotation?.annotation} config={defaultDocumentConfig} letters={state.letters} />
                                <AnnotationCanvas annotations={getNewAnnotation(state)} config={defaultDocumentConfig} letters={state.letters} />
                                <AnnotationHoverCanvas hover={state.hoveredAnnotation} annotations={State.getAnnotationsOfCurrentSchema(state)} config={defaultDocumentConfig} letters={state.letters} />
                            </div>
                    },
                    {
                        name: "Html",
                        child: <code>{state.document.html}</code>
                    },
                    {
                        name: "Text",
                        child: <code>{state.document.text}</code>
                    },
                ]
                } />
            </Bannered>
        </div>
    </div >;
}

const makeScrollToAnnotation = (d: HTMLDivElement, ls: Letter[], dispatch: (s: State.StateEvent) => void) => (a: UnassociatedAnnotation): void => {
    const firstLetter = ls[a.span.startInc];
    const scrollPosition = firstLetter.boundingBox.UPPER;
    dispatch({
        kind: "SelectAnnotationEvent", selection: {
            annotation: a.id,
            showComment: false
        }
    });
    d.scrollTo({top: scrollPosition, behavior: "smooth"});
}

const mouseEvent = (e: React.MouseEvent) => {
    const rect = e.currentTarget.getBoundingClientRect();
    return {
        x: e.clientX - rect.left - 5,
        y: e.clientY - rect.top - 5,
        clientX: e.clientX,
        clientY: e.clientY
    };
}

const getNewAnnotation = (s: State.State): UnassociatedAnnotation[] =>
    s.textSelection ? [{id: s.textSelection.newAnnotationUuid, span: {startInc: Math.min(s.textSelection.start, s.textSelection.other), endEx: Math.max(s.textSelection.start, s.textSelection.other)}}] :
        [];

const buildState = (doc: T.ExpandedDocument): State.State => {
    const letters = layoutTextWithTempCanvas({kind: "Text", text: doc.text}, defaultDocumentConfig);
    const tree = new KdTree(letters);
    const schemas = Object.keys(doc.expandedSchemas);
    const schemaSelection: State.SchemaSelection | undefined = schemas.length > 0 ? {id: schemas.sort()[0]} : undefined;

    return {
        letters,
        tree,
        document: doc,
        schemaSelection,
        showAutomaticAnnotations: false
    };
}
new KdTree<Letter>([]);
export interface UrlData {
    documentId: string;
}
