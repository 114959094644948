import {useEffect, useReducer} from "react";
import "./gold-mapping.scss";
import * as T from "../../backend/types";
import {
  flattenNodes,
} from "./tree";
import "./Schema.scss";

export type Names = {[nodeId: string]: string};
export type SchemaNames = {[schemaId: string]: string};

export type Descriptions = {
  names: Names;
  schemaNames: SchemaNames;
}

type State = {
  names: Names;
  schemaNames: SchemaNames;
  knownSchemas: {[schemaId: string]: T.Schema};
}

const update = (s: State, newInput: {schemas: T.Schema[], currentSchema?: T.Schema}): State => {
  const {schemas, currentSchema} = newInput;

  const all = currentSchema ? schemas.concat([currentSchema]) : schemas;
  const toUpdate = all.filter(schema => s.knownSchemas[schema.id] !== schema);
  const newState = toUpdate.reduce((state, schema) => {
    if (state.knownSchemas[schema.id] === schema) {
      return state;
    } else {
      const nodes = flattenNodes(schema.root);
      const newNames = nodes.reduce((desc, node) => ({...desc, [node.id]: schema.name + "/" + node.name}), state.names);
      return {
        names: newNames,
        schemaNames: {...state.schemaNames, [schema.id]: schema.name},
        knownSchemas: {...state.knownSchemas, [schema.id]: schema},
      };
    }
  }, s);

  return newState;
}

export const useDescriptions = (
  schemas: T.Schema[],
  currentSchema?: T.Schema,
): Descriptions => {

  const [state, updateState] = useReducer(update, {
    names: {},
    schemaNames: {},
    knownSchemas: {}
  });

  useEffect(() => {
    updateState({schemas, currentSchema});
  }, [schemas, currentSchema]);

  return state;
}
