import './StoredSearchTest.scss';
import React, {ReactElement} from 'react';
import {BEM, assertNever} from '../../util/util';
import * as S from './state';
import {TextCanvas} from '../document/TextCanvas';
import {defaultDocumentConfig} from '../document/document-config';
import {layoutTextWithTempCanvas} from '../document/letter';
import {AnnotationCanvas} from '../document/AnnotationCanvas';
import * as api from '../../backend/Api';
import {setFixedError} from '../../common-components/fixed-error/FixedError';
import v4 from 'uuid';

export const StoredSearchTest = (props: Props): ReactElement => {
    const selected = props.state.selected === undefined ? undefined : props.state.searches[props.state.selected];

    if (!selected) {
        return <div>
            Bitte wählen Sie zunächst eine gespeicherte Suche.
        </div>;
    }

    const test = () => {
        api.testSearch({
            search: selected.search,
            matchee: {
                text: selected.testText,
                expandedSchemas: {},
                bought: true
            }
        }).then(response => {
            switch (response.kind) {
                case "AlwaysError": setFixedError(response); break;
                case "GotData":
                    const letters = layoutTextWithTempCanvas({kind: "Text", text: selected.testText}, defaultDocumentConfig);
                    const uanns = response.data.map(span => ({id: v4(), span}));
                    props.setState({
                        ...props.state, testResult: {
                            text: selected.testText, letters, spans: uanns.map(exhibit => ({
                                id: exhibit.id, span: exhibit.span.span
                            }))
                        }
                    })
                    break;
                default: assertNever(response);
            }
        })
    }

    const spans = !props.state.testResult ? null : <div {...BEM(['stored-search-test', 'test-output'])}>
        <AnnotationCanvas annotations={props.state.testResult.spans} config={defaultDocumentConfig} letters={props.state.testResult.letters} />
        <TextCanvas config={defaultDocumentConfig} letters={props.state.testResult.letters} />
    </div>

    return <div {...BEM('stored-search-test')}>
        <button {...BEM(['stored-search-test', 'test-button'])} onClick={test}>Testen</button>
        {spans}
    </div>;


}

interface Props {
    state: S.State;
    setState: (s: (S.State | ((s: S.State) => S.State))) => void;
}

