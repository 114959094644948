import React, {ReactElement, useState, useEffect, useReducer} from "react";
import {useUrlData} from "../../hooks/useUrlData";
import {v4} from "uuid";
import * as Icon from "../../common-components/icon/Icon";
import * as T from "../../backend/types";
import * as api from "../../backend/Api";
import {
  newNode,
  deepClone,
  findNode,
} from "./tree";
import "./Schema.scss";
import {assertNever, classNames, BEM} from "../../util/util";
import {askKeyedList, toast} from "../../common-components/toast/Toast";
import {Bannered} from "../../common-components/bannered/Bannered";
import {SchemaList} from "./SchemaList";
import {
  setFixedError,
  setFixedErrorMessage,
} from "../../common-components/fixed-error/FixedError";
import {Loader} from "../../common-components/loader/Loader";
import {useSchemas} from "../../hooks/useSchema";
import {ScorerList} from "../../common-components/scorer-list/ScorerList";
import {SchemaScorer} from "./SchemaScorer";
import {Matcher, selectSchemaNode} from "../../common-components/matcher/Matcher";
import {Ripple} from "../ripple/Ripple"
import {handleEvent, SchemaEvent, TreeType} from "./state";
import {GoldStandardMapping} from "./GoldStandardMapping";
import {Tabbed} from "../../common-components/Tabbed";
import {Descriptions, useDescriptions} from "./descriptions";

export const Schema = (): ReactElement => {
  const [schemaId, setSchemaId] = useUrlData<UrlData>()("schemaId");
  const newSchema = (): T.Schema => ({
    id: v4(),
    name: "",
    root: newNode(),
    outcome: {
      goldStandardMapping: [],
    }
  });
  const [state, handleStateEvent] = useReducer(handleEvent,
    schemaId === null
      ? {
        kind: "Loaded",
        isNew: true,
        focus: null,
        schema: newSchema(),
        rippleTreeOpen: false
      }
      : {kind: "Loading"}
  );

  useEffect(() => {
    if (schemaId === null) {
      if (state.kind !== "Loading" && !state.isNew) {
        handleStateEvent({
          kind: "SetSchemaState",
          newState: {
            kind: "Loaded",
            schema: newSchema(),
            focus: null,
            isNew: false,
            rippleTreeOpen: false
          }
        });
      }
      return;
    }

    handleStateEvent({kind: "SetSchemaState", newState: {kind: "Loading"}});

    api.getSchema(schemaId).then((response) => {
      switch (response.kind) {
        case "AlwaysError":
          setFixedError(response);
          break;
        case "NotFound":
          setFixedErrorMessage("Das Schema konnte nicht gefunden werden");
          break;
        case "GotData":
          handleStateEvent({
            kind: "SetSchemaState",
            newState: {
              kind: "Loaded",
              schema: response.data,
              focus: null,
              isNew: false,
              rippleTreeOpen: false
            }
          });
          break;
        default:
          assertNever(response);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemaId]);
  const schemas = useSchemas() || [];
  const descriptions = useDescriptions(schemas, undefined);

  const onSave = () => {
    if (state.kind === "Loaded") {
      const schema = state.schema;
      api.saveSchema(schema).then((response) => {
        switch (response.kind) {
          case "AlwaysError":
            setFixedError(response);
            break;
          case "Ok":
            setSchemaId(schema.id);
            toast("Erfolgreich gespeichert");
            break;
          default:
            assertNever(response);
        }
      });
    }
  };

  const onDelete = () => {
    if (state.kind === "Loaded") {
      api.deleteSchema(state.schema.id).then((response) => {
        switch (response.kind) {
          case "AlwaysError":
            setFixedError(response);
            break;
          case "Ok":
            setSchemaId(null);
            toast("Erfolgreich gelöscht");
            break;
          default:
            assertNever(response);
        }
      });
    }
  };

  const onDuplicate = () => {
    if (state.kind === "Loaded") {
      setSchemaId(null);
      handleStateEvent({
        kind: "SetSchemaState",
        newState: {
          kind: "Loaded",
          schema: {
            id: v4(),
            name: state.schema.name + " Kopie",
            root: deepClone(state.schema.root),
            filter: state.schema.filter,
            outcome: {
              goldStandardMapping: state.schema.outcome.goldStandardMapping,
              rules: state.schema.outcome.rules,
              nodes: state.schema.outcome.nodes ? deepClone(state.schema.outcome.nodes) : undefined
            }
          },
          focus: null,
          isNew: true,
          rippleTreeOpen: false
        }
      });
    }
  };

  const onPopulateTestGroups = () => {
    if (state.kind === "Loaded") {
      api.populateTestGroups(state.schema.id).then((response) => {
        switch (response.kind) {
          case "AlwaysError":
            setFixedError(response);
            break;
          case "NotFound":
            toast("Not found");
            break;
          case "InvalidInput":
            toast("invalid input");
            break;
          case "Ok":
            setSchemaId(null);
            toast("Erfolgreich mit Testgruppen befüllt");
            break;
          default:
            assertNever(response);
        }
      });
    }
  }

  const getFocus = (): [T.SchemaNode, TreeType] | [null, null] => {
    if (state.kind === "Loading") {
      return [null, null];
    }

    const rootNode = findNode(state.schema.root, state.focus || "");
    if (rootNode) {
      return [rootNode, "Root"];
    }

    if (state.schema.outcome.nodes) {
      const outcomeNode = findNode(state.schema.outcome.nodes, state.focus || "");
      if (outcomeNode) {
        return [outcomeNode, "Outcome"];
      }
    }

    return [null, null];
  }

  const [focused, focusedTree] = getFocus();

  return (
    <div {...BEM("default-layout")}>
      <div {...BEM(["default-layout", "header"])}>
        {state.kind === "Loading" ? (
          <Loader input="loading" />
        ) : (
            <Controls
              onSave={onSave}
              onDelete={onDelete}
              onDuplicate={onDuplicate}
              isNew={schemaId === null}
              schema={state.schema}
              onPopulateTestGroups={onPopulateTestGroups}
            />
          )}
      </div>
      <div {...BEM(["default-layout", "left"])}>
        <Bannered name="Schema Liste">
          <SchemaList
            onSchemaLoaded={(s) => setSchemaId(s.id)}
            selected={schemaId || undefined}
          />
        </Bannered>
      </div>
      <div {...BEM(["default-layout", "middle"])}>
        <Bannered name="Schema">
          {state.kind === "Loading" ? (
            <Loader input="loading" />
          ) : (

              <SchemaTree
                schema={state.schema}
                handleEvent={handleStateEvent}
                focus={state.focus}
                schemas={schemas}
                descriptions={descriptions}
                rippleTreeOpen={state.rippleTreeOpen}
              />
            )}
        </Bannered>
      </div>
      <div {...BEM(["default-layout", "right"])}>
        <Bannered name="Knoten">
          <Spotlight
            handler={handleStateEvent}
            node={focused}
            schemas={schemas}
            tree={focusedTree}
            descriptions={descriptions}
          />
        </Bannered>
      </div>
    </div>
  );
};

export const SchemaTree = (props: {
  schema: T.Schema;
  handleEvent: (te: SchemaEvent) => void;
  focus: string | null;
  schemas: T.Schema[];
  descriptions: Descriptions;
  rippleTreeOpen: boolean;
}): ReactElement => {
  const {schema, handleEvent, focus} = props;
  const [dragging, setDragging] = useState<string | null>(null);

  (window as any).setOutcome = (a: any) => props.handleEvent({kind: "SetOutcome", outcome: a});
  console.log(schema.outcome);

  useEffect(() => {
    const onDragEndHandler = () => {
      setDragging(null);
    };

    window.addEventListener("dragend", onDragEndHandler);
    return () => {
      window.removeEventListener("dragend", onDragEndHandler);
    };
  }, []);

  const handleDragEvent = (e: DragEvent): void => {
    switch (e.kind) {
      case "StartDrag":
        setDragging(e.target);
        break;
      case "StopDrag":
        if (e.target && dragging) {
          props.handleEvent({
            kind: "TreeNodeMoveNode",
            tree: e.tree,
            target: e.target,
            asChild: e.asChild,
            dragee: dragging,
          });
        }
        setDragging(null);
        break;
      default:
        assertNever(e);
    }
  };

  const tab1 = <div className="schema-main">
    <label> Schema Name </label>
    <input
      {...BEM("default-input")}
      onChange={(e) =>
        props.handleEvent({kind: "Rename", newName: e.target.value})
      }
      value={schema.name}
    />

    <label>Filter</label>

    <Matcher
      matcher={schema.filter}
      setter={filter => props.handleEvent({kind: "SetFilter", filter: filter || undefined})}
      context={({schemas: props.schemas})}
      isRoot={true}
    />

    <label>Hauptknoten</label>
    <div className={classNames({root: true, dragging: !!dragging})}>
      <Node
        tree={"Root"}
        focused={focus}
        isRoot={true}
        dragHandler={handleDragEvent}
        key={schema.root.id}
        node={schema.root}
        setter={handleEvent}
      />
    </div>
  </div>;

  const tab2 = <div className="schema-main">
    <label>Factcheck Majority Voting Mode</label>
    <select value={props.schema.factcheckMajorityVotingMode} onChange={ev => props.handleEvent({
      kind: "SetFactcheckMajorityVotingMode",
      mode: ev.target.value.trim() ? (ev.target.value.trim() as T.MajorityVotingMode) : undefined
    })}>
      <option>EliminateLessOrEqual</option>
      <option>EliminateLess</option>
      <option>EliminateNone</option>
    </select>

    <label>Goldstandard Mapping</label>
    <GoldStandardMapping mappings={props.schema.outcome.goldStandardMapping} descriptions={props.descriptions} setter={props.handleEvent} />


    <label>Ausgangsknoten</label>
    <div className={classNames({"outcome-nodes": true, root: true, dragging: !!dragging})}>
      {
        props.schema.outcome.nodes
          ?
          <Node
            tree={"Outcome"}
            focused={focus}
            isRoot={true}
            dragHandler={handleDragEvent}
            key={props.schema.outcome.nodes.id}
            node={props.schema.outcome.nodes}
            setter={handleEvent}
          />
          : <button onClick={() => props.handleEvent({kind: "SetOutcomeNodes", node: newNode()})}>Erstellen</button>
      }
    </div>

    <label>Ausgangslogik</label>
    {
      props.rippleTreeOpen
        ? <Ripple isOpen={props.rippleTreeOpen} schema={schema} setter={props.handleEvent} />
        : <button onClick={() => props.handleEvent({kind: "SetOutcomeLogicOpen", isOpen: true})}>öffnen</button>

    }

  </div>;

  return <Tabbed tabs={[{name: "Standard", child: tab1}, {name: "Ausgang", child: tab2}]} />;
};

const Node = (props: NodeProps): ReactElement => {
  return (
    <div className="node">
      <div
        draggable={true}
        onDragStart={(e) => {
          e.dataTransfer.setData("text/plain", "");
          props.dragHandler({kind: "StartDrag", tree: props.tree, target: props.node.id});
        }}
        className={classNames({dragger: true, negated: props.node.negated})}
      >
        {props.node.or ? "||" : "&"}
      </div>
      <div className="main">
        <input
          // eslint-disable-next-line no-sparse-arrays
          {...BEM(["default-input", , {focused: props.focused === props.node.id}])}
          onFocus={() => props.setter({kind: "TreeNodeFocus", tree: props.tree, target: props.node.id})}
          autoFocus={true}
          value={props.node.name}
          onChange={(e) =>
            props.setter({
              kind: "TreeNodeRename",
              tree: props.tree,
              target: props.node.id,
              newName: e.target.value,
            })
          }
        />
      </div>
      <div
        onClick={() =>
          props.setter({kind: "TreeNodeAddSibling", tree: props.tree, target: props.node.id})
        }
        className={classNames({
          "add-sibling": true,
          adder: true,
          disabled: props.isRoot,
        })}
        onDragOver={(e) => {
          e.preventDefault();
          e.dataTransfer.dropEffect = "move";
        }}
        onDrop={(e) => {
          e.preventDefault();
          props.dragHandler({
            kind: "StopDrag",
            tree: props.tree,
            target: props.node.id,
            asChild: false,
          });
        }}
      ></div>
      <div
        onClick={() =>
          props.setter({kind: "TreeNodeAddChild", tree: props.tree, target: props.node.id})
        }
        className="add-child adder"
        onDragOver={(e) => {
          e.preventDefault();
          e.dataTransfer.dropEffect = "move";
        }}
        onDrop={(e) => {
          e.preventDefault();
          props.dragHandler({
            tree: props.tree,
            kind: "StopDrag",
            target: props.node.id,
            asChild: true,
          });
        }}
      ></div>
      <div className="subs">
        {props.node.subs.map((sub) => (
          <Node
            tree={props.tree}
            focused={props.focused}
            dragHandler={props.dragHandler}
            isRoot={false}
            key={sub.id}
            node={sub}
            setter={props.setter}
          />
        ))}
      </div>
    </div>
  );
};

const Spotlight = (props: SpotlightProps): ReactElement => {
  const noNodeSelected = (
    <div>
      Es wurde kein Knoten ausgewählt. Bitte wählen Sie einen Knoten aus.
    </div>
  );

  const withNode = (node: T.SchemaNode, tree: TreeType): ReactElement => {
    const tab1 =
      <div className="inner">
        <div className="label">Name</div>
        <div className="data">{node.name}</div>

        <div className="label">Negiert</div>
        <div className="data">
          <input
            onClick={() =>
              props.handler({kind: "TreeNodeToggleNegated", tree, target: node.id})
            }
            type="checkbox"
            checked={node.negated}
          />
        </div>

        <div className="label">Oder-Knoten</div>
        <div className="data">
          <input
            onClick={() =>
              props.handler({kind: "TreeNodeToggleAndOr", tree, target: node.id})
            }
            type="checkbox"
            checked={node.or}
          />
        </div>

        <div className="label">Löschen</div>
        <div className="data">
          <button
            onClick={() =>
              props.handler({kind: "TreeNodeDeleteNode", tree, target: node.id})
            }
          >
            Knoten löschen
          </button>
        </div>

        <div className="label">Scorer</div>
        <div className="data">
          <ScorerList
            scorers={node.search}
            setter={newScorers => props.handler({kind: "TreeNodeSetSearch", tree, target: node.id, search: newScorers})}
            context={({schemas: props.schemas})}
            createNew={matcher => {
              const scorer: T.SchemaScorer = {matcher, score: 100, state: "Yes"}
              return scorer;
            }}
            RenderSub={SchemaScorer}
          />
        </div>
      </div>

    const tab2 = <div className="inner">
      <div className="label">Erklärungstext</div>
      <div className="data">
        <textarea value={node.endUserExplanation} onChange={e => {props.handler({kind: "TreeNodeSetEndUserExaplanation", tree, target: node.id, text: e.target.value})}}></textarea>
      </div>
      <div className="label">Ja-Text</div>
      <div className="data">
        <textarea value={node.yesText} onChange={e => {props.handler({kind: "TreeNodeSetYesText", tree, target: node.id, text: e.target.value})}}></textarea>
      </div>
      <div className="label">No-Text</div>
      <div className="data">
        <textarea value={node.noText} onChange={e => {props.handler({kind: "TreeNodeSetNoText", tree, target: node.id, text: e.target.value})}}></textarea>
      </div>
      <div className="label">Diesen Knoten dem Endnutzer anzeigen</div>
      <div className="data">
        <input
          onClick={() =>
            props.handler({kind: "TreeNodeToggleAskEndUser", tree, target: node.id})
          }
          type="checkbox"
          checked={node.askEndUser}
        />
      </div>
      <div className="label">Default Value</div>
      <div className="data">
        <select value={"" + node.defaultValue} onChange={e => {props.handler({kind: "TreeNodeSetEndUserDefaultValue", tree, target: node.id, value: eval(e.target.value)})}} >
          <option value="undefined">Kein Default</option>
          <option value="true">Ja</option>
          <option value="false">No</option>
        </select>
      </div>
    </div>;

    const onSetMapping = async () => {
      const [, nodeId] = await selectSchemaNode();
      const polarity = await askKeyedList<boolean>("Soll das Mapping negiert werden?", [
        {message: "Ja", payload: false},
        {message: "No", payload: true},

      ]);

      props.handler({kind: "TreeNodeSetGoldstandardMapping", tree, target: node.id, mapping: {node: nodeId, polarity: polarity}});

    };

    const onDeleteMapping = () => {
      props.handler({kind: "TreeNodeSetGoldstandardMapping", tree, target: node.id});

    }

    const tab3 = <div className="inner">
      <div className="label">Ausgangsknoten</div>
      <div className="data">
        <div>{
          node.goldstandardMapping ? props.descriptions.names[node.goldstandardMapping.node] || "-" : ""
        }</div>
        <button onClick={onSetMapping}>Setzen</button>
        <button onClick={onDeleteMapping}>Löschen</button>
      </div>
      <div className="label">Mapping negieren</div>
      <div className="data">
        <input
          onClick={() => {
            if (node?.goldstandardMapping) {
              props.handler({kind: "TreeNodeSetGoldstandardMapping", tree, target: node.id, mapping: {...node.goldstandardMapping, polarity: !node.goldstandardMapping.polarity}});
            }
          }}
          type="checkbox"
          checked={!node.goldstandardMapping?.polarity || false}
        />
      </div>
    </div>;


    return <Tabbed tabs={[
      {name: "Standard", child: tab1},
      {name: "End Nutzer", child: tab2},
      {name: "Ausgang", child: tab3}
    ]} />
  };

  return (
    <div className="spotlight">
      {props.node === null || props.tree === null ? noNodeSelected : withNode(props.node, props.tree)}
    </div>
  );
};

const Controls = (props: {
  isNew: boolean;
  schema: T.Schema;
  onSave: () => void;
  onDelete: () => void;
  onDuplicate: () => void;
  onPopulateTestGroups: () => void;
}): ReactElement => {
  const save = <Icon.Save title={"Speichern"} onClick={props.onSave} />;
  const deleteB = (
    <Icon.Delete
      title={"Löschen"}
      disabled={props.isNew}
      onClick={props.onDelete}
    />
  );
  const duplicate = (
    <Icon.Duplicate
      title={"Duplizieren"}
      disabled={props.isNew}
      onClick={props.onDuplicate}
    />
  );

  const populateTestGroups = (
    <Icon.PopulateTestGroups
      title={"Populate Test Groups"}
      disabled={props.isNew}
      onClick={props.onPopulateTestGroups}
    />
  );

  return <div className="controls">{[save, deleteB, duplicate, populateTestGroups]}</div>;
};

interface NodeProps {
  tree: TreeType;
  node: T.SchemaNode;
  isRoot: boolean;
  setter: (te: SchemaEvent) => void;
  dragHandler: (e: DragEvent) => void;
  focused: string | null;
}

interface SpotlightProps {
  node: T.SchemaNode | null;
  tree: TreeType | null;
  handler: (te: SchemaEvent) => void;
  schemas: T.Schema[];
  descriptions: Descriptions;
}

export interface UrlData {
  schemaId: string;
}

export type DragEvent =
  | {
    kind: "StartDrag";
    tree: TreeType;
    target: string;
  }
  | {
    kind: "StopDrag";
    tree: TreeType;
    target?: string;
    asChild: boolean;
  };
