import * as T from "../../backend/types";
import {
  newNode,
  deleteNode,
  modify,
  moveTo,
  getPathOfNode,
  addSibling,
} from "./tree";
import "./Schema.scss";
import {assertNever} from "../../util/util";
import {toast} from "../../common-components/toast/Toast";

export type SchemaState = LoadingState | LoadedState;

type LoadingState = {kind: "Loading";};
type LoadedState = {
  kind: "Loaded";
  isNew: boolean;
  schema: T.Schema;
  focus: string | null;
  rippleTreeOpen: boolean;
};

export type SchemaEvent =
  SetSchemaStateEvent |
  SetOutcomeNodesEvent |
  SetOutcomeEvent |
  SetGoldStandardMappings |
  RenameEvent |
  SetFilterEvent |
  SetFactcheckMajorityVotingModeEvent |
  SetRippleOutcomeNodeEvent |
  SetOutcomeLogicOpenEvent |
  TreeNodeRenameEvent |
  TreeNodeAddSiblingEvent |
  TreeNodeAddChildEvent |
  TreeNodeToggleNegatedEvent |
  TreeNodeToggleAndOrEvent |
  TreeNodeSetSearchEvent |
  TreeNodeMoveNodeEvent |
  TreeNodeDeleteNodeEvent |
  TreeNodeFocusEvent |
  TreeNodeSetYesText |
  TreeNodeSetNoText |
  TreeNodeSetEndUserExaplanation |
  TreeNodeToggleAskEndUser |
  TreeNodeSetGoldstandardMapping |
  TreeNodeSetEndUserDefaultValue
  ;

export type TreeType = "Root" | "Outcome";

export type SetSchemaStateEvent = {
  kind: "SetSchemaState";
  newState: SchemaState;
}

type TreeNodeRenameEvent = {
  kind: "TreeNodeRename";
  tree: TreeType;
  target: string;
  newName: string;
};

type TreeNodeAddSiblingEvent = {
  kind: "TreeNodeAddSibling";
  tree: TreeType;
  target: string;
};

type TreeNodeAddChildEvent = {
  kind: "TreeNodeAddChild";
  tree: TreeType;
  target: string;
};

type TreeNodeFocusEvent = {
  kind: "TreeNodeFocus";
  tree: TreeType;
  target: string;
};

type TreeNodeToggleNegatedEvent = {
  kind: "TreeNodeToggleNegated";
  tree: TreeType;
  target: string;
};

type TreeNodeToggleAndOrEvent = {
  kind: "TreeNodeToggleAndOr";
  tree: TreeType;
  target: string;
};

type TreeNodeSetSearchEvent = {
  kind: "TreeNodeSetSearch";
  tree: TreeType;
  target: string;
  search: T.SchemaScorer[];
};

type RenameEvent = {
  kind: "Rename";
  newName: string;
};

type TreeNodeMoveNodeEvent = {
  kind: "TreeNodeMoveNode";
  tree: TreeType;
  target: string;
  dragee: string;
  asChild: boolean;
};

type SetFilterEvent = {
  kind: "SetFilter";
  filter?: T.PreMatcher;
};

type SetFactcheckMajorityVotingModeEvent = {
  kind: "SetFactcheckMajorityVotingMode",
  mode?: T.MajorityVotingMode
}

type TreeNodeDeleteNodeEvent = {
  kind: "TreeNodeDeleteNode";
  tree: TreeType;
  target: string;
};

type SetRippleOutcomeNodeEvent = {
  kind: "SetRippleOutcomeNode";
  node?: T.RippleNode;
};

type SetOutcomeLogicOpenEvent = {
  kind: "SetOutcomeLogicOpen";
  isOpen: boolean;
};

type SetOutcomeNodesEvent = {
  kind: "SetOutcomeNodes";
  node?: T.SchemaNode;
};

type SetOutcomeEvent = {
  kind: "SetOutcome";
  outcome: T.SchemaOutcomePersistence;
};

type SetGoldStandardMappings = {
  kind: "SetGoldStandardMappings";
  mappings: T.GoldStandardMapping[];
};

type TreeNodeSetGoldstandardMapping = {
  kind: "TreeNodeSetGoldstandardMapping";
  tree: TreeType;
  target: string;
  mapping?: T.NodeMapping
};

type TreeNodeSetYesText = {
  kind: "TreeNodeSetYesText";
  tree: TreeType;
  target: string;
  text: string;
};

type TreeNodeSetNoText = {
  kind: "TreeNodeSetNoText";
  tree: TreeType;
  target: string;
  text: string;
};

type TreeNodeSetEndUserExaplanation = {
  kind: "TreeNodeSetEndUserExaplanation";
  tree: TreeType;
  target: string;
  text: string;
};

type TreeNodeToggleAskEndUser = {
  kind: "TreeNodeToggleAskEndUser";
  tree: TreeType;
  target: string;
};


type TreeNodeSetEndUserDefaultValue = {
  kind: "TreeNodeSetEndUserDefaultValue";
  tree: TreeType;
  target: string;
  value: boolean | undefined;
};

export const handleEvent = (state: SchemaState, e: SchemaEvent): SchemaState => {
  if (e.kind === "SetSchemaState") {
    return e.newState;
  }

  if (state.kind === "Loading") {
    return state;
  }

  const schema = state.schema;

  if (e.kind === "Rename") {
    return {...state, schema: {...schema, name: e.newName}};
  }

  if (e.kind === "SetFilter") {
    return {...state, schema: {...schema, filter: e.filter}};
  }

  if (e.kind === "SetFactcheckMajorityVotingMode") {
    return {...state, schema: {...schema, factcheckMajorityVotingMode: e.mode}};
  }

  if (e.kind === "SetOutcomeNodes") {
    return {
      ...state,
      schema: {
        ...state.schema,
        outcome: {
          ...state.schema.outcome,
          nodes: e.node
        }
      }
    };
  }

  if (e.kind === "SetOutcome") {
    return {
      ...state,
      schema: {
        ...state.schema,
        outcome: e.outcome
      }
    };
  }

  if (e.kind === "SetRippleOutcomeNode") {
    return {
      ...state,
      schema: {
        ...state.schema,
        outcome: {
          ...state.schema.outcome,
          rules: e.node
        }
      }
    };
  }

  if (e.kind === "SetGoldStandardMappings") {
    return {
      ...state,
      schema: {
        ...state.schema,
        outcome: {
          ...state.schema.outcome,
          goldStandardMapping: e.mappings
        }
      }
    };
  }

  if (e.kind === "SetOutcomeLogicOpen") {
    return {...state, rippleTreeOpen: e.isOpen};
  }

  const root = e.tree === "Root" ? schema.root :
    e.tree === "Outcome" ? schema.outcome.nodes :
      assertNever(e.tree);

  if (!root) {
    toast("root not found");
    return state;
  }

  const path = getPathOfNode(root, e.target);

  if (path === null) {
    toast("stale node id", true);
    return state;
  }

  const toastIfNull = (n: T.SchemaNode | null): SchemaState => {
    if (e.tree === "Root") {
      if (n === null) {
        toast("tried to set root to null", true);
        return state;
      } else {
        return {...state, schema: {...schema, root: n}};
      }
    } else if (e.tree === "Outcome") {
      return {
        ...state,
        schema: {
          ...state.schema,
          outcome: {
            ...state.schema.outcome,
            nodes: n || undefined
          }
        }
      };
    } else {
      return assertNever(e.tree);
    }
  };

  switch (e.kind) {
    case "TreeNodeRename":
      return toastIfNull(
        modify(root, path, (n) => ({...n, name: e.newName}))
      );
    case "TreeNodeAddSibling":
      return toastIfNull(addSibling(root, e.target));
    case "TreeNodeAddChild":
      return toastIfNull(
        modify(root, path, (n) => ({
          ...n,
          subs: n.subs.concat([newNode()]),
        }))
      );
    case "TreeNodeFocus":
      return {...state, focus: e.target};
    case "TreeNodeToggleNegated":
      return toastIfNull(
        modify(root, path, (n) => ({...n, negated: !n.negated}))
      );
    case "TreeNodeToggleAndOr":
      return toastIfNull(modify(root, path, (n) => ({...n, or: !n.or})));
    case "TreeNodeSetSearch":
      return toastIfNull(
        modify(root, path, (n) => ({...n, search: e.search}))
      );
    case "TreeNodeSetGoldstandardMapping":
      return toastIfNull(
        modify(root, path, (n) => ({...n, goldstandardMapping: e.mapping}))
      );
    case "TreeNodeSetYesText":
      return toastIfNull(
        modify(root, path, (n) => ({...n, yesText: e.text}))
      );
    case "TreeNodeSetEndUserExaplanation":
      return toastIfNull(
        modify(root, path, (n) => ({...n, endUserExplanation: e.text}))
      );
    case "TreeNodeToggleAskEndUser":
      return toastIfNull(
        modify(root, path, (n) => ({...n, askEndUser: !n.askEndUser}))
      );
    case "TreeNodeSetNoText":
      return toastIfNull(
        modify(root, path, (n) => ({...n, noText: e.text}))
      );
    case "TreeNodeMoveNode":
      const newRoot = moveTo(root, e.target, e.dragee, e.asChild);
      if (newRoot === null) {
        toast("can't move node into itself", true);
        return state;
      } else {
        return {...state, schema: {...schema, root: newRoot}};
      }
    case "TreeNodeDeleteNode":
      return toastIfNull(deleteNode(root, e.target));
    case "TreeNodeSetEndUserDefaultValue":
      return toastIfNull(
        modify(root, path, (n) => ({...n, defaultValue: e.value}))
      );
    default:

      return assertNever(e);
  }
}
