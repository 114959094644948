import {Letter} from "./letter";
import {KdTree} from "../../util/kdtree";
import {assertThere, guaranteedProperty, arrayLast, assertNever} from "../../util/util";
import * as T from "../../backend/types";
import * as L from 'monocle-ts/lib/Lens';
import * as O from 'monocle-ts/lib/Optional';
import {pipe} from 'fp-ts/lib/function';
import uuid from "uuid";
import {Predicate} from "fp-ts/es6/function";
import {flattenNodes} from "../schema/tree";

export type State = {
  letters: Letter[];
  tree: KdTree<Letter>;
  document: T.ExpandedDocument;
  schemaSelection?: SchemaSelection;
  hoveredAnnotation?: string;
  textSelection?: TextSelection;
  mouseDownState?: MouseDown;
  selectedAnnotation?: AnnotationSelection;

  showAutomaticAnnotations: boolean;
}

export interface SchemaSelection {
  id: string;
  compareWith?: string;
}

export interface TextSelection {
  start: number;
  other: number;
  newAnnotationUuid: string;
}

export interface MouseDown {
  kind: "undecided" | "move";
  startX: number;
  startY: number;
}

export interface AnnotationSelection {
  annotation: string;
  contextMenuAt?: {
    x: number;
    y: number;
  }
  showComment: boolean;
}

export type StateEvent =
  | CreateAnnotationEvent
  | MouseDownEvent
  | MouseMoveEvent
  | MouseUpEvent
  | SetSchemaSelectionEvent
  | CloseAnnotationContextMenuEvent
  | SetStateEvent
  | DeleteSelectedAnnotationEvent
  | DeleteAllAnnotationsEvent
  | ChangeSelectedAnnotationToTextSelectionEvent
  | SelectAnnotationEvent
  | SetAnnotationStateEvent
  | SetAnnotationCommentVisibilityEvent
  | SetAnnotationCommentEvent
  | CreateAndAddToNewGroupEvent
  | SetAnnotationGroupEvent
  | SetShowAutomaticAnnotationsEvent
  ;

interface MouseEvent {
  x: number;
  y: number;
  clientX: number;
  clientY: number;
}

export interface MouseDownEvent extends MouseEvent {
  kind: "MouseDownEvent";
}

export interface MouseUpEvent extends MouseEvent {
  kind: "MouseUpEvent";
  openContextMenu: boolean;
}

export interface MouseMoveEvent extends MouseEvent {
  kind: "MouseMoveEvent";
}

export interface CreateAnnotationEvent {
  kind: "CreateAnnotationEvent";
  id: string;
  documentId: string;
  schemaNode: string;
  state: T.AnnotationState;
}

export interface SetStateEvent {
  kind: "SetStateEvent";
  state: State;
}

export interface SetSchemaSelectionEvent {
  kind: "SetSchemaSelectionEvent";
  selection?: SchemaSelection;
}

export interface CloseAnnotationContextMenuEvent {
  kind: "CloseAnnotationContextMenuEvent";
}

export interface DeleteSelectedAnnotationEvent {
  kind: "DeleteSelectedAnnotationEvent";
}

export interface DeleteAllAnnotationsEvent {
  kind: "DeleteAllAnnotationsEvent";
}

export interface ChangeSelectedAnnotationToTextSelectionEvent {
  kind: "ChangeSelectedAnnotationToTextSelectionEvent";
}

export interface SelectAnnotationEvent {
  kind: "SelectAnnotationEvent";
  selection: AnnotationSelection;
}

export interface SetAnnotationStateEvent {
  kind: "SetAnnotationStateEvent";
  annotationId: string;
  state: T.AnnotationState;
}

export interface SetAnnotationCommentVisibilityEvent {
  kind: "SetAnnotationCommentVisibilityEvent";
  visible: boolean;
}

export interface SetAnnotationCommentEvent {
  kind: "SetAnnotationCommentEvent";
  annotationId: string;
  comment: string;
}

export interface CreateAndAddToNewGroupEvent {
  kind: "CreateAndAddToNewGroupEvent";
  annotationId: string;
}

export interface SetAnnotationGroupEvent {
  kind: "SetAnnotationGroupEvent";
  annotationId: string;
  group?: number;
}

export interface SetShowAutomaticAnnotationsEvent {
  kind: "SetShowAutomaticAnnotationsEvent";
  newValue: boolean;
}


export const dispatch = (s: State, se: StateEvent): State => {
  const x = dispatch2(s, se);
  console.log(se);
  console.log(x);
  return x;
}

export const dispatch2 = (s: State, se: StateEvent): State => {
  switch (se.kind) {
    case "SetSchemaSelectionEvent": return {
      ...s,
      schemaSelection: se.selection
    }

    case "MouseDownEvent":
      const letters = s.tree.lookup(se.x, se.y);
      if (letters.length === 0) return {...s, mouseDownState: undefined};
      return {
        ...s,
        mouseDownState: {
          startX: se.x,
          startY: se.y,
          kind: "undecided"
        },
      };

    case "MouseMoveEvent":
      if (s.mouseDownState) {
        if (s.mouseDownState.kind === "move") {
          const letter = s.tree.lookup(se.x, se.y)[0];

          if (letter) {
            return {
              ...s,
              textSelection: {
                ...s.textSelection!,
                other: letter.index,
              }
            }
          } else {
            return s;
          }
        } else if (Math.abs(s.mouseDownState.startX - se.x) + Math.abs(s.mouseDownState.startY - se.y) > 20) {
          const startLetter = assertThere(
            s.tree.lookup(s.mouseDownState.startX, s.mouseDownState.startY)[0],
            "starting letter of undecided key down"
          );


          return {
            ...s,
            mouseDownState: {...s.mouseDownState, kind: "move"},
            textSelection: {
              start: startLetter.index,
              other: startLetter.index,
              newAnnotationUuid: uuid.v4(),
            }
          };
        } else {
          return s;
        }
      } else {
        const letters = s.tree.lookup(se.x, se.y);
        let annotation: T.Annotation | undefined = undefined;
        if (letters.length > 0) {
          annotation = getAnnotationsOfCurrentSchema(s).find(
            (a) =>
              a.span.startInc <= letters[0].index && a.span.endEx > letters[0].index
          );
        }
        return {
          ...s,
          hoveredAnnotation: annotation?.id,
        };
      }

    case "MouseUpEvent":
      if (s.mouseDownState) {
        const withNoMouseDown = ({...s, mouseDownState: undefined});
        const letters = s.tree.lookup(se.x, se.y);
        if (letters.length === 0) return withNoMouseDown;
        const letterIndex = letters[0].index;
        const annotations = getAnnotationsOfCurrentSchema(s).filter(
          (a) => a.span.startInc <= letterIndex && a.span.endEx >= letterIndex
        );
        if (annotations.length === 0) return withNoMouseDown;
        const contextMenu = se.openContextMenu ? {x: se.clientX, y: se.clientY} : undefined;
        return {
          ...withNoMouseDown,
          selectedAnnotation: {
            contextMenuAt: contextMenu,
            annotation: annotations[0].id,
            showComment: false
          }
        };
      } else {
        return s;
      }

    case "CreateAnnotationEvent":
      if (s.textSelection) {
        const newAnnotation: T.Annotation = {
          id: se.id,
          documentId: se.documentId,
          schemaNode: se.schemaNode,
          span: {
            startInc: Math.min(s.textSelection.start, s.textSelection.other),
            endEx: Math.max(s.textSelection.start, s.textSelection.other),
          },
          state: se.state,
          comment: "",
          auto: false
        };

        const removeTextSelection = pipe(
          L.id<State>(),
          L.prop("textSelection"),
          L.modify((_) => undefined)
        );

        const selectNewAnnotation = pipe(
          L.id<State>(),
          L.prop("selectedAnnotation"),
          L.modify((_) => ({annotation: newAnnotation.id, showComment: false}))
        )

        return pipe(
          s,
          addAnnotationToCurrentSchema(newAnnotation),
          removeTextSelection,
          selectNewAnnotation
        );

      } else {
        return s;
      }

    case "SetStateEvent": return se.state;

    case "CloseAnnotationContextMenuEvent":
      const x = pipe(
        L.id<State>(),
        L.prop("selectedAnnotation"),
        L.fromNullable,
        O.prop("contextMenuAt")
      )

      return x.set(undefined)(s)

    case "DeleteSelectedAnnotationEvent":
      if (s.schemaSelection && s.selectedAnnotation) {
        const id = s.selectedAnnotation.annotation;

        const selectedAnnotation = pipe(
          L.id<State>(),
          L.prop("selectedAnnotation")
        );

        const removeAnnotation =
          pipe(
            L.id<State>(),
            L.prop("document"),
            L.prop("expandedSchemas"),
            L.compose(guaranteedProperty<T.ExpandedSchema>(s.schemaSelection!.id)),
            L.prop("annotations"),
            L.modify(as => as.filter(a => a.id !== id))
          );


        return pipe(
          s,
          removeAnnotation,
          selectedAnnotation.set(undefined),
        );
      } else {
        return s;
      }

    case "DeleteAllAnnotationsEvent":
      if (s.schemaSelection) {

        const removeAnnotation =
          pipe(
            L.id<State>(),
            L.prop("document"),
            L.prop("expandedSchemas"),
            L.compose(guaranteedProperty<T.ExpandedSchema>(s.schemaSelection!.id)),
            L.prop("annotations"),
            L.modify(_ => [])
          );


        return pipe(
          s,
          removeAnnotation,
        );
      } else {
        return s;
      }

    case "ChangeSelectedAnnotationToTextSelectionEvent":
      if (s.selectedAnnotation && s.schemaSelection && s.textSelection) {
        const annotationId = s.selectedAnnotation.annotation;
        const start = Math.min(s.textSelection.start, s.textSelection.other);
        const end = Math.max(s.textSelection.start, s.textSelection.other);

        const updateAnnotation =
          pipe(
            L.id<State>(),
            L.prop("document"),
            L.prop("expandedSchemas"),
            L.compose(guaranteedProperty(s.schemaSelection.id)),
            L.prop("annotations"),
            L.modify(as => as.map(a => a.id === annotationId ? {...a, span: {startInc: start, endEx: end}} : a))
          )

        const removeTextSelection = pipe(
          L.id<State>(),
          L.prop("textSelection"),
          L.modify((_) => undefined)
        )

        return pipe(
          s,
          updateAnnotation,
          removeTextSelection
        );

      } else {
        return s;
      }

    case "SelectAnnotationEvent":
      if (s.schemaSelection) {
        return pipe(
          L.id<State>(),
          L.prop("selectedAnnotation"),
        ).set(se.selection)(s);
      } else {
        return s;
      }

    case "SetAnnotationStateEvent":
      if (s.schemaSelection) {
        return pipe(
          L.id<State>(),
          L.prop("document"),
          L.prop("expandedSchemas"),
          L.key(s.schemaSelection.id),
          O.prop("annotations"),
          OFindFirst(a => a.id === se.annotationId),
          O.prop("state"),
          O.modify((_) => se.state)
        )(s);
      }
      return s;

    case "SetAnnotationCommentVisibilityEvent":
      return pipe(
        L.id<State>(),
        L.prop("selectedAnnotation"),
        L.fromNullable,
        O.prop("showComment"),
        O.modify((_) => se.visible)
      )(s);

    case "SetAnnotationCommentEvent":
      if (s.schemaSelection) {
        return pipe(
          L.id<State>(),
          L.prop("document"),
          L.prop("expandedSchemas"),
          L.key(s.schemaSelection.id),
          O.prop("annotations"),
          OFindFirst(a => a.id === se.annotationId),
          O.prop("comment"),
          O.modify((_) => se.comment)
        )(s);
      }
      return s;

    case "CreateAndAddToNewGroupEvent":
      if (s.schemaSelection) {
        const taken = s.document.expandedSchemas[s.schemaSelection.id].annotations.filter(a => a.groupnumber !== undefined && a.groupnumber !== null).map(a => a.groupnumber!);

        const nextId = taken.length === 0 ? 0 : Math.max.apply(null, taken) + 1;

        return pipe(
          L.id<State>(),
          L.prop("document"),
          L.prop("expandedSchemas"),
          L.key(s.schemaSelection.id),
          O.prop("annotations"),
          OFindFirst(a => a.id === se.annotationId),
          O.prop("groupnumber"),
          O.modify((_) => nextId)
        )(s);
      }
      return s;

    case "SetAnnotationGroupEvent":
      if (s.schemaSelection) {
        return pipe(
          L.id<State>(),
          L.prop("document"),
          L.prop("expandedSchemas"),
          L.key(s.schemaSelection.id),
          O.prop("annotations"),
          OFindFirst(a => a.id === se.annotationId),
          O.prop("groupnumber"),
          O.modify((_) => se.group)
        )(s);
      }
      return s;

    case "SetShowAutomaticAnnotationsEvent":
      return pipe(
        L.id<State>(),
        L.prop("showAutomaticAnnotations"),
        L.modify((_) => se.newValue)
      )(s);


    default: return assertNever(se);
  }
};

function OFindFirst<A>(predicate: Predicate<A>): <S>(sa: O.Optional<S, A[]>) => O.Optional<S, A> {
  return O.findFirst(predicate) as any;
}


export const getAnnotationsOfCurrentSchema = (s: State): T.Annotation[] => {
  if (!s.schemaSelection) return [];
  const schema = s.document.expandedSchemas[s.schemaSelection.id];
  if (!schema) return [];
  const nodes = flattenNodes(schema.schema.root);
  return s
    .document
    .expandedSchemas[s.schemaSelection.id]
    .annotations
    .filter(a => a.auto === s.showAutomaticAnnotations)
    .map(ann => {
      const colorHint = nodes.find(n => n.id === ann.schemaNode)?.name;
      return {...ann, colorHint};
    });
}

export const getSelectedAnnotation = (s: State): T.Annotation | undefined => {
  if (s.selectedAnnotation) {
    const id = s.selectedAnnotation.annotation;
    const annotations = getAnnotationsOfCurrentSchema(s);
    return annotations.find(a => a.id === id);
  }
}

export const addAnnotationToCurrentSchema = (a: T.Annotation) => (s: State): State => {
  if (!s.schemaSelection) return s;

  return pipe(
    L.id<State>(),
    L.prop("document"),
    L.prop("expandedSchemas"),
    L.compose(guaranteedProperty<T.ExpandedSchema>(s.schemaSelection.id)),
    L.prop("annotations"),
    L.composeOptional(arrayLast())
  ).set(a)(s)
}

