import { useRef, useState } from "react"
import { uploadDocument } from "../../backend/Api"
import * as T from "../../backend/types"
import { HoverHelp } from "../../common-components/hover-help/HoverHelp"
import { Loader } from "../../common-components/loader/Loader"
import { toast } from "../../common-components/toast/Toast"
import { useApiAuto } from "../../hooks/useApi"
import { toText } from "../../util/util"
import "./upload.scss"

export const Upload = () => {
  const [req, setReq] = useState<T.UploadDocumentRequest | null>(null);
  const res = useApiAuto(uploadDocument, req);

  return <div className="route-upload">
    <div className="middle">
      <UploadForm doUpload={setReq} />
      <div>
        {
          res == "not-yet-started" ? null :
            res == "loading" ? <Loader input={res} /> :
              res.kind == "Ok" ? "Upload Erfolgreich" : "Error"
        }
      </div>
    </div>
  </div>
}

const UploadForm = (props: { doUpload: (dr: T.UploadDocumentRequest) => void }) => {
  const fileRef = useRef<HTMLInputElement>(null);

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [date, setDate] = useState("");

  const doUpload = () => {
    const file = fileRef.current?.files?.[0];
    if (!file) {
      toast("No file selected");
      return;
    }

    toText(file).then(text =>
      props.doUpload({
        id,
        name,
        date,
        html: text
      })
    )
  }

  return <form className="upload-form" onSubmit={e => { e.preventDefault(); doUpload(); }}>
    <label>
      <span>Id</span>
      <input className="default-input" type="text" value={id} onChange={e => setId(e.target.value)} />
      <HoverHelp>Wählt man eine ID, die schon existiert, wird das Dokument überschrieben.</HoverHelp>
    </label>
    <label>
      <span>Name</span>
      <input className="default-input" type="text" value={name} onChange={e => setName(e.target.value)} />
      <HoverHelp>Hier eine kurze Beschreibung anzeigen, wie die, die auf der Suchseite angezeigt werden soll.</HoverHelp>
    </label>
    <label>
      <span>Datum</span>
      <input className="default-input" type="date" value={date} onChange={e => setDate(e.target.value)} />
      <HoverHelp>Das Datum des Urteils.</HoverHelp>
    </label>
    <label>
      <span>File</span>
      <input ref={fileRef} type="file" />
      <HoverHelp>Muss ein HTML sein. Alles andere wird als Text interpretiert.</HoverHelp>
    </label>
    <button>Upload</button>
  </form>
}
