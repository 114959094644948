import './StoredSearch.scss';
import React, {ReactElement, useState, useEffect} from 'react';
import {BEM, assertNever} from '../../util/util';
import {Bannered} from '../../common-components/bannered/Bannered';
import {StoredSearchOverview} from './StoredSearchOverview';
import {StoredSearchMeta} from './StoredSearchMeta';
import {StoredSearchSearch} from './StoredSearchSearch';
import {StoredSearchTest} from './StoredSearchTest';
import {StoredSearchHeader} from './StoredSearchHeader';
import * as api from '../../backend/Api';
import * as T from '../../backend/types';
import * as S from './state';
import {Loader} from '../../common-components/loader/Loader';
import {setFixedError} from '../../common-components/fixed-error/FixedError';
import {useSchemas} from '../../hooks/useSchema';

export const StoredSearch = (): ReactElement => {
    const [initial, setInitial] = useState<undefined | T.StoredSearch[]>();

    useEffect(() => {
        api.listStoredSearches().then(response => {
            switch (response.kind) {
                case "AlwaysError": setFixedError(response); break;
                case "GotData": setInitial(response.data); break;
                default: assertNever(response);
            }
        })
    }, [])

    const schemas = useSchemas() || [];

    if (initial) return <StoredSearchLoaded schemas={schemas} initial={initial} />;
    else return <Loader input={"loading"} />;
}

export const StoredSearchLoaded = (props: {initial: T.StoredSearch[], schemas: T.Schema[]}): ReactElement => {
    const [state, setState] = useState<S.State>({searches: props.initial});

    return <div {...BEM('default-layout')}>
        <div {...BEM(['default-layout', 'header'])}>
            <StoredSearchHeader setState={setState} state={state} />
        </div>
        <div {...BEM(['default-layout', 'left', {'no-scroll': true}])}>
            <Bannered name={"Alle Suchen"} scroll={true}
                additionalBem={[["default-layout", "column-element"]]}
            >
                <StoredSearchOverview setState={setState} state={state} />
            </Bannered>

        </div>
        <div {...BEM(['default-layout', 'middle', {'no-scroll': true, '2-children': true}])}>
            <Bannered name={"Suchbaum"} scroll={true}
                additionalBem={[["default-layout", "column-element"]]}
            >
                <StoredSearchSearch setState={setState} state={state} schemas={props.schemas} />
            </Bannered>
            <Bannered name={"Test"} scroll={true}
                additionalBem={[["default-layout", "column-element"]]}
            >
                <StoredSearchTest setState={setState} state={state} />
            </Bannered>

        </div>

        <div {...BEM(['default-layout', 'right', {'no-scroll': true}])}>
            <Bannered name={"Metadaten"} scroll={true}
                additionalBem={[["default-layout", "column-element"]]}
            >
                <StoredSearchMeta setState={setState} state={state} />
            </Bannered>

        </div>

    </div>;
}
