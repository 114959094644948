import React, {ReactElement} from 'react';

import {Bannered} from "../../common-components/bannered/Bannered";
import {Loader} from "../../common-components/loader/Loader";
import {useApi} from "../../hooks/useApi";
import * as api from "../../backend/Api";

export const UpdateDefaultSearches = (): ReactElement => {
  const [res, runner] = useApi(api.updateDefaultStoredSearches);

  const state: "error" | "success" | undefined =
    res === "loading" || res === "not-yet-started" ? undefined :
      res.kind === "AlwaysError" ? "error" : "success";

  return <Bannered
    name="Update Default Searches"
    topRight={[<div onClick={runner}>run️</div>]}
    state={state}>
    {
      res === "not-yet-started" ? null :
        res === "loading" || res.kind === "AlwaysError" ?
          <Loader input={res} /> : "Ok"
    }
  </Bannered>
}

