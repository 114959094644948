import React, {ReactElement, useEffect, useRef} from "react";
import {assertThere, hashStringToRgb} from "../../util/util";
import {Letter} from "./letter";
import {DocumentConfig} from "./document-config";
import {UnassociatedAnnotation} from './model/annotation';

export const AnnotationCanvas = (props: Props): ReactElement => {
    const canvasRef = useRef<HTMLCanvasElement>();

    useEffect(() => {
        const canvas = assertThere(canvasRef.current, "textCanvas(when drawing)");
        const context = assertThere(canvas.getContext('2d'), "context(when drawing text canvas)");

        canvas.height = props.annotations.reduce((lastMax, ann) => (ann.span.startInc >= ann.span.endEx) ? 0 : Math.max(props.letters[ann.span.endEx - 1]?.boundingBox?.LOWER || 0, lastMax), 0);
        context.clearRect(0, 0, canvas.width, canvas.height);

        context.save();
        context.font = props.config.font;

        for (let ann of props.annotations) {
            const background = (ann.id === props.highlight) ? "#000000" : "#" + hashStringToRgb(ann.colorHint || ann.id) + "55";
            const foreground = (ann.id === props.highlight) ? "#FFFFFF" : undefined;

            let letters = props.letters.slice(ann.span.startInc, ann.span.endEx + 1);
            for (let letter of letters) {
                context.fillStyle = background;
                context.fillRect(
                    letter.boundingBox.LEFT,
                    letter.boundingBox.UPPER,
                    (letter.boundingBox.RIGHT - letter.boundingBox.LEFT),
                    (letter.boundingBox.LOWER - letter.boundingBox.UPPER));
                if (foreground) {
                    context.fillStyle = foreground;
                    context.fillText(letter.letter, letter.boundingBox.LEFT, letter.boundingBox.LOWER);
                }
            }
        }

        context.restore();
    }, [props.annotations, props.letters, props.config, props.highlight]);

    return <canvas width={props.config.width} height={400} ref={(ce) => canvasRef.current = ce!} />;
}

export interface Props {
    annotations: UnassociatedAnnotation[];
    letters: Letter[];
    config: DocumentConfig;
    highlight?: string;
}
