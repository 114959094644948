import React, {Fragment, ReactElement, useState} from "react";

import * as T from "../../backend/types";
import {findNode, flattenNodes} from "../../routes/schema/tree";
import {assertNever, BEM, classNames} from "../../util/util";
import * as api from "../../backend/Api";
import {ContextMenu, Menu, transformCallbacksMenu} from "../ContextMenu";
import {setFixedError} from "../fixed-error/FixedError";
import {askKeyedList, askList, showHtmlContent} from "../toast/Toast";
import {MatcherTest} from "./MatcherTest";

export const Matcher = (props: {
  setter: (m: null | T.PreMatcher) => void;
  matcher: T.PreMatcher | null | undefined;
  context: Context;
  isRoot?: boolean;
}): ReactElement => {
  const wrap = (e: ReactElement) =>
    <div className="search-top">
      <div className="search-scorers">
        <div className="scorer-top">
          {e}
        </div>
      </div>
    </div>;

  if (props.matcher) {
    const matcher: T.PreMatcher = props.matcher;
    let elem = null;
    if (matcher.kind === "And")
      elem = <AndOrMatcher setter={props.setter} matcher={matcher} context={props.context} />;
    else if (matcher.kind === "Or")
      elem = <AndOrMatcher setter={props.setter} matcher={matcher} context={props.context} />;
    else if (matcher.kind === "Not")
      elem = <NotMatcher setter={props.setter} matcher={matcher} context={props.context} />;
    else if (matcher.kind === "ContainsSubstring")
      elem = <ContainsSubstringMatcher setter={props.setter} matcher={matcher} context={props.context} />;
    else if (matcher.kind === "ContainsRegex")
      elem = <ContainsRegexMatcher setter={props.setter} matcher={matcher} context={props.context} />;
    else if (matcher.kind === "InCourt")
      elem = <InCourtMatcher setter={props.setter} matcher={matcher} context={props.context} />;
    else if (matcher.kind === "IsBought")
      elem = <IsBoughtMatcher setter={props.setter} matcher={matcher} context={props.context} />;
    else if (matcher.kind === "Within")
      elem = <WithinMatcher setter={props.setter} matcher={matcher} context={props.context} />;
    else if (matcher.kind === "WithinNode")
      elem = <WithinNodeMatcher setter={props.setter} matcher={matcher} context={props.context} />;
    else if (matcher.kind === "ContainsTokens")
      elem = <ContainsTokensMatcher setter={props.setter} matcher={matcher} context={props.context} />;
    else if (matcher.kind === "ContainsAnnotationOfSchema")
      elem = <ContainsAnnotationOfSchemaMatcher setter={props.setter} matcher={matcher} context={props.context} />;
    else if (matcher.kind === "ContainsAnnotationOfNode")
      elem = <ContainsAnnotationOfNodeMatcher setter={props.setter} matcher={matcher} context={props.context} />;
    else if (matcher.kind === "ContainsSubstringInName")
      elem = <ContainsSubstringInNameMatcher setter={props.setter} matcher={matcher} context={props.context} />;
    else if (matcher.kind === "MatchesNDA")
      elem = <MatchesNDAMatcher setter={props.setter} matcher={matcher} context={props.context} />;
    else {
      elem = assertNever(matcher);
    }


    if (props.isRoot) {
      elem = wrap(elem);
    }
    return elem;
  } else {
    const addMenu = makeAddMenu(newMatchers => {
      if (newMatchers.length === 0) {
        props.setter(null);
      } else if (newMatchers.length === 1) {
        props.setter(newMatchers[0]);
      } else {
        props.setter({kind: "And", subs: newMatchers})
      }
    });
    let elem = <AddMenu menu={addMenu} />
    if (props.isRoot) {
      elem = wrap(elem);
    }
    return elem;

  }
};

const MatcherHoc = (props: {
  matcher: T.PreMatcher;
  setter: (v: null | T.PreMatcher) => void;
  name: String;
  subs: ReactElement[];
  remove?: ReactElement;
  add?: ReactElement;
  terminal: boolean;
  context: Context;
}): ReactElement => {
  return (
    <div className={"matcher"}>
      <div className="matcher-type-label">
        <div>
          <NameMenu
            name={props.name}
            setter={props.setter}
            matcher={props.matcher}
          />
        </div>
      </div>
      <div className="matcher-type-line">
        <div className="matcher-line"></div>
      </div>
      <div className="matcher-content">
        {props.add ? <div className={"add-on-grid"}>{props.add}</div> : null}
        <div
          className={classNames({
            "matcher-content-line": true,
            terminal: props.terminal,
          })}
        ></div>
        <div className="matcher-content-subs">
          {props.subs.map((sub, i) => (
            <div key={i} className="matcher-sub">
              <div className="matcher-sub-line"></div>
              <div className="matcher-sub-content">{sub}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const ContainsSubstringMatcher = (props: {
  setter: (m: null | T.PreMatcher) => void;
  matcher: T.ContainsSubstring;
  context: Context;
}): ReactElement => {
  const setSubstring = (newValue: string): void => {
    props.setter({
      ...props.matcher,
      substring: newValue,
    });
  };

  const sub = (
    <div>
      <input
        {...BEM("default-input")}
        value={props.matcher.substring}
        onChange={(e) => setSubstring(e.target.value)}
      />
    </div>
  );

  return (
    <MatcherHoc
      matcher={props.matcher}
      setter={props.setter}
      name={"Enthält Teilsequenz"}
      subs={[sub]}
      terminal={true}
      context={props.context}
    />
  );
};

const ContainsSubstringInNameMatcher = (props: {
  setter: (m: null | T.PreMatcher) => void;
  matcher: T.ContainsSubstringInName;
  context: Context;
}): ReactElement => {
  const setSubstring = (newValue: string): void => {
    props.setter({
      ...props.matcher,
      substring: newValue,
    });
  };

  const sub = (
    <div>
      <input
        {...BEM("default-input")}
        value={props.matcher.substring}
        onChange={(e) => setSubstring(e.target.value)}
      />
    </div>
  );

  return (
    <MatcherHoc
      matcher={props.matcher}
      setter={props.setter}
      name={"Name enthält Teilsequenz"}
      subs={[sub]}
      terminal={true}
      context={props.context}
    />
  );
};

const ContainsTokensMatcher = (props: {
  setter: (m: null | T.PreMatcher) => void;
  matcher: T.ContainsTokens;
  context: Context;
}): ReactElement => {
  const setSubstring = (idx: number, newValue: string): void => {
    props.setter({
      ...props.matcher,
      tokens: props.matcher.tokens.map((t, i) => (i === idx) ? newValue : t)
    });
  };

  const addSubstring = (): void => props.setter({
    ...props.matcher,
    tokens: props.matcher.tokens.concat([""])
  })

  const removeSubstring = (idx: number): void => props.setter({
    ...props.matcher,
    tokens: props.matcher.tokens.filter((_, i) => i !== idx)
  })

  const sub = <div>
    <div>
      {
        props.matcher.tokens.map((t, i) =>
          <div className={"matcher-input-and-remove-line"}>
            <input key={i}
              {...BEM("default-input")}
              value={t}
              onChange={(e) => setSubstring(i, e.target.value)}
            />
            <button onClick={() => removeSubstring(i)}>-</button>
          </div>
        )
      }
    </div>
    <div>
      <button onClick={addSubstring}>+</button>
    </div>
  </div>;

  return (
    <MatcherHoc
      matcher={props.matcher}
      setter={props.setter}
      name={"Enthält Wörter"}
      subs={[sub]}
      terminal={true}
      context={props.context}
    />
  );
};


const ContainsRegexMatcher = (props: {
  setter: (m: null | T.PreMatcher) => void;
  matcher: T.ContainsRegex;
  context: Context;
}): ReactElement => {
  const setRegex = (newValue: string): void => {
    props.setter({
      ...props.matcher,
      regex: newValue,
    });
  };

  const sub = (
    <div>
      <input
        {...BEM("default-input")}
        value={props.matcher.regex}
        onChange={(e) => setRegex(e.target.value)}
      />
    </div>
  );

  return (
    <MatcherHoc
      matcher={props.matcher}
      setter={props.setter}
      name={"Enthält Regex"}
      subs={[sub]}
      terminal={true}
      context={props.context}
    />
  );
};

const MatchesNDAMatcher = (props: {
  setter: (m: null | T.PreMatcher) => void;
  matcher: T.MatchesNDA;
  context: Context;
}): ReactElement => {
  const setUserInput = (newValue: string): void => {
    props.setter({
      ...props.matcher,
      userInput: newValue,
    });
  };

  const sub = (
    <div>
      <input
        {...BEM("default-input")}
        value={props.matcher.userInput}
        onChange={(e) => setUserInput(e.target.value)}
      />
    </div>
  );

  return (
    <MatcherHoc
      matcher={props.matcher}
      setter={props.setter}
      name={"Matcht einen Automaten"}
      subs={[sub]}
      terminal={true}
      context={props.context}
    />
  );
};

const InCourtMatcher = (props: {
  setter: (m: null | T.PreMatcher) => void;
  matcher: T.InCourt;
  context: Context;
}): ReactElement => {
  const setValue = (newValue: string): void => {
    props.setter({
      ...props.matcher,
      court: newValue,
    });
  };

  const sub = (
    <div>
      <select
        value={props.matcher.court}
        onChange={(e) => setValue(e.target.value)}
      >
        {[
          "AG",
          "ArbG",
          "VG",
          "LG",
          "OLG",
          "LSG",
          "LAG",
          "VGH",
          "FG",
          "SG",
          "Staatsgerichtshof",
        ].map((c) => (
          <option key={c} value={c}>
            {c}
          </option>
        ))}
      </select>
    </div>
  );

  return (
    <MatcherHoc
      matcher={props.matcher}
      setter={props.setter}
      name={"In Gericht"}
      subs={[sub]}
      terminal={true}
      context={props.context}
    />
  );
};


const ContainsAnnotationOfSchemaMatcher = (props: {
  setter: (m: null | T.PreMatcher) => void;
  matcher: T.ContainsAnnotationOfSchema;
  context: Context;
}): ReactElement => {
  const sub = (
    <div>
      {props.context.schemas.find(s => s.id === props.matcher.schemaId)?.name}
    </div>
  );

  return (
    <MatcherHoc
      matcher={props.matcher}
      setter={props.setter}
      name={"Enthält Annotation von"}
      subs={[sub]}
      terminal={true}
      context={props.context}
    />
  );
};

const IsBoughtMatcher = (props: {
  setter: (m: null | T.PreMatcher) => void;
  matcher: T.IsBought;
  context: Context;
}): ReactElement => {
  const sub = (
    <div>Wurde gekauft</div>
  );

  return (
    <MatcherHoc
      matcher={props.matcher}
      setter={props.setter}
      name={"Ist gekauft"}
      subs={[sub]}
      terminal={true}
      context={props.context}
    />
  );
};

const WithinMatcher = (props: {
  setter: (m: null | T.PreMatcher) => void;
  matcher: T.Within;
  context: Context;
}): ReactElement => {
  const setNode = (newValue: null | T.PreMatcher): void => {
    if (newValue === null) props.setter(null);
    else
      props.setter({
        ...props.matcher,
        sub: newValue,
      });
  };
  const sub = (
    <Matcher setter={setNode} matcher={props.matcher.sub} context={props.context} />
  );

  return (
    <MatcherHoc
      matcher={props.matcher}
      setter={props.setter}
      name={"Ist innerhalb eines " + props.context.schemas.find(s => s.id === props.matcher.schema)?.name}
      subs={[sub]}
      terminal={true}
      context={props.context}
    />
  );
};

const ContainsAnnotationOfNodeMatcher = (props: {
  setter: (m: null | T.PreMatcher) => void;
  matcher: T.ContainsAnnotationOfNode;
  context: Context;
}): ReactElement => {
  const schema = props.context.schemas.find(s => s.id === props.matcher.schemaId);
  const node = !schema ? undefined : findNode(schema.root, props.matcher.nodeId);

  const sub = (
    <div>
      {schema?.name} / {node?.name}
    </div>
  );

  return (
    <MatcherHoc
      matcher={props.matcher}
      setter={props.setter}
      name={"Ist annotiert mit" + schema?.name + "/" + node?.name}
      subs={[sub]}
      terminal={true}
      context={props.context}
    />
  );
};

const WithinNodeMatcher = (props: {
  setter: (m: null | T.PreMatcher) => void;
  matcher: T.WithinNode;
  context: Context;
}): ReactElement => {
  const setNode = (newValue: null | T.PreMatcher): void => {
    if (newValue === null) props.setter(null);
    else
      props.setter({
        ...props.matcher,
        sub: newValue,
      });
  };
  const sub = (
    <Matcher setter={setNode} matcher={props.matcher.sub} context={props.context} />
  );
  const schema = props.context.schemas.find(s => s.id === props.matcher.schema);
  const node = !schema ? undefined : findNode(schema.root, props.matcher.nodeId);

  return (
    <MatcherHoc
      matcher={props.matcher}
      setter={props.setter}
      name={"Ist innerhalb eines " + schema?.name + "/" + node?.name}
      subs={[sub]}
      terminal={true}
      context={props.context}
    />
  );
};

const AndOrMatcher = (props: {
  setter: (m: null | T.PreMatcher) => void;
  matcher: T.And | T.Or;
  context: Context;
}): ReactElement => {
  const addNodes = (m: T.PreMatcher[]) => {
    props.setter({...props.matcher, subs: props.matcher.subs.concat(m)});
  };

  const removeNode = (i: number): void => {
    props.setter({
      ...props.matcher,
      subs: props.matcher.subs.filter((_, ii) => ii !== i),
    });
  };

  const setNode = (newValue: null | T.PreMatcher, i: number): void => {
    if (newValue === null) removeNode(i);
    else
      props.setter({
        ...props.matcher,
        subs: props.matcher.subs.map((oldValue, ii) =>
          i === ii ? newValue : oldValue
        ),
      });
  };

  const addMenu = makeAddMenu(addNodes);

  const subs = props.matcher.subs.map((sub, i) => (
    <div>
      <Matcher setter={(n) => setNode(n, i)} matcher={sub} context={props.context} />
    </div>
  ));

  return (
    <MatcherHoc
      matcher={props.matcher}
      setter={props.setter}
      name={props.matcher.kind}
      subs={subs}
      add={<AddMenu menu={addMenu} />}
      terminal={false}
      context={props.context}
    />
  );
};

const NotMatcher = (props: {
  setter: (m: null | T.PreMatcher) => void;
  matcher: T.Not;
  context: Context;
}): ReactElement => {
  const setNode = (newValue: null | T.PreMatcher): void => {
    if (newValue === null) props.setter(null);
    else
      props.setter({
        ...props.matcher,
        sub: newValue,
      });
  };
  const sub = (
    <div>
      <Matcher setter={setNode} matcher={props.matcher.sub} context={props.context} />
    </div>
  );

  return (
    <MatcherHoc
      matcher={props.matcher}
      setter={props.setter}
      name={props.matcher.kind}
      subs={[sub]}
      terminal={false}
      context={props.context}
    />
  );
};

const substituteWithChild = (m: T.PreMatcher): T.PreMatcher => {
  switch (m.kind) {
    // those have exactly one child to return
    case 'Not': return m.sub;
    case 'Within': return m.sub;
    case 'WithinNode': return m.sub;


    // those may have multiple children. Only works if it has exactly one child
    // otherwise we do nothing
    case 'And':
      if (m.subs.length === 1) return m.subs[0];
      else return m;
    case 'Or':
      if (m.subs.length === 1) return m.subs[0];
      else return m;

    // those don't have children at all - we do nothing
    case 'ContainsAnnotationOfNode': return m;
    case 'ContainsAnnotationOfSchema': return m;
    case 'ContainsRegex': return m;
    case 'ContainsSubstring': return m;
    case 'ContainsSubstringInName': return m;
    case 'ContainsTokens': return m;
    case 'InCourt': return m;
    case 'IsBought': return m;
    case 'MatchesNDA': return m;
    default: return assertNever(m);
  }
}

const testMatcher = (matcher: T.PreMatcher): void => {
  showHtmlContent(<MatcherTest matcher={matcher} />);
}

export const NameMenu = (props: {
  name: String;
  matcher: T.PreMatcher;
  setter: (m: null | T.PreMatcher) => void;
}): ReactElement => {
  const [contextMenuPos, setContextMenuPos] = useState<{
    x: number;
    y: number;
  } | null>(null);

  return (
    <Fragment>
      <div
        className="matcher-hoc-type"
        onClick={(e) => setContextMenuPos({x: e.clientX, y: e.clientY})}
      >
        {props.name}
      </div>
      {contextMenuPos === null ? null : (
        <ContextMenu
          x={contextMenuPos.x}
          y={contextMenuPos.y}
          onClose={() => setContextMenuPos(null)}
          menu={transformCallbacksMenu(
            {
              name: "top",
              subs: [
                {
                  name: "Löschen",
                  callback: () => props.setter(null),
                },
                {
                  name: "Einpacken",
                  subs: [
                    {
                      name: "Negieren",
                      callback: () =>
                        props.setter({kind: "Not", sub: props.matcher}),
                    },
                    {
                      name: "Verunden",
                      callback: () =>
                        props.setter({kind: "And", subs: [props.matcher]}),
                    },
                    {
                      name: "Verodern",
                      callback: () =>
                        props.setter({kind: "Or", subs: [props.matcher]}),
                    },
                    {
                      name: "Einschränken",
                      subs: [
                        {
                          name: "Auf irgendeinen Knoten",
                          callback: () =>
                            selectSchema().then(s =>
                              props.setter({kind: "Within", sub: props.matcher, schema: s.id}))
                        },
                        {
                          name: "Auf einen bestimmten Knoten",
                          callback: () =>
                            selectSchemaNode().then(result =>
                              props.setter({kind: "WithinNode", sub: props.matcher, schema: result[0], nodeId: result[1]}))
                        },
                      ]
                    },
                  ],
                },
                {
                  name: "Durch Kind Ersetzen",
                  callback: () => props.setter(substituteWithChild(props.matcher)),
                },
                {
                  name: "Testen",
                  callback: () => testMatcher(props.matcher)
                },
              ],
            },
            (c) => () => {
              setContextMenuPos(null);
              c();
            }
          )}
        />
      )}
    </Fragment>
  );
};

const selectSearch = async (): Promise<T.Search<T.PreMatcher>> => {
  const response = await api.listStoredSearches();
  switch (response.kind) {
    case "AlwaysError":
      setFixedError(response);
      throw new Error("Got Server Error");
    case "GotData":
      const answers = response.data.map(ss => ss.name);
      const selected = await askList("Bitte wählen Sie eine gespeicherte Suche", answers);
      return response.data.find(r => r.name === selected)!.search;
    default: return assertNever(response);
  }

}

const selectSchema = async (): Promise<T.SchemaOverview> => {
  const response = await api.listSchemas();
  switch (response.kind) {
    case "AlwaysError":
      setFixedError(response);
      throw new Error("Got Server Error");
    case "GotData":
      const answers = response.data.map(ss => ({payload: ss, message: ss.name}));
      return await askKeyedList("Bitte wählen Sie ein Schema", answers);
    default: return assertNever(response);
  }

}

export const selectSchemaNode = async (): Promise<[string, string]> => {
  const response = await api.listSchemas();
  switch (response.kind) {
    case "AlwaysError":
      setFixedError(response);
      throw new Error("Got Server Error");
    case "GotData":
      const answers = response.data.map(ss => ({payload: ss, message: ss.name}));
      const answer = await askKeyedList("Bitte wählen Sie ein Schema", answers);
      const schema = await api.getSchema(answer.id);
      switch (schema.kind) {
        case "AlwaysError":
          setFixedError(schema);
          throw new Error("Got Server Error");
        case "NotFound":
          throw new Error("Could not find schema for id" + answer.id);
        case "GotData":
          const nodes = flattenNodes(schema.data.root);
          const nodeSelection = nodes.map(node => ({payload: node, message: node.name}));
          const selectedNode = await askKeyedList("Bitte wählen Sie einen Knoten", nodeSelection);
          return [schema.data.id, selectedNode.id];
        default: return assertNever(schema);
      }
    default: return assertNever(response);
  }

}


const addScorerMenu = (props: {
  onAddAnd: () => void;
  onAddOr: () => void;
  onAddContainsSubstrings: () => void;
  onAddContainsRegex: () => void;
  onAddInCourt: () => void;
  onSelectSearch: () => void;
  onAddBought: () => void;
  onAddContainsToken: () => void;
  onAddContainsAnnotationOfSchema: () => void;
  onAddContainsAnnotationOfNode: () => void;
  onAddContainsSubstringInName: () => void;
  onAddMatchesNDA: () => void;
}): Menu => {
  return {
    name: "top",
    subs: [
      {
        name: "Gespeicherte Suche",
        callback: () => {
          props.onSelectSearch();
        },
      },
      {
        name: "Verbindungen",
        subs: [
          {
            name: "Verundung",
            callback: () => {
              props.onAddAnd();
            },
          },
          {
            name: "Veroderung",
            callback: () => {
              props.onAddOr();
            },
          },
        ],
      },
      {
        name: "Auf Text",
        subs: [
          {
            name: "Teilsequenz",
            callback: () => {
              props.onAddContainsSubstrings();
            },
          },
          {
            name: "Regulärer Ausdruck",
            callback: () => {
              props.onAddContainsRegex();
            },
          },
          {
            name: "Beinhaltet Wörter",
            callback: () => {
              props.onAddContainsToken();
            },
          },
          {
            name: "Beinhaltet Automaten",
            callback: () => {
              props.onAddMatchesNDA();
            },
          },
        ],
      }, {
        name: "Auf Dokument",
        subs: [
          {
            name: "Gerichtstyp",
            callback: () => {
              props.onAddInCourt();
            },
          },
          {
            name: "Gekauft",
            callback: () => {
              props.onAddBought();
            },
          },
          {
            name: "Wurde mit Schema annotiert",
            callback: () => {
              props.onAddContainsAnnotationOfSchema();
            },
          },
          {
            name: "Wurde mit Knoten annotiert",
            callback: () => {
              props.onAddContainsAnnotationOfNode();
            },
          },
          {
            name: "Enthält im Titel/Name",
            callback: () => {
              props.onAddContainsSubstringInName();
            },
          },
        ],
      },
    ],
  };
};

export const AddMenu = (props: {menu: Menu}): ReactElement => {
  const [contextMenuPos, setContextMenuPos] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const menu = transformCallbacksMenu(props.menu, (c) => () => {
    setContextMenuPos(null);
    c();
  });

  return (
    <Fragment>
      <div
        className="add-plus"
        onClick={(e) => setContextMenuPos({x: e.clientX, y: e.clientY})}
      >
        +
      </div>
      {contextMenuPos === null ? null : (
        <ContextMenu onClose={() => setContextMenuPos(null)} x={contextMenuPos.x} y={contextMenuPos.y} menu={menu} />
      )}
    </Fragment>
  );
};


export interface Context {
  schemas: T.Schema[];
}

const getMatchersFromScorers = (scorers: T.Scorer<T.PreMatcher>[]): T.PreMatcher[] => {
  return scorers.flatMap(scorer => {
    switch (scorer.kind) {
      case 'AssocicatedScorer': return [scorer.matcher];
      case 'UnassociatedScorer': return [scorer.matcher];
      case 'DropContainedScorer': return getMatchersFromScorers(scorer.subs);
      default: assertNever(scorer);
    }
    return [];
  });
}

export const makeAddMenu = (onNewMatcher: (m: T.PreMatcher[]) => void): Menu => {
  const addNode = (m: T.PreMatcher) => {
    onNewMatcher([m]);
  };

  const onAddAnd = (): void => addNode({kind: "And", subs: []});
  const onAddOr = (): void => addNode({kind: "Or", subs: []});
  const onAddContainsSubstrings = (): void =>
    addNode({kind: "ContainsSubstring", substring: ""});
  const onAddContainsRegex = (): void =>
    addNode({kind: "ContainsRegex", regex: ""});
  const onAddInCourt = (): void => addNode({kind: "InCourt", court: "ArbG"});
  const onSelectSearch = (): void => {
    selectSearch().then(search => {
      onNewMatcher(getMatchersFromScorers(search.scorers));
    });
  }
  const onAddBought = (): void => addNode({kind: "IsBought"});
  const onAddContainsToken = (): void => addNode({kind: "ContainsTokens", tokens: []});
  const onAddContainsAnnotationOfSchema = (): void => {
    selectSchema().then(so => addNode({kind: "ContainsAnnotationOfSchema", schemaId: so.id}));
  }
  const onAddContainsAnnotationOfNode = (): void => {
    selectSchemaNode().then(no => addNode({kind: "ContainsAnnotationOfNode", schemaId: no[0], nodeId: no[1]}));
  }
  const onAddContainsSubstringInName = (): void =>
    addNode({kind: "ContainsSubstringInName", substring: ""});
  const onAddMatchesNDA = (): void =>
    addNode({kind: "MatchesNDA", userInput: ""});

  return addScorerMenu({
    onAddAnd,
    onAddOr,
    onAddContainsSubstrings,
    onAddContainsRegex,
    onAddInCourt,
    onSelectSearch,
    onAddBought,
    onAddContainsToken,
    onAddContainsAnnotationOfSchema,
    onAddContainsAnnotationOfNode,
    onAddContainsSubstringInName,
    onAddMatchesNDA
  });
}
