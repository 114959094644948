import {assertNever} from '../../util/util';
import {add, distance, mult, Point, sub} from './point';

export type ScrollState = {
    offset: Point,
    scale: number,
    lastWheel?: Date,
    mouseDown?: {
        originalOffset: Point,
        originalMouseOffset: Point,
        reachedMinDistance: boolean
    }
    circle: Point
}

export const emptyScroll: ScrollState = {
    offset: {x: -10, y: -10},
    scale: 1,
    circle: {x: 0, y: 0},
};

export const update = (s: ScrollState, se: ScrollEvent): ScrollState => {
    switch (se.kind) {
        case 'Up':
            return {...s, mouseDown: undefined};

        case 'Down':
            return {
                ...s, mouseDown: {
                    originalOffset: s.offset,
                    originalMouseOffset: pointOf(se.event),
                    reachedMinDistance: false
                }
            }

        case 'Move':
            const current = pointOf(se.event);
            const inImageMousePoint = add(s.offset, mult(current, s.scale));

            if (s.mouseDown && (s.mouseDown.reachedMinDistance || distance(s.mouseDown.originalMouseOffset, current) > MIN_DISTANCE)) {
                return {
                    ...s,
                    offset: add(s.mouseDown.originalOffset, mult(sub(s.mouseDown.originalMouseOffset, current), s.scale)),
                    mouseDown: {
                        ...s.mouseDown,
                        reachedMinDistance: true
                    },
                    circle: inImageMousePoint
                };
            } else {
                return {...s, circle: inImageMousePoint};
            }
        case 'Leave': return {...s, mouseDown: undefined};
        case 'Wheel':
            se.event.preventDefault();

            const now = new Date();
            if (!s.lastWheel || (now.getTime() - s.lastWheel.getTime()) > 50) {
                const mouseOffset = pointOf(se.event);
                const newScale = Math.max(0.1, Math.min(10, s.scale * (1 + Math.sign(se.event.deltaY) / 5)));
                const newXOffset = s.offset.x + mouseOffset.x * s.scale - mouseOffset.x * newScale;
                const newYOffset = s.offset.y + mouseOffset.y * s.scale - mouseOffset.y * newScale;
                return {
                    ...s,
                    lastWheel: now,
                    offset: {x: newXOffset, y: newYOffset},
                    scale: newScale
                };
            } else {
                return {...s};
            }
        default: return assertNever(se);
    }
}

const pointOf = (m: MouseEvent): Point => ({
    x: m.offsetX,
    y: m.offsetY
});

const MIN_DISTANCE = 10;

export type ScrollEvent = {
    kind: "Move",
    event: MouseEvent
} | {
    kind: "Down",
    event: MouseEvent
} | {
    kind: "Up",
    event: MouseEvent
} | {
    kind: "Leave",
} | {
    kind: "Wheel",
    event: WheelEvent
};
