import React, {ReactElement} from 'react';
import {BEM, SingleBem} from '../../util/util';
import {Help} from '../icon/Icon';
import "./HoverHelp.scss";

export const HoverHelp = (props: Props): ReactElement => {
    return <div {...BEM("hover-help", ...(props.additionalBem || []))}>
        <Help title="" onClick={() => {}} />

        <div {...BEM(["hover-help", "text"])}>
            {props.children}
        </div>
    </div>
}

export interface Props {
    children: ReactElement[] | ReactElement | string;
    additionalBem?: SingleBem[];
}
