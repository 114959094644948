import { useApi } from "../../hooks/useApi"
import * as api from "../../backend/Api";
import { Bannered } from "../../common-components/bannered/Bannered";
import { Loader } from "../../common-components/loader/Loader";

export const PruneNonBought = () => {
  const [res, runner] = useApi(api.pruneNonBought);

  const state: "error" | "success" | undefined =
    res === "loading" || res === "not-yet-started" ? undefined :
      res.kind === "AlwaysError" ? "error" : "success";

  return <Bannered
    name="Prune non Bought"
    topRight={[<div onClick={runner}>run️</div>]}
    state={state}>
    {
      res === "not-yet-started" ? null :
        res === "loading" ? <Loader input={res} /> :
          res.kind == "Ok" ? "Success" : "Error"

    }
  </Bannered>
}
