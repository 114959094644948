import React, {ReactElement, useState} from 'react';
import {classNames} from "../util/util";
import './tabbed.scss';

export const Tabbed = (props: Props): ReactElement => {
    const [selected, select] = useState<string | undefined>(props.tabs[0]?.name)
    const index = props.tabs.findIndex(t => t.name === selected)

    return <div className="component-tabbed">
        <div className="head">
            {
                props.tabs.map((tab, i) =>
                    <div onClick={() => select(tab.name)} key={tab.name} className={classNames({
                        name: true,
                        active: i === index,
                        left: i < index,
                        right: i > index
                    })}>
                        {tab.name}
                    </div>
                )
            }
        </div>
        {
            props.tabs.filter(t => t.name === selected).map(t => t.child)[0]
        }
    </div>
}

export interface Props {
    tabs: {
        name: string;
        child: ReactElement;
    }[];
}