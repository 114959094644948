import { assertNever } from './util';

export type Bound = {
	boundingBox: BoundingBox;
}

export enum BoundingBoxPart {
	UPPER = "UPPER",
	RIGHT = "RIGHT",
	LOWER = "LOWER",
	LEFT  = "LEFT"
};

export type BoundingBox = {
	[key in BoundingBoxPart]: number
}

export class KdTree<A extends Bound> {
	constructor(init: A[], dimension: BoundingBoxPart = BoundingBoxPart.RIGHT) {
		this.dimension = dimension;
		if (init.length > 0) {
			const ordered = init.slice().sort((a, b) => a.boundingBox[dimension] - b.boundingBox[dimension]);
			const medianValue = ordered[Math.floor(ordered.length / 2)].boundingBox[dimension];
			let lastMedianIndex = ordered.findIndex(b => b.boundingBox[dimension] > medianValue);
			lastMedianIndex = lastMedianIndex === -1 ? ordered.length - 1 : lastMedianIndex - 1;
			this.median    = ordered[lastMedianIndex];
			this.lessEqual = lastMedianIndex > 0 ? new KdTree(ordered.slice(0, lastMedianIndex), nextDimension(dimension)) : undefined;
			this.greater   = lastMedianIndex < ordered.length - 1 ? new KdTree(ordered.slice(lastMedianIndex + 1, ordered.length), nextDimension(dimension)): undefined;
		}
	}

	private readonly dimension: BoundingBoxPart;
	private readonly lessEqual?: KdTree<A>;
	private readonly median?:    A;
	private readonly greater?:   KdTree<A>;

	lookup = (x: number, y: number): A[] => this.lookupIntern(x, y, [])

	private lookupIntern = (x: number, y: number, arr: A[] = []): A[] => {
		if (!this.median) return arr;

		let takeLessEqual = false;
		let takeGreater   = false;

		switch (this.dimension) {
			case BoundingBoxPart.LEFT:
				takeLessEqual = true;
				takeGreater = this.median.boundingBox[this.dimension] < x;
				break;
			case BoundingBoxPart.RIGHT:
				takeLessEqual = this.median.boundingBox[this.dimension] > x;
				takeGreater = true;
				break;
			case BoundingBoxPart.UPPER:
				takeLessEqual = true;
				takeGreater = this.median.boundingBox[this.dimension] < y;
				break;
			case BoundingBoxPart.LOWER:
				takeLessEqual = this.median.boundingBox[this.dimension] > y;
				takeGreater = true;
				break;
		}

		if (takeLessEqual && this.lessEqual) this.lessEqual.lookupIntern(x, y, arr);
		if (this.median && isWithin(x, y, this.median)) arr.push(this.median);
		if (takeGreater && this.greater) this.greater.lookupIntern(x, y, arr);
		return arr;

	}
}

const nextDimension = (bbp: BoundingBoxPart): BoundingBoxPart  => {
	switch (bbp) {
		case BoundingBoxPart.UPPER : return BoundingBoxPart.RIGHT;
		case BoundingBoxPart.RIGHT:  return BoundingBoxPart.LOWER;
		case BoundingBoxPart.LOWER:  return BoundingBoxPart.LEFT;
		case BoundingBoxPart.LEFT :  return BoundingBoxPart.UPPER;
		default: return assertNever(bbp);
	}
}

const isWithin = (x: number, y: number, bp: Bound): boolean =>
	x >= bp.boundingBox.LEFT && x <= bp.boundingBox.RIGHT && y >= bp.boundingBox.UPPER && y <= bp.boundingBox.LOWER
