import React, {Fragment} from 'react';

import './health-check.scss'
import {Bannered} from "../../common-components/bannered/Bannered";
import {Loader} from "../../common-components/loader/Loader";
import {useApi} from "../../hooks/useApi";
import * as api from "../../backend/Api";

export const HealthCheck = () => {
    const [res, runner] = useApi(api.doHealthCheck);

    const state: "error" | "success" | undefined =
        res === "loading" || res === "not-yet-started" ? undefined :
            res.kind === "AlwaysError" ? "error" :
                res.data.htmlParseErrors.length > 0 ? "error" : "success";

    return <Bannered
        name="Health Check"
        topRight={[<div onClick={runner}>run️</div>]}
        state={state}>
        {
            res === "not-yet-started" ? null :
                res === "loading" || res.kind === "AlwaysError" ?
                    <Loader input={res} /> :
                    res.data.htmlParseErrors.length === 0 && res.data.unknownCourt === null ?
                        <div className="component-health-check success">Everything seems to be in order.</div>
                        :
                        <div className="component-health-check failed">
                            {
                                res.data.unknownCourt !== null ? <><div>Unknown Court</div><div>"{res.data.unknownCourt}"</div></> : null
                            }
                            {
                                res.data.htmlParseErrors.map(e =>
                                    <Fragment key={JSON.stringify(e.document)}>
                                        <div>{e.document}</div>
                                        <div>{e.reason}</div>
                                    </Fragment>
                                )
                            }
                        </div>
        }
    </Bannered>
}
