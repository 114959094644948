import React, {ReactElement, useEffect} from "react";
import * as T from "../../backend/types";
import * as api from "../../backend/Api";
import "./Schema.scss";
import {assertNever, classNames} from "../../util/util";
import {
  setFixedError,
  setFixedErrorMessage,
} from "../../common-components/fixed-error/FixedError";
import {useApi} from "../../hooks/useApi";
import {Loader} from "../../common-components/loader/Loader";
import "./SchemaList.scss";

export const SchemaList = (props: Props): ReactElement => {
  const [schemaList, runner] = useApi(api.listSchemas);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(runner, [props.selected]);

  const retrieveSchema = (id: string): void => {
    api.getSchema(id).then((response) => {
      switch (response.kind) {
        case "AlwaysError":
          setFixedError(response);
          break;
        case "NotFound":
          setFixedErrorMessage("Das Schema konnte nicht gefunden werden");
          break;
        case "GotData":
          props.onSchemaLoaded(response.data);
          break;
        default:
          assertNever(response);
      }
    });
  };

  const makeList = (schemas: T.SchemaOverview[]): ReactElement => {
    const sorted = [...schemas];
    sorted.sort((a, b) => a.name.localeCompare(b.name));

    if (sorted.length === 0) {
      return <span>Noch keine Schemas vorhanden</span>;
    } else {
      return (
        <div className="list">
          {sorted.map((s) => (
            <div
              key={s.id}
              onClick={() => retrieveSchema(s.id)}
              className={classNames({
                entry: true,
                selected: s.id === props.selected,
              })}
            >
              {s.name === "" ? "[Name ist leer]" : s.name}
            </div>
          ))}
        </div>
      );
    }
  };

  const content =
    schemaList === "not-yet-started" ? null : schemaList === "loading" ||
      schemaList.kind === "AlwaysError" ? (
        <Loader input={schemaList} />
      ) : schemaList.kind === "GotData" ? (
        makeList(schemaList.data)
      ) : (
          assertNever(schemaList)
        );

  return <div className="component-schema-list">{content}</div>;
};

export interface Props {
  selected?: string;
  onSchemaLoaded: (s: T.Schema) => void;
}
