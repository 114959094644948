import React, {ReactElement} from "react";

import * as T from "../../backend/types";
import {BEM} from "../../util/util";
import {Matcher} from "../../common-components/matcher/Matcher";

export const SchemaScorer = (props: {
  scorer: T.SchemaScorer;
  setter: (s: null | T.SchemaScorer) => void;
  context: Context;
}): ReactElement => {
  const scorer = props.scorer;
  const matcher: T.PreMatcher = scorer.matcher;
  const updateMatcher = (m: null | T.PreMatcher): void => {
    if (m === null) props.setter(null);
    else props.setter({...scorer, matcher: m});
  };
  const setScore = (n: number): void => {
    props.setter({...scorer, score: n});
  };

  const setState = (s: T.AnnotationState): void => {
    props.setter({...scorer, state: s});
  };

  return (
    <div className="scorer-top">
      <div className="scorer-content">
        <Matcher setter={updateMatcher} matcher={matcher} context={props.context} />
      </div>
      <div className={"scorer-score"}>
        <select
          {...BEM('select')}
          onChange={ev => setState(ev.target.value as T.AnnotationState)}
          value={scorer.state}
        >
          <option value="Yes">Ja</option>
          <option value="PartialYes">Partiell Ja</option>
          <option value="No">Nein</option>
          <option value="Unsubstantiated">Unsubstantiiert</option>
          <option value="Neutral">Neutral</option>
        </select>
        <input
          {...BEM("default-input")}
          type="number"
          value={scorer.score === 0 ? "" : scorer.score}
          onChange={(e) => setScore(+e.target.value)}
        />
      </div>
    </div>
  );
};

interface Context {
  schemas: T.Schema[];
}
