import React, {ReactElement, useEffect, useRef} from "react";
import {assertThere} from "../../util/util";
import {Letter} from "./letter";
import {DocumentConfig} from "./document-config";

export const TextCanvas = (props: Props): ReactElement => {
    const canvasRef = useRef<HTMLCanvasElement>();

    useEffect(() => {
        const canvas = assertThere(canvasRef.current, "textCanvas(when drawing)");
        const context = assertThere(canvas.getContext('2d'), "context(when drawing text canvas)");

        canvas.height = props.letters.length > 0 ?
            props.letters[props.letters.length - 1].boundingBox.LOWER :
            0;
        context.clearRect(0, 0, canvas.width, canvas.height);

        context.save();
        context.fillStyle = "black";

        for (let letter of props.letters) {
            context.font = letter.font;
            context.fillText(letter.letter, letter.boundingBox.LEFT, letter.boundingBox.LOWER);
        }

        context.restore();
    }, [props.letters, props.config]);

    return <canvas width={props.config.width} height={400} ref={(ce) => canvasRef.current = ce!}/>;
}

export interface Props {
    letters: Letter[];
    config: DocumentConfig;
}
