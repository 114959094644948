import './StoredSearchSearch.scss';
import React, {ReactElement} from 'react';
import {SearchTree} from "../search/Search";
import * as T from "../../backend/types";
import * as S from './state';

export const StoredSearchSearch = (props: Props): ReactElement => {
    const selected = props.state.selected === undefined ? undefined : props.state.searches[props.state.selected];

    if (!selected) {
        return <div>
            Bitte wählen Sie zunächst eine gespeicherte Suche.
        </div>;
    }

    return <SearchTree context={({schemas: props.schemas})} search={selected.search} setter={(s: T.Search<T.PreMatcher>) => props.setState(S.updateSelected(ss => ({...ss, search: s})))} />
}

interface Props {
    state: S.State;
    setState: (s: (S.State | ((s: S.State) => S.State))) => void;
    schemas: T.Schema[];
}
