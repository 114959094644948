import * as T from '../../backend/types';
import {Lens} from 'monocle-ts/es6/index';
import {indexArray} from 'monocle-ts/es6/Index/Array';
import {Letter} from '../document/letter';
import {UnassociatedAnnotation} from '../document/model/annotation';

export interface State {
    searches: T.StoredSearch[];
    selected?: number;
    testResult?: {
        text: string;
        letters: Letter[];
        spans: UnassociatedAnnotation[];
    }
}

export const setSelected = (i: number) => (s: State): State =>
    ({...s, selected: i, testResult: undefined})

export const updateSelected = (f: (s: T.StoredSearch) => T.StoredSearch) => (s: State): State => {
    if (s.selected !== undefined) {
        return Lens.fromProp<State>()('searches').composeOptional(
            indexArray<T.StoredSearch>().index(s.selected)
        ).modify(f)(s)
    } else {
        return s;
    }
}
