import React, {ReactElement, useMemo, Fragment} from "react";
import * as State from "./state";
import * as T from "../../backend/types";
import * as Icon from "../../common-components/icon/Icon";
import './Annotations.scss';
import {UnassociatedAnnotation} from "./model/annotation";
import {BEM} from "../../util/util";

export const Annotations = (props: Props): ReactElement => {
    const initalArray = State.getAnnotationsOfCurrentSchema(props.state);
    const annotations = useMemo(() => {return initalArray.slice().sort((a, b) => a.span.startInc - b.span.endEx)}, [initalArray]);
    const schema = props.state.schemaSelection ? props.state.document.expandedSchemas[props.state.schemaSelection.id] : undefined;
    const nodeOfId = useMemo(() => schema ? buildNodeIdToNodeMap(schema.schema.root) : {}, [schema]);

    return <div {...BEM("document-annotations")}>
        <div {...BEM(["document-annotations", "header"])}>
            <input type="checkbox" checked={props.state.showAutomaticAnnotations} onChange={ev => props.dispatch(
                {
                    kind: "SetShowAutomaticAnnotationsEvent",
                    newValue: ev.target.checked
                })} />
            <span>Automatische Annotationen</span>
            <button
                onClick={() => props.dispatch({kind: 'DeleteAllAnnotationsEvent'})}
            >Alle Löschen</button>
        </div>
        {
            annotations.map(a => <Annotation key={a.id} {...props} current={a} nodeOfId={nodeOfId} />)
        }
    </div>;
}

export const Annotation = (props: AnnotationProps): ReactElement => {
    const a = props.current;
    return <Fragment>

        <div {...BEM(["document-annotations", "annotation", {

            selected: props.state.selectedAnnotation?.annotation === a.id,
            hovered: props.state.hoveredAnnotation === a.id
        }])}
            key={a.id}
        >
            {/* eslint-disable-next-line no-sparse-arrays*/}
            <div {...BEM(["document-annotation-comment", , {visible: !!(props.state.selectedAnnotation?.showComment) && props.state.selectedAnnotation.annotation === a.id}])}>
                {/* eslint-disable-next-line no-sparse-arrays*/}
                <div {...BEM(["document-annotation-comment", , {visible: !!(props.state.selectedAnnotation?.showComment) && props.state.selectedAnnotation.annotation === a.id}])}>
                    <textarea {...BEM(["document-annotation-comment", "text"])} onChange={(ev) => props.dispatch({kind: "SetAnnotationCommentEvent", annotationId: a.id, comment: ev.target.value})} value={a.comment}></textarea>
                    <div {...BEM(["document-annotation-comment", "ok"], ["button"])} onClick={() => props.dispatch({kind: "SetAnnotationCommentVisibilityEvent", visible: false})}>Ok</div>
                </div>
            </div>
            <div {...BEM(["document-annotations", "name"])}
                onClick={() => props.onAnnotationFocusRequested(a)}
            >{props.nodeOfId[a.schemaNode].name}</div>
            <div {...BEM(["document-annotations", "group"])}>
                {a.groupnumber === undefined || a.groupnumber === null ? "-" : a.groupnumber}
            </div>
            <div {...BEM(["document-annotations", "comment"])}>
                <Icon.Comment
                    onClick={() => {
                        props.onAnnotationFocusRequested(a);
                        props.dispatch({kind: "SetAnnotationCommentVisibilityEvent", visible: true});
                    }}
                    title="Kommentar"
                    highlight={!!a.comment}
                />
            </div>
            <div {...BEM([
                "document-annotations",
                "state",
                {
                    "yes": a.state === "Yes",
                    "partial": a.state === "PartialYes",
                    "no": a.state === "No",
                    "unsubstantiated": a.state === "Unsubstantiated",
                    "neutral": a.state === "Neutral",
                }
            ])}
                title={stateTranslation[a.state]}
            >{
                    stateAbbreviation[a.state]
                }</div>
            <div {...BEM(["document-annotations", "auto"])}>
                {a.auto ? "A" : "M"}
            </div>
        </div>
    </Fragment>;
}

export const stateAbbreviation: {[key in T.AnnotationState]: string} = {
    "Yes": "J",
    "PartialYes": "P",
    "No": "N",
    "Neutral": "E",
    "Unsubstantiated": "U"
}

export const stateTranslation: {[key in T.AnnotationState]: string} = {
    "Yes": "Ja",
    "PartialYes": "Partiell",
    "No": "Nein",
    "Neutral": "Neutral",
    "Unsubstantiated": "Unsubstantiiert"
}

const buildNodeIdToNodeMap = (s: T.SchemaNode): {[id: string]: T.SchemaNode} => {
    const subs = s.subs.reduce((obj, node) => ({...obj, ...buildNodeIdToNodeMap(node)}), {});
    return {
        ...subs,
        [s.id]: s
    };
}

export interface Props {
    state: State.State;
    dispatch: (se: State.StateEvent) => void;
    onAnnotationFocusRequested: (a: UnassociatedAnnotation) => void;
}

interface AnnotationProps extends Props {
    current: T.Annotation;
    nodeOfId: {[id: string]: T.SchemaNode};
}
