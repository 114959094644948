import React, {ReactElement, MouseEvent} from "react";
import {SingleBem, BEM} from "../../util/util";
import "./Icon.scss";

const icon = (materialIconName: string) => (props: Props): ReactElement => {
  const classes = BEM(
    "material-icons",
    // eslint-disable-next-line no-sparse-arrays
    ["icon", , {disabled: !!props.disabled, highlight: !!props.highlight}],
    ...(props.additionalBem || [])
  );

  return (
    <i
      title={props.title}
      {...classes}
      onClick={(e) => !props.disabled && props.onClick(e)}
    >
      {materialIconName}
    </i>
  );
};

export const Save = icon("save");
export const Delete = icon("delete");
export const Duplicate = icon("content_copy");
export const Help = icon("help");
export const Comment = icon("comment");
export const Annotate = icon("low_priority");
export const GetOutcome = icon("star");
export const GetFactcheck = icon("check");
export const PopulateTestGroups = icon("group");
export const Link = icon("link");
export const Fullscreen = icon("fullscreen");

export type Props = {
  onClick: (e: MouseEvent) => void;
  disabled?: boolean;
  additionalBem?: SingleBem[];
  highlight?: boolean;
  title: string;
};
