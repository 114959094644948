import * as T from "./types";
import { Schema } from "../routes/document/model/schema";

export type AlwaysResponses =
  | T.UnknownError
  | T.Timeout
  | T.ServerShuttingDown
  | T.InvalidCredentials
  | T.InvalidInput;

export interface AlwaysError {
  kind: "AlwaysError";
  cause: AlwaysResponses | { kind: "NetworkProblem" };
}

export const searchForDocuments = (
  phrase: string
): Promise<AlwaysError | T.SearchForDocumentsResponse> =>
  post("searchForDocuments", phrase);
export const getDocument = (
  id: string
): Promise<AlwaysError | T.GetDocumentResponse> => post("getDocument", id);
export const doHealthCheck = (): Promise<
  AlwaysError | T.DoHealthCheckResponse
> => post("doHealthCheck", {});
export const getLaredaSearchOverview = (
  request: T.LaredaSearchRequest
): Promise<AlwaysError | T.LaredaSearchActionResponse> =>
  post("laredaSearch", request);
export const getLaredaCrawlerOverview = (
  request: T.GetLaredaCrawlerOverviewRequest
): Promise<AlwaysError | T.GetLaredaCrawlerOverviewResponse> =>
  post("getLaredaCrawlerOverview", request);
export const setConfigOption = (
  request: T.SetConfigOptionRequest
): Promise<AlwaysError | T.SetConfigOptionResponse> =>
  post("setConfigOption", request);
export const listConfigOptions = (): Promise<
  AlwaysError | T.ListConfigOptionsResponse
> => post("listConfigOptions", {});
export const startSearch = (
  search: T.Search<T.PreMatcher>
): Promise<AlwaysError | T.StartSearchResponse> => post("startSearch", search);
export const getSearch = (
  range: T.PagingRange
): Promise<AlwaysError | T.GetSearchResponse> => post("getSearch", range);
export const listSchemas = (): Promise<AlwaysError | T.ListSchemasResponse> =>
  post("listSchemas", {});
export const saveSchema = (
  s: Schema
): Promise<AlwaysError | T.SaveSchemaResponse> => post("saveSchema", s);
export const getSchema = (
  id: String
): Promise<AlwaysError | T.GetSchemaResponse> => post("getSchema", id);
export const deleteSchema = (
  id: String
): Promise<AlwaysError | T.DeleteSchemaResponse> => post("deleteSchema", id);
export const saveDocument = (
  doc: T.ExpandedDocument
): Promise<AlwaysError | T.SaveDocumentResponse> => post("saveDocument", doc);
export const annotateDocument = (
  req: T.AnnotateRequest
): Promise<AlwaysError | T.AnnotateDocumentResponse> => post("annotateDocument", req);
export const testSearch = (
  req: T.TestSearchRequest
): Promise<AlwaysError | T.TestSearchResponse> => post("testSearch", req);
export const listStoredSearches = (
): Promise<AlwaysError | T.ListStoredSearchesResponse> => post("listStoredSearches", {});
export const saveStoredSearch = (
  req: T.StoredSearch
): Promise<AlwaysError | T.SaveStoredSeachResponse> => post("saveStoredSearch", req);
export const deleteStoredSearch = (
  req: string
): Promise<AlwaysError | T.DeleteStoredSeachResponse> => post("deleteStoredSearch", req);
export const updateDefaultStoredSearches = (
): Promise<AlwaysError | T.UpdateDefaultStoredSearchesResponse> => post("updateDefaultStoredSearches", {});
export const luceneQueryTest = (
  query: string
): Promise<AlwaysError | T.LuceneQueryTestResponse> => post("luceneQueryTest", query);
export const analyseOverlap = (
  req: T.AnalyseOverlapRequest
): Promise<AlwaysError | T.AnalyseOverlapResponse> => post("analyseOverlap", req);
export const listBills = (
): Promise<AlwaysError | T.ListBillsResponse> => post("listBills", {});
export const saveBill = (
  bill: T.Bill<string>
): Promise<AlwaysError | T.SaveBillResponse> => post("saveBill", bill);
export const getBill = (
  bill: string
): Promise<AlwaysError | T.GetBillResponse> => post("getBill", bill);
export const listUnbilledDocuments = (
): Promise<AlwaysError | T.ListUnbilledDocumentsResponse> => post("listUnbilledDocuments", {});
export const renderBill = (
  bill: string
): Promise<AlwaysError | T.RenderBillResponse> => post("renderBill", bill);
export const updateCourtMetadata = (
): Promise<AlwaysError | T.UpdateCourtMetadataResponse> => post("updateCourtMetadata", {});
export const startAnalysis = (
  request: T.AnalysisRequest
): Promise<AlwaysError | T.StartAnalysisResponse> => post("startAnalysis", request);
export const getAnalysis = (
  request: T.GetAnalysisRequest
): Promise<AlwaysError | T.GetAnalysisResponse> => post("getAnalysis", request);
export const getAnalysisExcel = (): Promise<AlwaysError | T.GetAnalysisExcelResponse> => post("getAnalysisExcel", {});
export const getSynonyms = (
): Promise<AlwaysError | T.GetSynonymsResponse> => post("getSynonyms", {});
export const setSynonyms = (
  synonyms: [string, string][]
): Promise<AlwaysError | T.SetSynonymsResponse> => post("setSynonyms", synonyms);
export const adjudicate = (
  request: T.AdjudicationRequest
): Promise<AlwaysError | T.NotFound | T.AdjudicateResponse> => post("adjudicate", request);
export const populateTestGroups = (
  schemaId: string
): Promise<AlwaysError | T.NotFound | T.PopulateTestGroupsResponse> => post("populateTestGroups", schemaId);
export const getOutcome = (
  request: T.GetOutcomeRequest,
): Promise<AlwaysError | T.GetOutcomeResponse> => post("getOutcome", request);
export const normalizeText = (
  text: string,
): Promise<AlwaysError | T.NormalizeTextResponse> => post("normalizeText", text);
export const getFactcheck = (
  request: T.GetFactcheckRequest,
): Promise<AlwaysError | T.GetFactcheckResponse> => post("getFactcheck", request);
export const testMatcher = (
  request: T.TestMatcherRequest,
): Promise<AlwaysError | T.TestMatcherResponse> => post("testMatcher", request);
export const pruneNonBought = (): Promise<AlwaysError | T.PruneNonBoughtResponse> => post("pruneNonBought", {});
export const uploadDocument = (req: T.UploadDocumentRequest): Promise<AlwaysError | T.UploadDocumentResponse> => post("uploadDocument", req);


export async function checkLogin(
  userName: string,
  password: string
): Promise<AlwaysError | T.LoginResponse> {
  const c: Credentials = { userName, password };
  localStorage.setItem("janator.credentials", JSON.stringify(c));
  const request: T.LoginRequest = c;
  return post<T.Ok>("login", request);
}

async function post<T extends { kind: string }>(
  action: string,
  body: any
): Promise<T | AlwaysError> {
  const storedCredentials = localStorage.getItem("janator.credentials");
  let credentials: Credentials | undefined;
  if (storedCredentials) {
    try {
      credentials = JSON.parse(storedCredentials);
    } catch (e) {
      console.log(e);
    }
  }
  if (!credentials) {
    localStorage.removeItem("janator.credentials");
    const error: AlwaysError = {
      kind: "AlwaysError",
      cause: { kind: "InvalidCredentials" },
    };
    return error;
  }

  try {
    const response = await fetch(apiBase + action, {
      method: "POST",
      body: JSON.stringify(body),
      headers: { ...credentials },
    });
    const decoded: T | AlwaysResponses = await response.json();
    if (decoded.kind === "InvalidCredentials") {
      localStorage.removeItem("janator.credentials");
    }

    if (
      decoded.kind === "UnknownError" ||
      decoded.kind === "Timeout" ||
      decoded.kind === "ServerShuttingDown" ||
      decoded.kind === "InvalidCredentials" ||
      decoded.kind === "InvalidInput"
    ) {
      const error: AlwaysError = {
        kind: "AlwaysError",
        cause: decoded as AlwaysResponses,
      };
      return error;
    } else {
      return decoded as T;
    }
  } catch (e) {
    console.log(e);
    const error: AlwaysError = {
      kind: "AlwaysError",
      cause: { kind: "NetworkProblem" },
    };
    return error;
  }
}

interface Credentials {
  userName: string;
  password: string;
}


const getApiBase = () => {
  if (process.env.REACT_APP_API_URL && window.location.protocol.toLowerCase().indexOf("https") !== -1) {
    return process.env.REACT_APP_API_URL.toLowerCase().replace(/http/, "https");
  } else if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  } else {
    return [window.location.protocol, "//", window.location.hostname, ":8080/"].join("");
  }
}

const apiBase = process.env.REACT_APP_API_URL || [window.location.protocol, "//", window.location.hostname, ":8080/"].join("");
