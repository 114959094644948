import React, { ReactElement } from "react";
import "./loader.scss";

import _ from "lodash";

import * as api from "../../backend/Api";
import { ShowError } from "../show-error/ShowError";

export const Loader = (props: Props): ReactElement => {
  if (props.input === "loading") {
    return (
      <div className="component-loader-loading">
        <Spinner />
      </div>
    );
  } else if ("kind" in props.input) {
    return (
      <div className="component-loader-error">
        <ShowError error={props.input} />
      </div>
    );
  } else {
    return <div className="component-loader-error">{props.input}</div>;
  }
};

export const Spinner = (): ReactElement => {
  return (
    <svg viewBox="-50 -50 100 100">
      {_.range(0, circles).map((i) => {
        const deg = ((2.0 * Math.PI) / circles) * -i;
        return (
          <circle
            key={i}
            cx={Math.cos(deg) * 40}
            cy={Math.sin(deg) * 40}
            r={100 / circles}
          />
        );
      })}
      <text textAnchor="middle">Loading</text>
    </svg>
  );
};

const circles = 20;

export interface Props {
  input: "loading" | api.AlwaysError | ReactElement;
}
