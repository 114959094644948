import {useCallback, useEffect, useRef, useState} from "react";

export const useApi = <T>(f: () => Promise<T>): [ApiRequest<T>, () => void] => {
    const [state, setState] = useState<ApiRequest<T>>("not-yet-started");
    const cancelLast = useRef<undefined | (() => void)>(undefined);

    const runner = useCallback(() => {
        if (cancelLast.current) {
            cancelLast.current();
        }

        let canceled = false;
        cancelLast.current = () => canceled = true;

        setState("loading");
        f().then((response) => {
            if (!canceled) {
                setState(response)
            }
        })

    }, [f]);

    return [state, runner];
}

export const useApi2 = <I, T>(f: (i: I) => Promise<T>, i: I | undefined | null): [ApiRequest<T>, () => void] => {
    const [state, setState] = useState<ApiRequest<T>>("not-yet-started");
    const cancelLast = useRef<undefined | (() => void)>(undefined);

    const runner = useCallback(() => {
        if (cancelLast.current) {
            cancelLast.current();
        }

        let canceled = false;
        cancelLast.current = () => canceled = true;

        if (i !== null && i !== undefined) {
            setState("loading");
            f(i).then((response) => {
                if (!canceled) {
                    setState(response)
                }
            })
        } else {
            setState("not-yet-started");
        }

    }, [f, i]);

    return [state, runner];
}


export const useApiAuto = <I, T>(f: (i: I) => Promise<T>, i: I | undefined | null): ApiRequest<T> => {
    const [state, setState] = useState<ApiRequest<T>>("not-yet-started");

    useEffect(() => {
        let canceled = false;

        if (i !== null && i !== undefined) {
            setState("loading");
            f(i).then(response => {
                if (!canceled) {
                    setState(response);
                }
            })
        } else {
            setState("not-yet-started");
        }

        return () => {
            canceled = true
        };
    }, [f, i]);

    return state;
}

export type ApiRequest<T> = "loading" | "not-yet-started" | T
