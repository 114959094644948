import React, {ReactElement} from "react";
import {assertNever, BEM} from "../../util/util";
import * as RT from './types';
import * as VM from './vm';
import * as P from './point';
import * as T from '../../backend/types';
import {Answer, askKeyedList, askOneIn} from "../../common-components/toast/Toast";
import {AnnotationState} from "../../backend/types";

export const RippleNode = (props: Props): ReactElement => {
    const start = startOfInPixel(props.node.location);

    const onMainBodyClick = () => {
        if (!props.node.node) {
            props.updater(props.node.path, RT.emptyRippleNode())
        }
    }

    const onDeleteClick = () => {
        props.updater(props.node.path, undefined);
    }

    return <g>
        <g
            {...BEM(["ripple", "node", {"new": !props.node.node}])}
            onClick={onMainBodyClick}
        >
            <rect
                x={start.x}
                y={start.y}
                width={VM.nodeWidth}
                height={VM.nodeHeight}
            />

            {createContent(props)}
        </g>
        <g onClick={onDeleteClick}>
            <rect
                {...BEM(["ripple", "node-delete-rect", {"new": !props.node.node}])}
                x={start.x + VM.nodeWidth - 0.5 * VM.deleteWidth}
                y={start.y - 0.5 * VM.deleteWidth}
                width={VM.deleteWidth}
                height={VM.deleteWidth}
            />
            <line
                {...BEM(["ripple", "node-delete-line", {"new": !props.node.node}])}
                x1={start.x + VM.nodeWidth - 0.5 * VM.deleteWidth}
                x2={start.x + VM.nodeWidth + 0.5 * VM.deleteWidth}
                y1={start.y - 0.5 * VM.deleteWidth}
                y2={start.y + 0.5 * VM.deleteWidth}
            />

            <line
                {...BEM(["ripple", "node-delete-line", {"new": !props.node.node}])}
                x1={start.x + VM.nodeWidth - 0.5 * VM.deleteWidth}
                x2={start.x + VM.nodeWidth + 0.5 * VM.deleteWidth}
                y1={start.y + 0.5 * VM.deleteWidth}
                y2={start.y - 0.5 * VM.deleteWidth}
            />


        </g>
        {makeArrow(props.node)}
    </g>;
}

const startOfInPixel = (point: P.Point): P.Point => {
    const x = point.x * VM.nodeWidth + point.x * VM.gap;
    const y = point.y * VM.nodeHeight + point.y * VM.gap;
    return {
        x, y
    };

}

const makeArrow = (node: VM.RippleNode): ReactElement => {
    if (!node.parent) {
        return <g></g>;
    }
    else {
        const startNodeUpperLeft = startOfInPixel(node.parent);
        const currentNodeUpperLeft = startOfInPixel(node.location);

        const elseArrow = node.location.x == node.parent.x;

        const arrowStart = P.addY(
            P.addX(startNodeUpperLeft, elseArrow ? VM.nodeWidth / 2 : VM.nodeWidth),
            elseArrow ? VM.nodeHeight : VM.nodeHeight / 2);

        const arrowEnd = P.addY(
            P.addX(currentNodeUpperLeft, elseArrow ? VM.nodeWidth / 2 : 0),
            elseArrow ? 0 : VM.nodeHeight / 2);

        const middleX = (arrowEnd.x + arrowStart.x) / 2;
        const middleY = (arrowEnd.y + arrowStart.y) / 2;
        const name = elseArrow ? "Else" : "Except";
        const rotate = elseArrow ? 90 : 0;
        const textOffsetX = elseArrow ? 2 : 0;
        const textOffsetY = elseArrow ? 0 : -2;

        return <g
            {...BEM(["ripple", "edge", {"new": !node.node}])}
        >
            <line
                x1={arrowStart.x}
                y1={arrowStart.y}
                x2={arrowEnd.x}
                y2={arrowEnd.y}
            />

            <text
                transform={"translate(" + (middleX + textOffsetX) + "," + (middleY + textOffsetY) + ") rotate(" + rotate + ")"}
                x={0}
                y={0}
                textAnchor="middle"
            >{name}</text>;
        </g>;
    }
}

const createContent = (props: Props): ReactElement => {
    const start = startOfInPixel(props.node.location);

    if (!props.node.node) {
        return addCrossAt({x: start.x + VM.nodeWidth / 2, y: start.y + VM.nodeHeight / 2});
    } else {
        const n = props.node.node;
        const toggleAction = () => props.updater(props.node.path, {...n, action: RT.cycleAction(n.action)});
        const mod = {
            "yes": props.node.node.action === "Yes",
            "no": props.node.node.action === "No",
            "partial": props.node.node.action === "PartialYes",
        };

        const onTextClick = () => {
            askForNewCheck(props.schemaInfo).then(condition =>
                props.updater(props.node.path, {...n, condition})
            )
        };

        return <g>
            {!n.condition ?
                <text
                    onClick={onTextClick}
                    x={start.x + VM.nodeWidth / 2}
                    y={start.y + VM.nodeHeight / 2}
                    textAnchor="middle"
                >add condition</text>
                : <Condition schemaInfo={props.schemaInfo} setCondition={c => props.updater(props.node.path, {...n, condition: c})} start={start} c={n.condition} />}
            <g onClick={() => toggleAction()}>
                <rect
                    x={start.x}
                    y={start.y + VM.nodeHeight - VM.thenHeight}
                    width={VM.nodeWidth}
                    height={VM.thenHeight}
                    {...BEM(['ripple', 'then-line-rect', mod])}
                />

                <text
                    {...BEM(['ripple', 'then-line-text', mod])}
                    x={start.x + VM.nodeWidth / 2}
                    y={start.y + VM.nodeHeight - VM.thenHeight / 2 + VM.textHeight / 2}
                    textAnchor="middle"
                >
                    {props.node.node.action}
                </text>
            </g>
        </g>;
    }
}

const askForNewCheck = async (si: VM.SchemaInfo): Promise<T.RippleCheck> => {
    const subject = await askKeyedList("Für welchen Knoten soll eine Bedingung formuliert werden.", si.possiblesAnswers);
    const values: Answer<AnnotationState>[] = [
        {message: "Yes", payload: "Yes"},
        {message: "No", payload: "No"},
        {message: "PartialYes", payload: "PartialYes"}
    ];
    const value = await askKeyedList("Welchen Wert soll der Knoten haben?", values);

    return {kind: "RippleCheck", nodeId: subject, value};
}

const askForConditionUpdate = async (si: VM.SchemaInfo): Promise<UpdateConditionEvent> => {
    type WhatToDo = {[key in UpdateConditionEvent['kind']]: string};
    const whatToDo: WhatToDo = {
        Delete: "Diesen Teil löschen",
        OrWith: "Diesen Teil mit einem neuen Check verodern",
        AndWith: "Diesen Teil mit einem neuen Check verunden",
        LeaveUnchanged: "Nichts tun",
    };

    const value = await askOneIn("Was wollen Sie tun?", whatToDo);

    switch (value) {
        case 'LeaveUnchanged': return {kind: 'LeaveUnchanged'};
        case 'Delete': return {kind: 'Delete'};
        case 'OrWith':
            const orCheck = await askForNewCheck(si);
            return {kind: 'OrWith', newCondition: orCheck};
        case 'AndWith':
            const andCheck = await askForNewCheck(si);
            return {kind: 'AndWith', newCondition: andCheck};
        default: return assertNever(value);
    }
}

type UpdateConditionEvent = {
    kind: "Delete";
} | {
    kind: "OrWith";
    newCondition: T.RippleCondition;
} | {
    kind: "AndWith";
    newCondition: T.RippleCondition;
} | {
    kind: "LeaveUnchanged";
}

const addCrossAt = (center: P.Point): ReactElement => {
    const length = Math.min(VM.nodeHeight, VM.nodeWidth) / 2;
    return <g>
        <line
            x1={center.x - length / 2}
            x2={center.x + length / 2}
            y1={center.y}
            y2={center.y}
            {...BEM(['ripple', 'add-cross-line'])}
        />

        <line
            x1={center.x}
            x2={center.x}
            y1={center.y - length / 2}
            y2={center.y + length / 2}
            {...BEM(['ripple', 'add-cross-line'])}
        />
    </g>;
}

type ConditionProps = {
    start: P.Point;
    schemaInfo: VM.SchemaInfo;
    setCondition: (c: T.RippleCondition | undefined) => void;
    c: T.RippleCondition;
}

const Condition = (cProps: ConditionProps): ReactElement => {
    const go = (c: T.RippleCondition, start: P.Point, path: number[]): ReactElement => {
        const onClick = () => {
            askForConditionUpdate(cProps.schemaInfo).then(ce => {
                switch (ce.kind) {
                    case 'LeaveUnchanged': break;
                    case 'Delete': cProps.setCondition(RT.updateCondition(cProps.c, path, undefined)); break;
                    case 'OrWith': cProps.setCondition(RT.updateCondition(cProps.c, path, {kind: "RippleOr", ored: [c, ce.newCondition]})); break;
                    case 'AndWith': cProps.setCondition(RT.updateCondition(cProps.c, path, {kind: "RippleAnd", anded: [c, ce.newCondition]})); break;
                    default: return assertNever(ce);
                }
            });
        };
        if ("anded" in c) {
            return <g>
                <text
                    onClick={onClick}
                    {...BEM(['ripple', 'condition-text'])}
                    y={start.y + VM.textHeight}
                    x={start.x}
                >AND</text>

                {
                    c.anded.map((c, i) => go(c, P.addX(P.addY(start, i * 1.5 * VM.textHeight), 40), path.concat([i])))
                }
            </g>
        } else if ("ored" in c) {
            return <g>
                <text
                    onClick={onClick}
                    {...BEM(['ripple', 'condition-text'])}
                    y={start.y + VM.textHeight}
                    x={start.x}
                >OR</text>
                {
                    c.ored.map((c, i) => go(c, P.addX(P.addY(start, i * 1.5 * VM.textHeight), 30), path.concat([i])))
                }
            </g>
        } else {
            const oneLetterValues: {[s in AnnotationState]: string} = {"Yes": "Y", "No": "N", "PartialYes": "P", "Neutral": "T", "Unsubstantiated": "U"};
            const name = (cProps.schemaInfo.nodes[c.nodeId] || {name: "undefined"}).name;
            const shortName = name.split(/\s*\(/)[0].substr(0, 10);

            return <text
                onClick={onClick}
                {...BEM(['ripple', 'condition-text'])}
                y={start.y + VM.textHeight}
                x={start.x}
            >{shortName} = {oneLetterValues[c.value]}</text>
        }
    }

    return <g clipPath="url(#condition-clip)" transform={"translate(" + cProps.start.x + ", " + cProps.start.y + ")"}>
        {go(cProps.c, {x: 0, y: 0}, [])}
    </g>
}

export type Props = {
    schemaInfo: VM.SchemaInfo;
    node: VM.RippleNode;
    updater: (path: RT.PathSegment[], newNode: RT.RippleNode | undefined) => void;
}
