import React, {ReactElement, useState, useEffect} from "react";
import {v4} from "uuid";
import {pipe} from 'fp-ts/lib/function';
import * as T from "../../backend/types";
import * as L from 'monocle-ts/lib/Lens';
import * as O from 'monocle-ts/lib/Optional';
import * as api from "../../backend/Api";
import "./Bill.scss";

import {assertNever, BEM, showBytes} from "../../util/util";
import {Bannered} from "../../common-components/bannered/Bannered";
import {
    setFixedError,
} from "../../common-components/fixed-error/FixedError";
import {Loader} from "../../common-components/loader/Loader";

export const Bill = (): ReactElement => {
    const [state, setState] = useState<State>({kind: "Loading"});

    const addToCurrent = (ds: T.DocumentMeta[]): void => {
        if (state.kind === "Loaded") {
            const es = entries.getOption(state);
            if (es._tag === "Some") {
                const added = entries.set(ds.concat(es.value))(state);
                setState({...added, toBill: state.toBill.filter(dd => !ds.find(toRemove => dd.id === toRemove.id))});
            }
        }
    }

    const removeFromCurrent = (d: T.DocumentMeta): void => {
        if (state.kind === "Loaded") {
            const es = entries.getOption(state);
            if (es._tag === "Some") {
                const removed = entries.set(es.value.filter(dd => dd.id !== d.id))(state);
                setState({...removed, toBill: [d].concat(state.toBill)});
            }
        }
    }

    const saveCurrent = (render?: boolean): void => {
        if (state.kind === "Loaded" && state.current) {
            const id = state.current.id;
            api.saveBill({...state.current, entries: state.current.entries.map(e => e.id)}).then(response => {
                switch (response.kind) {
                    case "AlwaysError":
                        setFixedError(response);
                        break;
                    case "Ok":
                        if (render) {
                            api.renderBill(id).then(response => {
                                switch (response.kind) {
                                    case "AlwaysError":
                                        setFixedError(response);
                                        break;
                                    case "NotFound":
                                        window.location.reload();
                                        break;
                                    case "GotData":
                                        showBytes(response.data, "bill.pdf");
                                        break;
                                    default:
                                        assertNever(response);
                                }
                            });
                        } else {
                            window.location.reload();
                        }
                        break;
                    default: assertNever(response);
                }
            });
        }
    }


    useEffect(() => {
        api.listBills().then(response => {
            switch (response.kind) {
                case "AlwaysError":
                    setFixedError(response);
                    break;
                case "GotData":
                    api.listUnbilledDocuments().then(innerResponse => {
                        switch (innerResponse.kind) {
                            case "AlwaysError":
                                setFixedError(innerResponse);
                                break;

                            case "GotData":
                                setState({
                                    kind: "Loaded",
                                    toBill: innerResponse.data,
                                    bills: response.data,
                                    current: undefined,
                                });
                                break;
                            default: assertNever(innerResponse);

                        }
                    });
                    break;
                default: assertNever(response);
            }
        })
    }, []);

    const createNew = () => {
        api.listUnbilledDocuments().then(response => {
            switch (response.kind) {
                case "AlwaysError":
                    setFixedError(response);
                    break;

                case "GotData":
                    if (state.kind === "Loaded") {
                        setState({
                            ...state,
                            current: {
                                name: "Name",
                                id: v4(),
                                entries: [],
                                description: "Beschreibung"
                            }
                        })
                    }
                    break;
                default: assertNever(response);

            }
        });
    }

    const openBill = (id: string): void => {
        api.getBill(id).then(response => {
            switch (response.kind) {
                case "AlwaysError":
                    setFixedError(response);
                    break;

                case "NotFound":
                    window.location.reload();
                    break;

                case "GotData":
                    if (state.kind === "Loaded") {
                        setState({...state, current: response.data});
                    }
                    break;
                default: assertNever(response);
            }
        });
    }

    return <div {...BEM("default-layout")}>
        <div {...BEM(["default-layout", "left"])}>
            <Bannered name="Rechnungen">
                {state.kind === "Loading" ?
                    <Loader input={<div></div>} />
                    :
                    <div>
                        {
                            state.bills.map(b => <div {...BEM(["bills", "bill-in-overview"])} onClick={() => openBill(b.id)} key={b.id}>{b.name}</div>)
                        }

                    </div>}
                <button {...BEM(["bills", "create-button"])} onClick={createNew}>Neue Rechnung erstellen</button>
            </Bannered>
        </div>
        <div {...BEM(["default-layout", "middle"])}>
            <Bannered name="Rechnung">
                {state.kind === "Loading" || state.current === undefined ? (
                    <div>Bitte wählen Sie eine Rechnung oder erstellen Sie eine neue.</div>
                ) : (
                        <div {...BEM(["bills", "bill"])}>
                            <input {...BEM(["bills", "name"], "default-input")} value={state.current.name} onChange={(ev) => setState(name.set(ev.target.value)(state))} />
                            <textarea {...BEM(["bills", "description"])} value={state.current.description} onChange={(ev) => setState(description.set(ev.target.value)(state))} />
                            <div {...BEM(["bills", "entries"])}>
                                {
                                    state.current.entries.map(b =>
                                        <div
                                            {...BEM(["bills", "on-bill"])}
                                            key={b.id}
                                            onClick={() => removeFromCurrent(b)}
                                        >
                                            {b.name}
                                        </div>
                                    )
                                }
                            </div>
                            <button {...BEM(["bills", "save"])} onClick={() => saveCurrent()}>Speichern</button>
                            <button {...BEM(["bills", "generate-pdf"])} onClick={() => saveCurrent(true)}>PDF erzeugen</button>
                        </div>
                    )}
            </Bannered>
        </div>
        <div {...BEM(["default-layout", "right"])}>
            <Bannered name={"Unbezahlte Dokumente (" + (state.kind === "Loading" ? 0 : state.toBill.length) + ")"}>
                <div>
                    {state.kind === "Loading" ? (
                        <Loader input="loading" />
                    ) : (
                            <div>
                                <button {...BEM(["bills", "add-all"])} onClick={() => addToCurrent(state.toBill)}>Alle übernehmen</button>
                                {
                                    state.toBill.map(b =>
                                        <div
                                            {...BEM(["bills", "unbilled-document"])}
                                            key={b.id}
                                            onClick={() => addToCurrent([b])}
                                        >
                                            {b.name}
                                        </div>
                                    )
                                }

                            </div>
                        )}

                </div>
            </Bannered>
        </div>
    </div >;
};

const name =
    pipe(
        L.id<Loaded>(),
        L.prop("current"),
        L.fromNullable,
        O.prop("name")
    );

const description =
    pipe(
        L.id<Loaded>(),
        L.prop("current"),
        L.fromNullable,
        O.prop("description")
    );

const entries =
    pipe(
        L.id<Loaded>(),
        L.prop("current"),
        L.fromNullable,
        O.prop("entries")
    );

type State = {
    kind: "Loading";
} | Loaded;

type Loaded = {
    kind: "Loaded";
    bills: T.Bill<object>[];
    current?: T.Bill<T.DocumentMeta>;
    toBill: T.DocumentMeta[];
};
