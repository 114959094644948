import React, {ReactElement} from "react";

import {Spinner} from "../../common-components/loader/Loader";
import {Tabbed} from "../../common-components/Tabbed";
import {useSchemas} from "../../hooks/useSchema";
import {BEM} from "../../util/util";
import {useDescriptions} from "../schema/descriptions";
import {AutoAnnotate} from "./AutoAnnotate";
import {Relevance} from "./Relevance";
import * as T from "../../backend/types";
import {Outcome} from "./Outcome";
import {Factcheck} from "./Factcheck";

const emptySchemas: T.Schema[] = [];

export const Analysis = (): ReactElement => {
  const schemas = useSchemas();
  const descriptions = useDescriptions(schemas || emptySchemas, undefined);

  console.log(descriptions);

  return <div {...BEM(["default-layout"], "route-analysis")}>
    <div {...BEM(["default-layout", "middle"])}>
      {
        !schemas ? <Spinner /> :
          <Tabbed tabs={[
            {name: "Relevanz", child: <Relevance schemas={schemas} descriptions={descriptions} />},
            {name: "Ausgang", child: <Outcome schemas={schemas} descriptions={descriptions} />},
            {name: "Faktencheck", child: <Factcheck schemas={schemas} descriptions={descriptions} />},
            {name: "Auto Annotate", child: <AutoAnnotate />}
          ]} />
      }
    </div>

  </div>
}
