import React, {ReactElement, useState, MutableRefObject} from "react";
import "./bannered.scss";
import {SingleBem, BEM} from "../../util/util";
import {Fullscreen} from "../icon/Icon";

export const Bannered = (props: Props): ReactElement => {
  const [collapsed, setCollapsed] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);

  const bem: SingleBem[] = [
    // eslint-disable-next-line no-sparse-arrays
    [
      "bannered",
      ,
      {
        success: props.state === "success",
        error: props.state === "error",
        scroll: !!props.scroll,
        fullscreen: fullscreen
      },
    ],
    ...(props.additionalBem || []),
  ];

  return (
    <div {...BEM(...bem)}>
      <div {...BEM(["bannered", "header"])}>
        <div {...BEM(["bannered", "border-top"])}></div>
        <div {...BEM(["bannered", "label"])}><span>{props.name}</span><Fullscreen title="Vollbild" onClick={() => {setFullscreen(!fullscreen)}} /></div>
        <div {...BEM(["bannered", "top-right"])}>
          {props.topRight === undefined
            ? null
            : props.topRight.map((element, i) => (
              <div key={i} {...BEM(["bannered", "top-right-wrapper"])}>
                {element}
              </div>
            ))}
          {!props.canCollapse ? null : (
            <div
              key="trw"
              {...BEM(["bannered", "top-right-wrapper"])}
              onClick={() => setCollapsed((old) => !old)}
            >
              <span>{collapsed ? "▼" : "▲"}</span>
            </div>
          )}
          {props.onClose === undefined ? null : (
            <div
              key="trw"
              {...BEM(["bannered", "top-right-wrapper"])}
              onClick={props.onClose}
            >
              <span>×</span>
            </div>
          )}
        </div>
      </div>
      <div ref={props.bodyRef} {...BEM(["bannered", "body", {collapsed: collapsed, 'only-top-padding': !!props.onlyTopPadding, scroll: !!props.scroll}])}>
        <div {...BEM(["bannered", "hide-overlay", {shown: collapsed}])}></div>
        {props.children}
      </div>
    </div >
  );
};

interface Props {
  name: String;
  children:
  | null
  | undefined
  | string
  | ReactElement
  | (null | undefined | ReactElement | string)[];
  topRight?: ReactElement[];
  onClose?: () => void;
  state?: "error" | "success";
  canCollapse?: boolean;
  additionalBem?: SingleBem[];
  onlyTopPadding?: boolean;
  scroll?: boolean;
  bodyRef?: MutableRefObject<any>
}
