import React, {ReactElement} from "react";
import './StoredSearchHeader.scss';
import * as S from "./state";
import * as Icon from "../../common-components/icon/Icon";
import * as api from "../../backend/Api";
import {setFixedError} from "../../common-components/fixed-error/FixedError";
import {toast, dialog} from "../../common-components/toast/Toast";
import {assertNever} from "../../util/util";

export const StoredSearchHeader = (props: Props): ReactElement => {
    const selected = props.state.selected === undefined ? undefined : props.state.searches[props.state.selected];

    const onSave = () => {
        if (selected) {
            api.saveStoredSearch(selected).then(response => {
                switch (response.kind) {
                    case "AlwaysError": setFixedError(response); break;
                    case "Ok": toast("Erfolgeich gespeichert"); break;
                    default: assertNever(response);
                }
            });
        }
    }

    const onDelete = async () => {
        if (selected) {
            const answer = await dialog("Wollen Sie die gespeicherte Suche '" + selected.name + "' wirklich löschen?", [{payload: true, message: "Ja"}, {payload: false, message: "Nein"}]);
            if (!answer) return;
            const response = await api.deleteStoredSearch(selected.id);
            switch (response.kind) {
                case "AlwaysError": setFixedError(response); break;
                case "Ok": toast("Erfolgeich gelöscht");
                    window.location.reload();
                    break;
                default: assertNever(response);
            }
        }
    }

    return <>
        <Icon.Save disabled={!selected} title="Speichern" onClick={onSave} />
        <Icon.Delete disabled={!selected} title="Löschen" onClick={onDelete} />
    </>;
}

interface Props {
    state: S.State;
    setState: (s: (S.State | ((s: S.State) => S.State))) => void;
}
