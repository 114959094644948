import React, {ReactElement, useMemo, useReducer, useState} from "react";

import * as T from "../../backend/types";
import * as api from "../../backend/Api";
import {Bannered} from "../../common-components/bannered/Bannered";
import {toast} from "../../common-components/toast/Toast";
import {assertNever, BEM, showBytes} from "../../util/util";
import {Descriptions} from "../schema/descriptions";
import {flattenNodes} from "../schema/tree";
import {setFixedError} from "../../common-components/fixed-error/FixedError";
import {getRedirectLink} from "../../hooks/useUrlData";
import {UrlData} from "../document/Document";

export const Outcome = (props: Props): ReactElement => {
  const [state, dispatch] = useReducer(handleEvent, {newRequest: {}});
  const [result, setResult] = useState<undefined | T.AnalyzeOutcomeState>(undefined);

  const groups = useMemo(() => {
    if (state.newRequest.groupSchemaId) {
      return props.schemas.flatMap(s => {
        if (s.id == state.newRequest.groupSchemaId) {
          const ret = flattenNodes(s.root);
          ret.shift();
          return ret;
        } else {
          return [];
        }
      });
    } else {
      return [];
    }
  }, [props.schemas, state.newRequest.groupSchemaId]);

  const startAnalysis = async () => {

    if (state.newRequest.groupNodeId && state.newRequest.groupSchemaId && state.newRequest.outcomeSchema) {
      const res = await api.startAnalysis({
        kind: "AnalyzeOutcomeRequest",
        testSchema: state.newRequest.groupSchemaId,
        testGroup: state.newRequest.groupNodeId,
        schema: state.newRequest.outcomeSchema
      });

      if (res.kind === "Ok") toast("Search Started");
      else if (res.kind === "UnknownError") toast(res.hint, true)
      else setFixedError(res);
    } else {
      toast("Bitte erst alles auswählen", true);
    }

  };

  const getResults = async () => {
    const res = await api.getAnalysis({length: 10, offset: 0});

    switch (res.kind) {
      case "AlwaysError":
        setFixedError(res);
        break;
      case "NotFound":
        toast("Keine Analyse gestarted", true);
        break;
      case "GotData":
        if (res.data.kind == "AnalyzeOutcomeState") {
          setResult(res.data);
        } else {
          toast("Falsche Antwort auf AutoAnnotation", true);
        }
        break;
      default:
        assertNever(res);
    }
  };

  const getResultsExcel = async () => {
    const res = await api.getAnalysisExcel();

    switch (res.kind) {
      case "AlwaysError":
        setFixedError(res);
        break;
      case "NotFound":
        toast("Keine Analyse gestarted", true);
        break;
      case "GotData":
        showBytes(res.data, "results.xlsx");
        break;
      default:
        assertNever(res);
    }
  };

  const correctlyPredicted = !result ? [] : result.results.filter(r => r.result.kind === "OutcomePrediction" && r.result.expteced === r.result.predicted);
  const wronglyPredicted = !result ? [] : result.results.filter(r => r.result.kind === "OutcomePrediction" && r.result.expteced !== r.result.predicted);
  const errors = !result ? [] : result.results.filter(r => r.result.kind === "OutcomeError");

  return <div>
    <Bannered
      name="Konfiguration"
      topRight={[<div onClick={startAnalysis}>run</div>]}
      additionalBem={[["default-layout", "column-element"]]}
    >
      <div {...BEM(["route-analysis", "sub-header", {first: true}])}>Neuer Test</div>

      <div {...BEM(["route-analysis", "selection-table"])}>
        <div{...BEM(["route-analysis", "label", {first: true}])}>Testgruppen-Schema:</div>
        <select className="select" onChange={e => dispatch({kind: "SelectGroupSchema", id: e.target.value})}>
          <option></option>
          {
            props.schemas.map(s =>
              <option key={s.id} value={s.id}>{s.name}</option>
            )

          }
        </select>
        <div{...BEM(["route-analysis", "label", {first: true}])}>Testgruppen-Knoten:</div>
        <select className="select" onChange={e => dispatch({kind: "SelectGroupNode", id: e.target.value})}>
          <option></option>
          {
            groups.map(g =>
              <option key={g.id} value={g.id}>{g.name}</option>
            )

          }

        </select>
        <div{...BEM(["route-analysis", "label", {first: true}])}>Ausgangs-Schema:</div>
        <select className="select" onChange={e => dispatch({kind: "SelectOutcomeSchema", id: e.target.value})}>
          <option> </option>
          {
            props.schemas.map(s =>
              <option key={s.id} value={s.id}>{s.name}</option>
            )

          }
        </select>
        <div></div>
      </div>

      <div {...BEM(["route-analysis", "sub-header"])}>Eingereihte Tests</div>

      <div {...BEM(["route-analysis", "sub-header"])}> </div>

    </Bannered>

    <Bannered
      name="Auswertung"
      topRight={[<div onClick={getResults}>run</div>]}
      additionalBem={[["default-layout", "column-element"]]}
    >
      {!result ? "Noch nichts" :
        <div>
          <div onClick={getResultsExcel}>Als Excel Downloaden</div>
          <div{...BEM(["route-analysis", "sub-header", {first: true}])}>Fortschritt:</div>
          <div>{result.common.numberOfDocumentsAlreadyScored}/{result.common.numberOfDocumentsToBeScored}</div>

          <div>
            <div>
              <div{...BEM(["route-analysis", "sub-header"])}>Ergebnisse:</div>
              <div {...BEM(["route-analysis", "selection-table"])}>
                <div>Richtig klassifizierte:</div>
                <div>{correctlyPredicted.length}</div>
                <div>Falsch klassifizierte:</div>
                <div>{wronglyPredicted.length}</div>
                <div>Fehler:</div>
                <div>{errors.length}</div>
              </div>


              <div {...BEM(["route-analysis", "sub-header"])}> Erklärung zu den Fehlern: </div>

              <table>
                <tbody>
                  <tr>
                    <td {...BEM(['route-analysis', 'table-cell'])}>MissingSchema</td>
                    <td {...BEM(['route-analysis', 'table-cell'])}>Das angegeben Schema existiert nicht (kommt normalerweise nicht vor)</td>
                  </tr>

                  <tr>
                    <td {...BEM(['route-analysis', 'table-cell'])}>MissingDocument</td>
                    <td {...BEM(['route-analysis', 'table-cell'])}>Das angegeben Dokument existiert nicht (kommt normalerweise nicht vor)</td>
                  </tr>

                  <tr>
                    <td {...BEM(['route-analysis', 'table-cell'])}>MissingNodes</td>
                    <td {...BEM(['route-analysis', 'table-cell'])}>In dem gewählten Schema fehlen die Scorer</td>
                  </tr>

                  <tr>
                    <td {...BEM(['route-analysis', 'table-cell'])}>MissingRipple</td>
                    <td {...BEM(['route-analysis', 'table-cell'])}>In dem gewählten Schema fehlen die Ripple-Knoten</td>
                  </tr>

                  <tr>
                    <td {...BEM(['route-analysis', 'table-cell'])}>MissingMapping</td>
                    <td {...BEM(['route-analysis', 'table-cell'])}>Für das gewählte Dokument gibt es keinen Eintrag in der passenden Urteilsübersicht</td>
                  </tr>
                </tbody>
              </table>

              <div {...BEM(["route-analysis", "label"])}>Richtig</div>
              <div {...BEM(["route-analysis", "max-height-result-container"])}>
                <table>
                  <tbody>
                    {correctlyPredicted.map(result => <ResultRow s={result} />)}

                  </tbody>
                </table>
              </div>
              <div {...BEM(["route-analysis", "label"])}>Falsch</div>
              <div {...BEM(["route-analysis", "max-height-result-container"])}>
                <table>
                  <tbody>
                    {wronglyPredicted.map(result => <ResultRow s={result} />)}
                  </tbody>
                </table>
              </div>

              <div {...BEM(["route-analysis", "label"])}>Fehler</div>
              <div {...BEM(["route-analysis", "max-height-result-container"])}>
                <table>
                  <tbody>
                    {errors.map(result => <ResultRow s={result} />)}
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>
      }
    </Bannered>

  </div>;
}

const ResultRow = (props: {s: T.SingleOutcomeResult}): ReactElement => {
  const link = getRedirectLink<UrlData>("document");

  return (
    <tr {...BEM(["route-analysis", "table-row"])} onClick={() => window.open(link({documentId: props.s.document.id}))}>
      <td {...BEM(["route-analysis", "table-cell"])}>{props.s.document.name}</td>
      <td {...BEM(["route-analysis", "table-cell"])}>{props.s.result.kind === "OutcomeError" ? "Error" : props.s.result.expteced}</td>
      <td {...BEM(["route-analysis", "table-cell"])}>{props.s.result.kind === "OutcomeError" ? props.s.result.message : (props.s.result.predicted || "None")}</td>
    </tr>
  );
};



type State = {
  newRequest: SingleNewRequest;
}

type SingleNewRequest = {
  groupSchemaId?: string;
  groupNodeId?: string;
  outcomeSchema?: string;
}

type StateEvent = {
  kind: "SelectGroupSchema";
  id: string;
} | {
  kind: "SelectGroupNode";
  id: string;
} | {
  kind: "SelectOutcomeSchema";
  id: string;
}

const handleEvent = (state: State, event: StateEvent): State => {
  switch (event.kind) {
    case 'SelectOutcomeSchema':
      return {...state, newRequest: {...state.newRequest, outcomeSchema: event.id}};
    case 'SelectGroupNode':
      return {...state, newRequest: {...state.newRequest, groupNodeId: event.id}};
    case 'SelectGroupSchema':
      return {...state, newRequest: {groupSchemaId: event.id}};
    default: return assertNever(event);
  }
}

type Props = {
  schemas: T.Schema[];
  descriptions: Descriptions;
}

