export type Point = {
    x: number;
    y: number;
};

export const addX = (p: Point, amount: number): Point => ({...p, x: p.x + amount});
export const addY = (p: Point, amount: number): Point => ({...p, y: p.y + amount});
export const add = (p1: Point, p2: Point): Point => ({
    x: p1.x + p2.x,
    y: p1.y + p2.y,
});
export const sub = (p1: Point, p2: Point): Point => add(p1, mult(p2, -1));

export const mult = (p1: Point, scalar: number): Point => ({
    x: p1.x * scalar,
    y: p1.y * scalar,
});

export const distance = (p1: Point, p2: Point): number => Math.sqrt(
    Math.pow(p1.x - p2.x, 2) +
    Math.pow(p1.y - p2.y, 2)
);
