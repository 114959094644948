import React, {Fragment, ReactElement, useState} from "react";
import * as api from "../../backend/Api";

import "./search.scss";
import * as T from "../../backend/types";
import {Bannered} from "../../common-components/bannered/Bannered";
import {Loader} from "../../common-components/loader/Loader";
import {
  ContextMenu,
  Menu,
  transformCallbacksMenu,
} from "../../common-components/ContextMenu";
import {assertNever, classNames, BEM} from "../../util/util";
import {toast} from "../../common-components/toast/Toast";
import {setFixedError} from "../../common-components/fixed-error/FixedError";
import {getRedirectLink} from "../../hooks/useUrlData";
import {UrlData} from "../document/Document";
import {SchemaList} from "../schema/SchemaList";
import {createCompoundSearch} from "../schema/tree";
import {useSchemas} from "../../hooks/useSchema";
import {Matcher} from "../../common-components/matcher/Matcher";
import {ScorerList} from "../../common-components/scorer-list/ScorerList";

export const Search = (): ReactElement => {
  const [search, setSearch] = useState<T.Search<T.PreMatcher>>({scorers: []});
  const startSearch = async () => {
    const res = await api.startSearch({...search});

    if (res.kind === "Ok") toast("Search Started");
    else setFixedError(res);
  };

  const schemas = useSchemas() || [];
  return (
    <div className="route-search">
      <div {...BEM("default-layout")}>
        <div {...BEM(["default-layout", "left"])}>
          <Bannered name="Schema Liste">
            <SchemaList
              onSchemaLoaded={(s) => setSearch(createCompoundSearch(s))}
            />
          </Bannered>
        </div>
        <div {...BEM(["default-layout", "middle"])}>
          <Bannered
            name="Filter"
            additionalBem={[["default-layout", "column-element"]]}
          >
            <pre>
              <Matcher setter={filter => setSearch({...search, filter: filter || undefined})} matcher={search.filter} context={({schemas})} isRoot={true} />
            </pre>
          </Bannered>
          <Bannered
            name="Scoring"
            topRight={[<div onClick={startSearch}>run</div>]}
            additionalBem={[["default-layout", "column-element"]]}
          >
            <pre>
              <SearchTree search={search} setter={setSearch} context={({schemas: schemas})} />
            </pre>
          </Bannered>
          <SearchResults />
        </div>
      </div>
    </div>
  );
};

export const SearchTree = (props: {
  search: T.Search<T.PreMatcher>;
  setter: (s: T.Search<T.PreMatcher>) => void;
  context: Context;
}): ReactElement => {
  return <ScorerList
    scorers={props.search.scorers}
    setter={newScorers => props.setter({...props.search, scorers: newScorers})}
    context={props.context}
    createNew={matcher => {
      const scorer: T.UnassociatedScorer<T.PreMatcher> = {kind: "UnassociatedScorer", score: 100, matcher}
      return scorer;
    }}
    RenderSub={Scorer}
  />;
};

const Scorer = (props: {
  scorer: T.Scorer<T.PreMatcher>;
  setter: (s: null | T.Scorer<T.PreMatcher>) => void;
  context: Context;
}): ReactElement => {
  console.log(props.scorer.kind);
  const scorer = props.scorer;
  if (scorer.kind === "DropContainedScorer") {
    return <ScorerList
      scorers={scorer.subs}
      setter={newScorers => props.setter({...scorer, subs: newScorers})}
      context={props.context}
      createNew={matcher => {
        const scorer: T.UnassociatedScorer<T.PreMatcher> = {kind: "UnassociatedScorer", score: 100, matcher}
        return scorer;
      }}
      RenderSub={Scorer}
    />;
  } else {
    const matcher: T.PreMatcher = scorer.matcher;
    const updateMatcher = (m: null | T.PreMatcher): void => {
      if (m === null) props.setter(null);
      else props.setter({...scorer, matcher: m});
    };
    const setScore = (n: number): void => {
      props.setter({...scorer, score: n});
    };

    const setState = (s: T.AnnotationState): void => {
      if (scorer.kind === "AssocicatedScorer") {
        props.setter({...scorer, fact: {node: scorer.fact.node, state: s}});
      }
    };


    const select = scorer.kind !== "AssocicatedScorer" ? null :
      <select
        {...BEM('select')}
        onChange={ev => setState(ev.target.value as T.AnnotationState)}
        value={scorer.fact.state}
      >
        <option value="Yes">Ja</option>
        <option value="PartialYes">Partiell Ja</option>
        <option value="No">Nein</option>
        <option value="Unsubstantiated">Unsubstantiiert</option>
        <option value="Neutral">Neutral</option>
      </select>;


    return (
      <div className="scorer-top">
        <div className="scorer-content">
          <Matcher setter={updateMatcher} matcher={matcher} context={props.context} />
        </div>
        <div className={"scorer-score"}>
          {select}
          <input
            {...BEM("default-input")}
            type="number"
            value={scorer.score === 0 ? "" : scorer.score}
            onChange={(e) => setScore(+e.target.value)}
          />
        </div>
      </div>
    );
  }
};

const renderProgress = (state: T.SearchStatus): ReactElement => {
  const leftToScore =
    state.numberOfDocumentsToScore - state.numberAlreadyScored;
  const perSecond =
    state.nanoDiff === 0
      ? 0
      : state.numberAlreadyScored / (state.nanoDiff / 1000 / 1000 / 1000);
  const percent =
    state.numberOfDocumentsToScore === 0
      ? "100"
      : (100 - (leftToScore * 100) / state.numberOfDocumentsToScore).toFixed(1);
  const timeLeft = () => {
    const allSeconds = Math.ceil(leftToScore / perSecond);
    const seconds = (allSeconds % 60) + "s";
    const allMinutes = Math.floor(allSeconds / 60);
    const minutes = (allMinutes % 60) + "m";
    const allHours = Math.floor(allSeconds / 60 / 60);
    const hours = (allHours % 60) + "h";

    return hours + minutes + seconds;
  };

  if (leftToScore === 0) {
    return (
      <div className="progress done">
        <div>Search Done</div>
        <div>{state.numberOfPositiveDocuments} Found</div>
        <div>{percent}%</div>
        <div>{perSecond.toFixed(1)} / s</div>
        <div>{timeLeft()} left</div>
      </div>
    );
  } else {
    return (
      <div className="progress running">
        <div>Still Running ...</div>
        <div>{state.numberOfPositiveDocuments} Found</div>
        <div>{percent}%</div>
        <div>{perSecond.toFixed(1)} Documents per Second</div>
        <div>{timeLeft()} left</div>
      </div>
    );
  }
};

export const AddMenu = (props: {menu: Menu}): ReactElement => {
  const [contextMenuPos, setContextMenuPos] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const menu = transformCallbacksMenu(props.menu, (c) => () => {
    setContextMenuPos(null);
    c();
  });

  return (
    <Fragment>
      <div
        className="add-plus"
        onClick={(e) => setContextMenuPos({x: e.clientX, y: e.clientY})}
      >
        +
      </div>
      {contextMenuPos === null ? null : (
        <ContextMenu onClose={() => setContextMenuPos(null)} x={contextMenuPos.x} y={contextMenuPos.y} menu={menu} />
      )}
    </Fragment>
  );
};

const SearchResults = (): ReactElement => {
  const [page, setPage] = useState(0);
  const [lastResult, setLastResult] = useState<
    T.GotData<T.SearchStatus> | T.NotFound | null
  >(null);
  const [loading, setLoading] = useState(false);
  const maxPage =
    lastResult?.kind === "GotData"
      ? Math.ceil(lastResult.data.numberAlreadyScored / pageSize)
      : 0;

  const getResults = async (page: number) => {
    setLoading(true);
    const res = await api.getSearch({
      offset: page * pageSize,
      size: pageSize,
    });
    setLoading(false);
    switch (res.kind) {
      case "AlwaysError":
        setFixedError(res);
        break;
      case "NotFound":
      case "GotData":
        setLastResult(res);
        break;
      default:
        assertNever(res);
    }
  };

  const changeToPage = (n: number) => {
    setPage(n);
    getResults(n).then();
  };

  const pager = (
    <div className="pager">
      <div>
        <span onClick={() => changeToPage(Math.max(0, page - 1))}>←</span>
      </div>
      <div>
        page {}
        {[-2, -1, 0, +1, +2]
          .filter((p) => page + p >= 0)
          .map((delta, i) => (
            <div>
              {i === 0 ? "" : ", "}
              <span
                className={classNames({current: delta === 0})}
                onClick={(_) => {
                  changeToPage(page + delta);
                }}
              >
                {page + delta}
              </span>
            </div>
          ))}
        {} of {maxPage}
      </div>
      <div>
        <span onClick={() => changeToPage(page + 1)}>→</span>
      </div>
    </div>
  );

  return (
    <Bannered
      name="Results"
      topRight={[<div onClick={() => getResults(page)}>🗘</div>]}
      additionalBem={[["default-layout", "column-element"]]}
    >
      <div className={"component-loading-overlay"}>
        <div
          className={classNames({"loading-overlay": true, loading: loading})}
        >
          {loading ? <Loader input={"loading"} /> : null}
        </div>
        <div className={"loading-overlay-content"}>
          {lastResult === null ? null : lastResult.kind === "NotFound" ? (
            <div> No Search Running</div>
          ) : (
              <div className="result">
                {renderProgress(lastResult.data)}
                {pager}
                <table>
                  <tbody>
                    {lastResult.data.scores.map((result) => (
                      <ResultRow s={result} />
                    ))}
                  </tbody>
                </table>
                {pager}
              </div>
            )}
        </div>
      </div>
    </Bannered>
  );
};


const ResultRow = (props: {s: T.SingleResult}): ReactElement => {
  const link = getRedirectLink<UrlData>("document");

  return (
    <tr onClick={() => window.open(link({documentId: props.s.meta.id}))}>
      <td>{props.s.meta.name}</td>
      <td>{props.s.meta.date}</td>
      <td>{props.s.score}</td>
    </tr>
  );
};

const pageSize: number = 20;

interface Context {
  schemas: T.Schema[];
}
