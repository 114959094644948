import * as State from "./state";
import React, {ReactElement, useState, useEffect} from "react";
import {v4} from "uuid";
import * as T from '../../backend/types';
import './NewAnnotation.scss';
import {BEM} from "../../util/util";
import {stateTranslation} from "./Annotations";

export const NewAnnotation = (props: {state: State.State, dispatch: (se: State.StateEvent) => void}): ReactElement | null => {
    const [polarity, setPolarity] = useState<T.AnnotationState>("Yes");

    const schema = !props.state.schemaSelection ?
        undefined :
        props.state.document.expandedSchemas[props.state.schemaSelection.id]!;

    const onNodeSelected = (id: string): void => {
        props.dispatch({
            kind: "CreateAnnotationEvent",
            id: v4(),
            documentId: props.state.document.id,
            state: polarity,
            schemaNode: id
        });
    }

    useEffect(() => {
        const selected = State.getSelectedAnnotation(props.state)
        if (selected) {
            setPolarity(selected.state);
        }
    }, [props.state])

    return <div>
        <select
            {...BEM('select')}
            onChange={ev => setPolarity(ev.target.value as T.AnnotationState)}
            value={polarity}
        >
            {
                (Object.keys(stateTranslation) as (keyof typeof stateTranslation)[]).map(k =>
                    <option key={k} value={k}>{stateTranslation[k]}</option>
                )
            }
        </select>

        {
            schema ? <DocumentSchemaTree node={schema.schema.root} nodeSelected={onNodeSelected} /> : null
        }
    </div>;
}

const DocumentSchemaTree = (props: DocumentSchemaTreeProps): ReactElement => {
    const [expanded, setExpanded] = useState(false);

    return <div {...BEM('document-schema-tree')}>
        <div {...BEM(['document-schema-tree', 'horizontal-pipe'])}>
        </div>
        <div
            onClick={() => setExpanded(!expanded)}
            {...BEM(['document-schema-tree', 'expander', {empty: props.node.subs.length === 0}])}
        >
            +
        </div>
        <div
            onClick={() => props.nodeSelected(props.node.id)}
            {...BEM(['document-schema-tree', 'name'])}>
            {props.node.name}
        </div>
        <div {...BEM(['document-schema-tree', 'vertical-pipe'])}>
        </div>
        <div {...BEM(['document-schema-tree', 'children', {hidden: !expanded}])}>
            {
                props.node.subs.map(child => <DocumentSchemaTree
                    key={child.id}
                    node={child}
                    nodeSelected={props.nodeSelected} />
                )
            }
        </div>

    </div>;
}

interface DocumentSchemaTreeProps {
    node: T.SchemaNode;
    nodeSelected: (id: string) => void;
}
