import {useEffect, useState} from "react";
import * as T from "../backend/types";
import * as api from "../backend/Api";
import {setFixedError} from "../common-components/fixed-error/FixedError";

export const useSchemas = (): (T.Schema[] | null) => {
    const [schemas, setSchemas] = useState<null | T.Schema[]>(null);

    useEffect(() => {
        let cancled = false;

        (async () => {
            const overviews = await api.listSchemas();
            if (overviews.kind === "AlwaysError") {
                setFixedError(overviews);
                return;
            }


            const schemas: T.Schema[] = [];

            for (let overview of overviews.data) {
                if (cancled) {
                    return;
                }
                const schema = await api.getSchema(overview.id);

                if (schema.kind === "AlwaysError") {
                    setFixedError(schema);
                    return;
                } else if (schema.kind === "NotFound") {
                    continue;
                } else {
                    schemas.push(schema.data);
                }
            }

            schemas.sort((a, b) => a.name > b.name ? 1 : -1);
            if (!cancled) {
                setSchemas(schemas);
            }
        })();
        return () => {cancled = true;}
    }, []);

    return schemas;
}
