import React from 'react';

import {Bannered} from "../../common-components/bannered/Bannered";
import {Loader} from "../../common-components/loader/Loader";
import {useApi} from "../../hooks/useApi";
import * as api from "../../backend/Api";

export const UpdateCourtMetadata = () => {
  const [res, runner] = useApi(api.updateCourtMetadata);

  const state: "error" | "success" | undefined =
    res === "loading" || res === "not-yet-started" ? undefined :
      res.kind === "AlwaysError" ? "error" : "success";

  return <Bannered
    name="UpdateCourtMetadata"
    topRight={[<div onClick={runner}>run️</div>]}
    state={state}>
    {
      res === "not-yet-started" ? null :
        res === "loading" || res.kind === "AlwaysError" ?
          <Loader input={res} /> :
          <div className="component-health-check success">Everything seems to be in order.</div>
    }
  </Bannered>
}

