import React, {ReactElement, useEffect, useState} from 'react'
import {Loader} from '../../common-components/loader/Loader';
import * as Icon from "../../common-components/icon/Icon";
import {BEM} from '../../util/util';
import * as api from "../../backend/Api";
import {setFixedError} from '../../common-components/fixed-error/FixedError';
import {v4} from 'uuid';
import {toast} from '../../common-components/toast/Toast';
import "./synonyms.scss";

export const Synonyms = (): ReactElement => {
    const [clusters, setClusters] = useState<Cluster[] | null>(null);
    const [selected, setSelected] = useState<string | null>(null);

    useEffect(() => {
        let canceled = false;

        api.getSynonyms().then(response => {
            if (!canceled) {
                if (response.kind === "GotData") {
                    const clusters: {[key: string]: string[]} = {};
                    for (let [word, cluster] of response.data) {
                        clusters[cluster] = (clusters[cluster] || []).concat([word]);
                    }
                    const keys = Object.keys(clusters);
                    keys.sort();
                    setClusters(keys.map(key => ({id: key, words: clusters[key]})));
                } else {
                    setFixedError(response);
                }
            }
        })

        return () => {canceled = true;};
    }, []);



    if (!clusters) {
        return <div><Loader input="loading" /></div>;
    } else {

        const addCluster = () => {
            const id = v4();

            setClusters(clusters.concat([{id, words: []}]));
            setSelected(id);
        };

        const saveClusters = () => {
            const data: [string, string][] = [];

            for (let c of clusters) {
                for (let w of c.words) {
                    data.push([w, c.id]);
                }
            }

            api.setSynonyms(data).then(response => {
                if (response.kind === "Ok") {
                    toast("Erfolgreich gespeichert");
                } else {
                    setFixedError(response);
                }
            });

        }

        const addNewWord = (clusterId: string): void => {
            setClusters(clusters.map(c => {
                if (c.id === clusterId) return {...c, words: c.words.concat([""])}
                else return c;
            }));
        }

        const setWord = (clusterId: string, wordIdx: number, value: string): void => {
            setClusters(clusters.map(c => {
                if (c.id === clusterId) {return {...c, words: c.words.map((w, i) => i === wordIdx ? value : w)}}
                else return c;
            }));
        }

        const removeWord = (clusterId: string, wordIdx: number): void => {
            setClusters(clusters.map(c => {
                if (c.id === clusterId) {return {...c, words: c.words.filter((_, i) => i !== wordIdx)}}
                else return c;
            }));
        }

        const save = <Icon.Save title={"Speichern"} onClick={saveClusters} />;


        return <div className="route-synonyms">
            <div {...BEM("default-layout")}>
                <div {...BEM(["default-layout", "header"])}>
                    {save}

                </div>
                <div {...BEM(["default-layout", "middle"])}>
                    <div>
                        {
                            clusters.map(c => {
                                if (c.id === selected) {
                                    return <div key={c.id} className="cluster expanded">
                                        {c.words.map((w, i) =>
                                            <div className="word-row" key={i}>
                                                <input className="default-input" value={w} onChange={ev => setWord(c.id, i, ev.target.value)} />

                                                <button onClick={() => removeWord(c.id, i)}>Wort löschen</button>
                                            </div>
                                        )}
                                        <button onClick={() => addNewWord(c.id)} >Neues Wort hinzufügen</button>
                                    </div>

                                } else {
                                    return <div className="cluster collapsed" onClick={() => setSelected(c.id)} key={c.id} > {c.words.join(", ")}</div>;
                                }
                            })
                        }

                        <button onClick={addCluster} >Neuen Cluster anlegen</button>
                    </div>
                </div>
            </div>
        </div >;
    }
}

type Cluster = {
    id: string;
    words: string[];
}
