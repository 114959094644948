import React, {ReactElement, useState} from 'react'

import "./matcher-test.scss";

import * as T from "../../backend/types";
import * as api from "../../backend/Api";
import {setFixedError} from '../fixed-error/FixedError';
import {assertNever, BEM} from '../../util/util';


export const MatcherTest = (props: {matcher: T.PreMatcher}): ReactElement => {
  const [text, setText] = useState<string>("Bitte Text hier eingeben")
  const [result, setResult] = useState<T.Span[] | undefined>([]);

  const runTest = (): void => {
    setResult(undefined);
    api.testMatcher({text, matcher: props.matcher}).then(response => {
      switch (response.kind) {
        case "AlwaysError":
          setFixedError(response);
          throw new Error("Got Server Error");
        case "GotData": setResult(response.data); break;
        default: return assertNever(response);
      }
    });
  }

  return <div {...BEM("matcher-test")}>
    <textarea {...BEM(["matcher-test", "text"], "default-input")} value={text} onChange={e => setText(e.target.value)}></textarea>
    <div {...BEM(["matcher-test", "button"])} onClick={runTest}>Testen</div>

    <div {...BEM(["matcher-test", "result"])}>
      {
        result === undefined ? "loading..." :
          result.length === 0 ? <div>Kein Match!</div> :
            result.map((r, i) => <div {...BEM(["matcher-test", "result-row"])} key={i}>{text.substring(r.startInc, r.endEx)}</div>)
      }
    </div>
  </div>;
}
