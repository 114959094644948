import React, {ReactElement, useEffect, useRef} from "react";
import {assertThere} from "../../util/util";
import {Letter} from "./letter";
import {DocumentConfig} from "./document-config";
import {UnassociatedAnnotation} from "./model/annotation";

export const AnnotationHoverCanvas = (props: Props): ReactElement => {
    const canvasRef = useRef<HTMLCanvasElement>();

    useEffect(() => {
        const canvas = assertThere(canvasRef.current, "textCanvas(when drawing)");
        const context = assertThere(canvas.getContext('2d'), "context(when drawing text canvas)");

        canvas.height = props.annotations.reduce((lastMax, ann) => (ann.span.startInc >= ann.span.endEx) ? 0 : Math.max(props.letters[ann.span.endEx - 1]?.boundingBox?.LOWER || 0, lastMax), 0);
        context.clearRect(0, 0, canvas.width, canvas.height);

        if (props.hover) {
            const ann = props.annotations.find(a => a.id === props.hover);
            if (ann) {
                const ls = props.letters.slice(ann.span.startInc, ann.span.endEx + 1)

                if (ls.length > 0) {
                    context.beginPath();
                    context.strokeStyle = "#000000";
                    for (let letter of ls) {
                        context.moveTo(letter.boundingBox.LEFT, letter.boundingBox.UPPER);
                        context.lineTo(letter.boundingBox.RIGHT, letter.boundingBox.UPPER);
                        context.moveTo(letter.boundingBox.LEFT, letter.boundingBox.LOWER);
                        context.lineTo(letter.boundingBox.RIGHT, letter.boundingBox.LOWER);
                    }
                    context.stroke();
                }
            }
        }
    }, [props.annotations, props.letters, props.config, props.hover]);

    return <canvas width={props.config.width} height={400} ref={(ce) => canvasRef.current = ce!} />;
}

export interface Props {
    annotations: UnassociatedAnnotation[];
    letters: Letter[];
    config: DocumentConfig;
    hover?: string;
}
