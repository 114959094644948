import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {classNames} from "../util/util";
import "./context-menu.scss";

export const ContextMenu = (props: Props): ReactElement => {
    return <div
        style={{top: props.y, left: props.x}}
        className="component-context-menu"
    >
        <SubMenu menu={props.menu} parentDidLayout={true} />
        <div onClick={props.onClose} className="overlay"></div>
    </div>;
}

export const SubMenu = (props: SubMenuProps): ReactElement => {
    const divRef = useRef<HTMLDivElement>();
    useEffect(() => {
        if (props.parentDidLayout) {
            setNoSpaceToRight(divRef.current!.getBoundingClientRect().right > window.innerWidth);
            setNoSpaceBelow(divRef.current!.getBoundingClientRect().bottom > window.innerHeight);
            setDidLayout(true);
        }
    }, [props.parentDidLayout]);

    const [didLayout, setDidLayout] = useState(false);
    const [noSpaceBelow, setNoSpaceBelow] = useState(false);
    const [noSpaceToRight, setNoSpaceToRight] = useState(false);

    return <div
        ref={div => divRef.current = div || undefined}
        className={classNames({
            "sub-menu": true,
            "no-space-to-the-right": noSpaceToRight,
            "no-space-below": noSpaceBelow,
        })}>
        {
            props.menu.subs.map((e, index) => {
                if ("callback" in e) {
                    return <div className="entry" key={index} onClick={e.callback}>
                        {e.name}
                        {e.keys ? <kbd>{e.keys}</kbd> : null}
                    </div>;
                } else {
                    return <div key={index} className="entry">
                        <span>{e.name}</span>
                        <span>&gt;</span>
                        <SubMenu parentDidLayout={didLayout} menu={e} />
                    </div>;
                }
            })
        }
    </div>
}

export interface Props {
    x: number,
    y: number,
    onClose: () => void,
    menu: Menu
}

interface SubMenuProps {
    parentDidLayout: boolean;
    menu: Menu;
}

export interface Leaf {
    name: string;
    keys?: string;
    callback: () => void;
}

export interface Menu {
    name: string;
    subs: (Menu | Leaf)[];
}

export const transformCallbacksMenu = (menu: Menu, f: (c: () => void) => (() => void)): Menu => {
    return {
        ...menu, subs: menu.subs.map(sub => {
            if ("callback" in sub) {
                return transformCallbacksLeaf(sub, f);
            } else {
                return transformCallbacksMenu(sub, f);
            }
        })
    };
}

export const transformCallbacksLeaf = (menu: Leaf, f: (c: () => void) => (() => void)): Leaf => {
    return {...menu, callback: f(menu.callback)};
}

