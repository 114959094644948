import React, {ReactElement, useCallback, useState} from "react";

import "./health-check.scss";
import {Bannered} from "../../common-components/bannered/Bannered";
import {Loader} from "../../common-components/loader/Loader";
import {useApi} from "../../hooks/useApi";
import * as api from "../../backend/Api";
import * as T from "../../backend/types";
import {assertNever} from "../../util/util";

export const LaredaSearch = () => {
  const [phrase, setPhrase] = useState("");
  const [addToDownloadQueue, setAddToDownloadQueue] = useState(false);

  const request = useCallback(
    () =>
      api.getLaredaSearchOverview({
        phrase,
        addToDownloadQueue,
      }),
    [phrase, addToDownloadQueue]
  );
  const [res, runner] = useApi(request);

  const state: "error" | "success" | undefined =
    res === "loading" || res === "not-yet-started"
      ? undefined
      : res.kind === "AlwaysError"
        ? "error"
        : undefined;

  return (
    <Bannered
      canCollapse={true}
      name="Crawler Search Overview"
      topRight={[<div onClick={runner}>run️</div>]}
      state={state}
    >
      Phrase:
      <input value={phrase} onChange={(e) => setPhrase(e.target.value)} />
      Add to download Queue:
      <input
        type="checkbox"
        checked={addToDownloadQueue}
        onChange={(e) => setAddToDownloadQueue(e.target.checked)}
      />
      {res === "not-yet-started" ? null : res === "loading" ||
        res.kind === "AlwaysError" ? (
          <Loader input={res} />
        ) : (
          <div className="result">{renderResult(res)}</div>
        )}
    </Bannered>
  );
};

export const renderResult = (
  result: T.BackendCouldNotBeReached | T.GotData<T.SearchOverview>
): ReactElement => {
  if (result.kind === "BackendCouldNotBeReached") {
    return (
      <div>
        <p>The Backend could not be reached:</p>
        <pre>{result.explanation}</pre>
      </div>
    );
  } else if (result.kind === "GotData") {
    return (
      <div>
        <p>
          There were {result.data.numDocuments} documents found. Here are the
          first few of them:
        </p>
        <table>
          <tbody>
            {result.data.partialResult.map((meta, i) => (
              <tr key={i}>
                <td>
                  {meta.service}/{meta.serviceLocalId}
                </td>
                <td>{meta.date}</td>
                <td>{meta.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  } else {
    return assertNever(result);
  }
};

