import React, {ReactElement, useState} from 'react';

import {Bannered} from "../../common-components/bannered/Bannered";
import {Loader} from "../../common-components/loader/Loader";
import {useApiAuto} from "../../hooks/useApi";
import * as api from "../../backend/Api";

export const LuceneQueryTest = (): ReactElement => {
  const [request, setRequest] = useState<null | string>(null);
  const [userInput, setUserInput] = useState<string>("");

  const res = useApiAuto(api.luceneQueryTest, request);

  const runner = (): void => {
    if (userInput.trim().length === 0) {
      setRequest(null);
    } else {
      setRequest(userInput);
    }
  }

  const state: "error" | "success" | undefined =
    res === "loading" || res === "not-yet-started" ? undefined :
      res.kind === "AlwaysError" ? "error" :
        undefined;

  return <div className="component-lucene-query-test">
    <Bannered
      canCollapse={true}
      name="Lucene Query Test"
      topRight={[<div onClick={runner}>run️</div>]}
      state={state}>
      <div>
        <input value={userInput} onChange={ev => setUserInput(ev.target.value)} />
      </div>
      {
        res === "not-yet-started" ? null :
          res === "loading" || res.kind === "AlwaysError" ?
            <Loader input={res} /> :
            <div className="result">
              {res.kind === "InvalidInput" ? "invalid input" :
                res.data.map(s => <div>{s}</div>)
              }
            </div>
      }
    </Bannered>
  </div>
}
