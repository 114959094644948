import React, {ReactElement, useEffect, useState} from 'react';

import './fixed-error.scss';
import {Bannered} from "../bannered/Bannered";
import {AlwaysError} from "../../backend/Api";
import {ShowError} from "../show-error/ShowError";

let callback: null | ((state: State | null) => void) = null;

export const setFixedError = (error: AlwaysError): void => {
    if (callback) {
        callback({
            kind: "AlwaysError",
            error
        });
    }
}

export const setFixedErrorMessage = (message: string): void => {
    if (callback) {
        callback({
            kind: "ErrorMessage",
            message
        });
    }
}

export const closeFixedEror = (): void => {
    if (callback) {
        callback(null);
    }
}

export const FixedError = (props: {}): ReactElement | null => {
    const [state, setState] = useState<State | null>(null);

    useEffect(() => {
        callback = setState;

        return () => {
            callback = null;
        }
    }, [setState]);

    if (state === null) {
        return null;
    }

    return <div className="component-fixed-error">
        <div className="content">
            <Bannered name="An Error Occurred" state={"error"} onClose={closeFixedEror}>
                <div className="inner-content">
                    {
                        state.kind === "AlwaysError" ?  <ShowError error={state.error}/> :
                            state.kind === "ErrorMessage" ? <div>{state.message}</div> : null
                    }
                </div>
            </Bannered>
        </div>
    </div>
};

export type State = {
    kind: "AlwaysError";
    error: AlwaysError;
} | {
    kind: "ErrorMessage";
    message: string;
}