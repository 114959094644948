import React, {ReactElement} from "react";
import {BEM} from "../../util/util";
import './Schemas.scss';
import {State, StateEvent} from "./state";
import {ExpandedSchema} from "../../backend/types";
import {HoverHelp} from "../../common-components/hover-help/HoverHelp";

export const Schemas = (props: Props): ReactElement => {
    const state = props.state;
    const expanded = state.document;

    const schemas: ExpandedSchema[] = Object.keys(expanded.expandedSchemas).map(key => expanded.expandedSchemas[key]).sort((a, b) => (a.schema.name > b.schema.name) ? 1 : -1);

    const setPrimary = (id: string): void => {
        const foundation = props.state.schemaSelection || {};

        if (!id) {
            props.dispatch({kind: "SetSchemaSelectionEvent", selection: undefined});
        } else {
            props.dispatch({kind: "SetSchemaSelectionEvent", selection: {...foundation, id: id}});
        }
    }

    const setSecondary = (id: string): void => {
        if (props.state.schemaSelection && id !== "") {
            props.dispatch({kind: "SetSchemaSelectionEvent", selection: {...props.state.schemaSelection, compareWith: id}});
        }
    }

    return <div {...BEM('document-schemas')}>
        <label {...BEM(["document-schemas", "label-primary"])} >Primäres Schema</label>
        <HoverHelp additionalBem={[["document-schemas", "help-primary"]]}>
            Das primäre Schema ist das, in dem man alle Bearbeitung macht. In diesem Schema können Annotationen erstellt, bearbeitet und gelöscht werden.
        </HoverHelp>
        <select
            {...BEM("select", ["document-schemas", "select-primary"])}
            value={props.state.schemaSelection?.id}
            onChange={(ev) => setPrimary(ev.target.value)}
        >
            {
                schemas.map(schema => <option key={schema.schema.id} value={schema.schema.id}>{schema.schema.name}</option>)
            }
        </select>

        <label {...BEM(["document-schemas", "label-secondary"])} >Sekundäres Schema</label>
        <HoverHelp additionalBem={[["document-schemas", "help-secondary"]]}>
            In diesem Schema findet keinerlei Bearbeitung statt. Das Hauptschema kann aber mit diesem Schema zu Auswertungszwecken verglichen werden.
        </HoverHelp>
        <select
            {...BEM("select", ["document-schemas", "select-secondary"])}
            value={props.state.schemaSelection?.compareWith}
            onChange={(ev) => setSecondary(ev.target.value)}
        >
            {
                schemas.map(schema => <option key={schema.schema.id} value={schema.schema.id}>{schema.schema.name}</option>)
            }
        </select>
    </div>;
}

export interface Props {
    state: State,
    dispatch: (se: StateEvent) => void;
}
