import React, {ReactElement} from 'react';
import './show-error.scss';

import {AlwaysError} from "../../backend/Api";
import {assertNever} from "../../util/util";

export const ShowError = (props: {error: AlwaysError}): ReactElement =>
    <div className="component-show-error">
        {getError(props.error)}
    </div>

export const getError = (error: AlwaysError): ReactElement => {
    const cause = error.cause;
    switch (cause.kind) {
        case "InvalidCredentials": return <div>Your Credentials seem to be invalid. Please log in.</div>
        case "NetworkProblem": return <div>A network problem has occured. This may be because the server is down or because of your internet connection. Please check your internet connection on a different website.</div>
        case "ServerShuttingDown": return <div>The server is in the process of shutting down. Please try again later.</div>
        case "Timeout": return <div>The server seems to be under heavy load. Please try again in a few minutes.</div>
        case "UnknownError": return <div>An unknown error occured. Most of the time, this is a hardware problem or a programming error. Please notify the administrator. The hint is {">>"}{cause.hint}{"<<"}</div>
        case "InvalidInput": return <div>The input the client sent was invalid. This is most likely a programming error. Please notify an administrator.</div>;
        default: return assertNever(cause);
    }
}
