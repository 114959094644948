import React, {MutableRefObject, ReactElement, useEffect, useMemo, useRef, useState} from "react";
import './Header.scss';
import {State, StateEvent, addAnnotationToCurrentSchema} from "./state";
import * as Icon from "../../common-components/icon/Icon";
import * as api from "../../backend/Api";
import * as T from '../../backend/types';
import {setFixedError} from "../../common-components/fixed-error/FixedError";
import {showHtmlContent, toast} from "../../common-components/toast/Toast";
import {assertNever, BEM, SingleBem} from "../../util/util";
import {useDescriptions} from "../schema/descriptions";
import {flattenNodes} from "../schema/tree";

export const Header = (props: Props): ReactElement => {
  const [goldstandardSchema, setGoldstandardSchema] = useState<T.Schema | undefined>(undefined);

  const names: {[nodeId: string]: string} = useMemo(() => {
    const keys = Object.keys(props.state.document.expandedSchemas);
    const schemas = keys.map(key => props.state.document.expandedSchemas[key].schema);
    const nodes1 = schemas.flatMap(schema => schema.outcome.nodes ? flattenNodes(schema.outcome.nodes) : []);
    const nodes2 = schemas.flatMap(schema => flattenNodes(schema.root));
    return nodes1.concat(nodes2).reduce((names, node) => ({...names, [node.id]: node.name}), {});
  }, [props.state.document.expandedSchemas, goldstandardSchema]);

  const ref = useRef(names);
  useEffect(() => {ref.current = names}, [names]);

  const onSave = () => {
    api.saveDocument(props.state.document).then((response) => {
      switch (response.kind) {
        case "AlwaysError":
          setFixedError(response);
          break;
        case "Ok":
          toast("Erfolgreich gespeichert");
          break;
        case "NotFound":
          toast("Dokument konnte nicht gespeichert werden.");
          break;
        default:
          assertNever(response);
      }
    });
  };

  const onAnnotate = () => {
    const schemaId = props.state.schemaSelection?.id;

    if (schemaId)
      api.annotateDocument({schema: schemaId, document: props.state.document.id}).then((response) => {
        switch (response.kind) {
          case "AlwaysError":
            setFixedError(response);
            break;
          case "GotData":
            toast("Annotiert");
            const newState = response.data.reduce((state, annotation) => addAnnotationToCurrentSchema(annotation)(state), props.state);
            props.dispatch({
              kind: "SetStateEvent",
              state: newState
            });
            break;
          default:
            assertNever(response);
        }
      });
  };

  const getOutcome = () => {
    const schemaId = props.state.schemaSelection?.id;

    if (schemaId)
      api.getOutcome({schema: schemaId, document: props.state.document.id}).then((response) => {
        switch (response.kind) {
          case "AlwaysError":
            setFixedError(response);
            break;
          case "UnknownError":
            toast(response.hint, true);
            break;
          case "GotData":
            const headerStyle = {
              borderBottom: "1px solid black",
              display: "block",
              marginTop: "1em"
            }

            const factStyle = {
              display: "block",
              margin: "1.3em 0.3em 0.3em 0.5em",
              padding: "0.2em",
            }

            const exhibitStyle = {
              display: "block",
              margin: "0.3em 0.3em 0.3em 1em",
              padding: "0.2em",
            }

            const html = <div>
              <div style={headerStyle}>Erwartet</div>
              <div>{response.data.expected}</div>
              <div style={headerStyle}>Vorhergesagt</div>
              <div>{response.data.predicted ? response.data.predicted[0] : "nichts"}</div>
              <div style={headerStyle}>Scorer Gefunden Und Behalten</div>
              <div>
                {response.data.usedEvidence.map(ev => {
                  return <div>
                    <div style={factStyle}>{ev.fact ? names[ev.fact.node] : "--"} = {ev.fact?.state}</div>
                    <div>{ev.exhibits.map(e => {
                      const prefixStart = Math.max(0, e.span.startInc - 50);
                      const suffixEnd = Math.min(props.state.document.text.length, e.span.endEx + 50);
                      return <div style={exhibitStyle}>
                        <span style={{fontWeight: 'lighter'}}>{props.state.document.text.substring(prefixStart, e.span.startInc)}[</span>
                        <span>{props.state.document.text.substring(e.span.startInc, e.span.endEx)}</span>
                        <span style={{fontWeight: 'lighter'}}>]{props.state.document.text.substring(e.span.endEx, suffixEnd)}</span>
                      </div>
                    })}</div>
                  </div>
                })
                }
              </div>
              <div style={headerStyle}>Scorer Gefunden Und Verworfen</div>
              <div>
                {response.data.discardedEvidence.map(ev => {
                  return <div>
                    <div style={factStyle}>{ev.fact ? names[ev.fact.node] : "--"} = {ev.fact?.state}</div>
                    <div>{ev.exhibits.map(e => {
                      const prefixStart = Math.max(0, e.span.startInc - 50);
                      const suffixEnd = Math.min(props.state.document.text.length, e.span.endEx + 50);
                      return <div style={exhibitStyle}>
                        <span style={{fontWeight: 'lighter'}}>{props.state.document.text.substring(prefixStart, e.span.startInc)}[</span>
                        <span>{props.state.document.text.substring(e.span.startInc, e.span.endEx)}</span>
                        <span style={{fontWeight: 'lighter'}}>]{props.state.document.text.substring(e.span.endEx, suffixEnd)}</span>
                      </div>
                    })}</div>
                  </div>
                })
                }
              </div>
              <div style={headerStyle}>Pfad benutzt</div>
              <div>{response.data.predicted ? response.data.predicted[1].join(" --> ") : ""}</div>
            </div>
            showHtmlContent(html);
            break;
          default:
            assertNever(response);
        }
      });

  }

  const getFactcheck = () => {
    const schemaId = props.state.schemaSelection?.id;

    if (schemaId)
      api.getFactcheck({schema: schemaId, document: props.state.document.id}).then((response) => {
        switch (response.kind) {
          case "AlwaysError":
            setFixedError(response);
            break;
          case "UnknownError":
            toast(response.hint, true);
            break;
          case "GotData":
            api.getSchema(response.data.goldstandardSchema).then(innerRes => {
              switch (innerRes.kind) {
                case "AlwaysError":
                  setFixedError(innerRes);
                  break;
                case "NotFound":
                  toast("goldstandardSchema not found", true);
                  break;
                case "GotData":
                  setGoldstandardSchema(innerRes.data);
                  setTimeout(() => {
                    showHtmlContent(<FactcheckResultRoot
                      documentText={props.state.document.text}
                      names={ref}
                      result={response.data}
                    />);
                  }, 100);
                  break;
                default:
                  assertNever(innerRes);
              }

            });
            break;
          default:
            assertNever(response);
        }
      });

  }


  const FactcheckResultRoot = (props: {
    result: T.FactcheckResult
    names: MutableRefObject<{[nodeId: string]: string}>
    documentText: string
  }): ReactElement => {
    const [evidence, setEvidence] = useState<T.Evidence[]>([]);

    const table =
      <table>
        <thead>
          <tr>
            <th {...BEM(['factcheck-result', 'table-head'])}>Fakt</th>
            <th {...BEM(['factcheck-result', 'table-head'])}>Erwartet</th>
            <th {...BEM(['factcheck-result', 'table-head'])}>Vorhergesagt</th>
          </tr>
        </thead>
        <tbody>
          {
            props.result.table.map((row, i) => [
              <tr
                onClick={() => setEvidence(row.predictedSupport)}
                {...BEM(["factcheck-result", "table-row", {
                  error: row.expected !== row.predicted,
                  correct: row.expected === row.predicted && row.predicted !== false,
                  selected: row.predictedSupport === evidence
                }])}
                key={"main-" + i}
              >
                <td {...BEM(["factcheck-result", "table-cell"])}>{props.names.current[row.fact.node]} = {row.fact.state}</td>

                <td {...BEM(["factcheck-result", "table-cell"])}>{"" + row.expected}</td>
                <td {...BEM(["factcheck-result", "table-cell"])}>{"" + row.predicted}</td>
              </tr>
            ])

          }
        </tbody>
      </table>


    return <div {...BEM(["factcheck-result"])}>
      <div {...BEM(["factcheck-result", "table"])}>{table}</div>
      <div {...BEM(["factcheck-result", "evidence"])}>{
        evidence.map(e => {
          return <SingleEvidence names={props.names} text={props.documentText} evidence={e} />
        })
      }</div>
    </div>
  }


  const SingleEvidence = (props: {
    text: string;
    evidence: T.Evidence;
    additionalBem?: SingleBem[];
    names: MutableRefObject<{[nodeId: string]: string}>;

  }): ReactElement => {
    return <div {...BEM(['factcheck-result', 'single-evidence'])}>
      <div>{props.names.current[props.evidence.fact!.node]}</div>
      {props.evidence.exhibits.map(e =>
        <Exhibit additionalBem={[['factcheck-result', 'evidence-exhibit']]} text={props.text} span={e.span} />
      )}
    </div>;
  }

  const Exhibit = (props: {text: string, span: T.Span, additionalBem?: SingleBem[];}): ReactElement => {
    const prefixStart = Math.max(0, props.span.startInc - 50);
    const suffixEnd = Math.min(props.text.length, props.span.endEx + 50);
    return <div {...BEM(['exhibit'], ...(props.additionalBem || []))}>
      <span style={{fontWeight: 'lighter'}}>{props.text.substring(prefixStart, props.span.startInc)}[</span>
      <span>{props.text.substring(props.span.startInc, props.span.endEx)}</span>
      <span style={{fontWeight: 'lighter'}}>]{props.text.substring(props.span.endEx, suffixEnd)}</span>
    </div>
  }



  const openLink = () => {
    const link = "https://www.lareda.hessenrecht.hessen.de/bshe/document/" + props.state.document.serviceLocalId;
    window.open(link, "_blank");
  }

  return <>
    <Icon.Save title="Speichern" onClick={onSave} />
    <Icon.Annotate title="Annotieren" onClick={onAnnotate} />
    <Icon.GetOutcome title="Ausgang bewerten" onClick={getOutcome} />
    <Icon.GetFactcheck title="Fakten Checken" onClick={getFactcheck} />
    <Icon.Link title="Extern öffnen" onClick={openLink} />
  </>;
}

export interface Props {
  state: State,
  dispatch: (se: StateEvent) => void;
}
