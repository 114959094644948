import './StoredSearchOverview.scss';
import React, {ReactElement, useState} from 'react';
import {BEM} from '../../util/util';
import * as S from './state';
import v4 from 'uuid';

export const StoredSearchOverview = (props: Props): ReactElement => {
    const [filter, setFilter] = useState("");
    const newSearch = (): void => {
        props.setState({...props.state, searches: props.state.searches.concat([{id: v4(), description: "", testText: "", name: "Neue Suche", search: {scorers: []}}])});
    }

    return <div {...BEM('stored-search-overview')}>
        <div {...BEM(['stored-search-overview', 'filter'])}>
            <input {...BEM('default-input')} placeholder={"Filter"} value={filter} onChange={ev => setFilter(ev.target.value)} />

        </div>
        <button onClick={newSearch} {...BEM(['stored-search-overview', 'new-button'])}>Neue Suche erstellen</button>
        {
            props.state.searches.filter(ss => ss.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1).map((ss, i) =>
                <div
                    key={ss.id}
                    {...BEM(['stored-search-overview', 'item', {active: i === props.state.selected}])}
                    onClick={() => props.setState(S.setSelected(i))}
                >
                    {ss.name}
                </div>
            )
        }
    </div>;
}

interface Props {
    state: S.State;
    setState: (s: (S.State | ((s: S.State) => S.State))) => void;
}
