import React, {ReactElement} from "react";
import {Bannered} from "../../common-components/bannered/Bannered";

export const Systems = (): ReactElement => {
  return (
    <Bannered name="Systems">
      <div>
        {[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
          (i) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{width: "var(--space-4)"}}>{"--fsize-" + i}</div>
              <div
                style={{
                  height: "var(--space-0)",
                  width: "var(--space-" + i + ")",
                  backgroundColor: "red",
                  margin: "var(--space--2)",
                  display: "inline-block",
                }}
              ></div>
            </div>
          )
        )}

        <table>
          {[-2, -1, 0, 1, 2, 3, 4, 5, 6, 7].map((fsize) => (
            <tr>
              {[-1, 0, 1, 2].map((fweight) => (
                <td
                  style={{
                    fontWeight: ("var(--fweight-" + fweight + ")") as any,
                    fontSize: "var(--fsize-" + fsize + ")",
                  }}
                >
                  size: {fsize}, weight: {fweight}
                </td>
              ))}
            </tr>
          ))}
        </table>

        <table>
          {[0, 1, 2].map(y => (
            <tr>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(x => (
                <td
                  style={{
                    'backgroundColor': `var(--background-${x}00)`,
                    width: 70,
                    height: 100
                  }}
                >
                  hallo
                </td>
              ))}
            </tr>
          ))}
        </table>
      </div>
    </Bannered >
  );
};
