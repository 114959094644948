import React, {ReactElement, useEffect, useState} from 'react';

import './toast.scss';

let callback: null | ((element: Element<any>) => void) = null;
let id = 0;

export const toast = (message: string, error?: boolean): void => {
  if (callback) {
    id++;

    const fixedId = id;

    callback({
      id: fixedId,
      message,
      error: error,
    });

    setTimeout(() => {
      if (callback) {
        callback({id: fixedId});
      }
    }, 5000)
  }
}

export const showHtmlContent = (message: ReactElement): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (callback) {
      id++;

      const fixedId = id;

      callback!({
        id: fixedId,
        message,
        error: false,
        answers: {
          callback: resolve,
          answers: [{message: "Ok", payload: ""}]
        },
      });
    } else {
      reject(new Error("Callbacks not initialized"));
    }
  });

}

export const dialog = <A,>(message: string, answers: Answer<A>[], additionalClasses?: string[]): Promise<A> => {
  return new Promise((resolve, reject) => {
    if (callback) {
      id++;

      const fixedId = id;

      callback!({
        id: fixedId,
        message,
        error: false,
        answers: {
          callback: resolve,
          answers
        },
        additionalClasses
      });
    } else {
      reject(new Error("Callbacks not initialized"));
    }
  });
}

export const askList = (message: string, answers: string[]): Promise<string> =>
  dialog<string>(message, answers.map(a => ({payload: a, message: a})), ['ask-list']);

export const askKeyedList = <A,>(message: string, answers: Answer<A>[]): Promise<A> =>
  dialog(message, answers, ['ask-list']);

export const askOneIn = <A extends string,>(message: string, values: {[key in A]: string}): Promise<A> => {
  // I did not find a way to get rid of that cast. If you do, please change.
  const ks = Object.keys(values) as A[];
  const answers: Answer<A>[] = ks.map(a => ({message: values[a], payload: a}));
  return dialog(message, answers, ['ask-list']);
}



export const Toast = (): ReactElement => {
  const [elements, setElements] = useState<Element<any>[]>([]);

  useEffect(() => {
    callback = (element: Element<any>) => {

      if (element.message) {
        setElements(elements => elements.concat([element]));
      } else {
        setElements(elements => elements.filter(e => e.id !== element.id));
      }
    };

    return () => {
      callback = null;
    }
  }, [setElements]);

  return <div className="component-toast">
    {
      elements.map(({id, message, error, answers, additionalClasses}) => {
        const className = [
          (answers ? "dialog" : "message"),
          (error ? "error" : "success")
        ].concat(additionalClasses || []).join(" ")

        return <div className={className} key={id}>
          <div className={"wrapper"}>
            <div className={"body"}>
              {message}
            </div>
            <div className={"answers"}>
              {
                (answers ? answers.answers : []).map((a: Answer<unknown>) => {
                  const cb = () => {
                    callback!({id: id});
                    answers!.callback(a.payload);
                  }

                  return <div onClick={cb} className={"answer"}>{a.message}</div>;
                })
              }
            </div>
          </div>
        </div>;
      })
    }
  </div>
}

interface Element<A> {
  id: number;
  message?: string | ReactElement;
  error?: boolean;
  answers?: {
    callback: (answer: A) => void;
    answers: Answer<A>[];
  }
  additionalClasses?: string[];
}

export interface Answer<A> {
  payload: A;
  message: string;
}
