import {v4} from "uuid";
import * as T from '../../backend/types';

export type PathSegment = "Except" | "Else";

export type RippleNode = {
    id: string;
    condition?: T.RippleCondition;
    action: T.AnnotationState;
    exceptNode?: RippleNode;
    elseNode?: RippleNode
}

export const emptyRippleNode = (): RippleNode => ({
    id: v4(),
    action: "Yes",
});

export const update = (root: RippleNode | undefined, path: PathSegment[], newNode: RippleNode | undefined): RippleNode | undefined => {
    const [head, ...tail] = path;

    if (!head) {
        return newNode;
    } else if (head === 'Else' && root) {
        return {...root, elseNode: update(root.elseNode, tail, newNode)};
    } else if (head === 'Except' && root) {
        return {...root, exceptNode: update(root.exceptNode, tail, newNode)};
    } else {
        return undefined;
    }
}


export const cycleAction = (a: T.AnnotationState): T.AnnotationState => {
    const cycle: {[key in T.AnnotationState]: T.AnnotationState} = {
        "Yes": "No",
        "No": "PartialYes",
        "PartialYes": "Yes",
        "Neutral": "No",
        "Unsubstantiated": "No"
    };

    return cycle[a];
}

export const updateCondition = (current: T.RippleCondition, path: number[], newCondition?: T.RippleCondition): T.RippleCondition | undefined => {
    if (path.length === 0) {
        return newCondition;
    } else if ("ored" in current) {
        const [head, ...tail] = path;
        if (current.ored.length <= head) {
            return undefined;
        } else {
            const next = updateCondition(current.ored[head], tail, newCondition);
            if (next) {
                const subClones = current.ored.slice();
                subClones[head] = next;
                return {kind: "RippleOr", ored: subClones};
            } else {
                const subClones = current.ored.filter((_, i) => i !== head);
                return {kind: "RippleOr", ored: subClones};
            }
        }
    } else if ("anded" in current) {
        const [head, ...tail] = path;
        if (current.anded.length <= head) {
            return undefined;
        } else {
            const next = updateCondition(current.anded[head], tail, newCondition);
            if (next) {
                const subClones = current.anded.slice();
                subClones[head] = next;
                return {kind: "RippleAnd", anded: subClones};
            } else {
                const subClones = current.anded.filter((_, i) => i !== head);
                return {kind: "RippleAnd", anded: subClones};
            }
        }
    } else {
        // in a normal value, the path can not be non-empty, since it is
        // already a leaf and there is no way to go deeper
        return undefined;
    }

}

export const toBackendNode = (n: RippleNode): T.RippleNode | undefined => {
    if (!n.condition) {
        return undefined;
    } else {
        const elseNode = n.elseNode ? toBackendNode(n.elseNode) : undefined;
        const exceptNode = n.exceptNode ? toBackendNode(n.exceptNode) : undefined;

        if (n.elseNode && !elseNode || n.exceptNode && !exceptNode) {
            return undefined;
        } else {
            return {
                action: n.action,
                id: n.id,
                elseNode,
                exceptNode,
                condition: n.condition
            };
        }
    }
}
