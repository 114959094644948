export interface DocumentConfig {
    font: string;
    headlineFont: string,
    width: number;
};

export const defaultDocumentConfig: DocumentConfig = {
    font: "18px serif",
    headlineFont: "bold 24px serif",
    width: 740
}
